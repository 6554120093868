import Fetch from "@/utils/fetch";
import { serverTime } from "@/api/common/common";
// 直播详情
/*
 * */
const appointments = function(data, fn, errofn) {
  return new Fetch(`training/speaking_appointments`, "get", data, fn, errofn);
};
const appointmentsFeedback = function(id, data, fn, errofn) {
  return new Fetch(
    `training/speaking_appointments/${id}/feedback`,
    "get",
    data,
    fn,
    errofn
  );
};
const appointmentsDetail = function(id, fn, errofn) {
  return new Fetch(
    `training/speaking_appointments/${id}`,
    "get",
    "",
    fn,
    errofn
  );
};
const submitAppointmentsFeedback = function(
  id,
  data,
  page = "other",
  fn,
  errofn
) {
  if (page == "spoken") {
    return new Fetch(
      `training/speaking_appointments/${id}/feedback`,
      "put",
      data,
      fn,
      errofn
    );
  } else {
    return new Fetch(
      `training/speaking_appointments/${id}/feedback`,
      "put",
      data,
      fn,
      errofn
    );
  }
};
const appointmentHistory = function(data, fn, errofn) {
  return new Fetch(
    `training/speaking_appointments/history`,
    "get",
    data,
    fn,
    errofn
  );
};
const checkMark = function(id, data, fn, errofn) {
  return new Fetch(
    `training/speaking_appointments/${id}/comments`,
    "get",
    data,
    fn,
    errofn
  );
};
const configLive = function(data, fn, errofn) {
  return new Fetch(`config/live`, "get", data, fn, errofn);
};
export {
  appointments,
  appointmentsFeedback,
  submitAppointmentsFeedback,
  appointmentsDetail,
  appointmentHistory,
  serverTime,
  checkMark,
  configLive,
};
