<template>
  <div class="p_countdown">
    <i class="iconfont papa-icon-close" @click="close"></i>
    <div class="title">{{ generateColumns("countdown") }}</div>
    <div class="inputs">
      <span v-show="false">
        <el-input v-model="hour" placeholder="小时"></el-input>
        <span>小时</span>
      </span>
      <el-input v-model="minute" placeholder="00"></el-input>
      <span> {{ generateColumns("minute") }} </span>
      <el-input v-model="second" placeholder="00"></el-input>
      <span style="margin-right: 0px"> {{ generateColumns("second") }} </span>
    </div>
    <div class="buttons">
      <div class="close" @click="close">{{ generateColumns("close") }}</div>
      <div class="confirm" @click="confirm">
        {{ generateColumns("affirm") }}
      </div>
    </div>
  </div>
</template>

<script>
import * as CustomMsgType from "@/views/live/constants/customMsgType";
import EXT_IM from "@/views/live/ext/im.js";
import { mapState } from "vuex";
import { generateColumns, generateTips } from "@/utils/i18n";

export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      hour: null,
      minute: null,
      second: null,
    };
  },
  computed: {
    ...mapState({
      canBeginClass: (state) => state.live.canBeginClass,
    }),
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    generateColumns,
    generateTips,
    close() {
      this.$emit("close");
    },
    confirm() {
      console.log(this.hour, this.minute, this.second);
      if (!this.hour && !this.minute && !this.second) {
        //必须输入时间
        this.$message.error(this.generateTips("correct_time"));
        return;
      }
      if (
        //hour必须在0-23之间 minute必须在0-59之间 second必须在0-59之间 输入的时间必须是数字
        (this.hour && (this.hour < 0 || this.hour > 23 || isNaN(this.hour))) ||
        (this.minute &&
          (this.minute < 0 || this.minute > 59 || isNaN(this.minute))) ||
        (this.second &&
          (this.second < 0 || this.second > 59 || isNaN(this.second)))
      ) {
        this.$message.error(this.generateTips("correct_time"));
        return;
      }
      let time = this.hour * 3600 + this.minute * 60 + this.second * 1;
      let msg = {
        data: CustomMsgType.MsgStartOrEndCountDown(time),
      };
      time = new Date().getTime() / 1000 + time + 2 + " ";
      if (this.canBeginClass) {
        this.$message.error("课程未开始，无法设置倒计时");
      } else {
        EXT_IM.sendCustomMsg(msg);
        this.$emit("confirm", time);
      }
      this.$emit("close");
    },
  },
};
</script>

<style lang='less' scoped>
.p_countdown {
  position: relative;
  .papa-icon-close {
    position: absolute;
    right: 30px;
    top: 25px;
    cursor: pointer;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 9999;
  background-color: white;
  width: 100%;
  height: 100%;
  padding: 30px;
  box-sizing: border-box;
  border-radius: 8px 8px 8px 8px;
  .title {
    text-align: center;
    color: rgba(0, 0, 0, 0.9);
    font-weight: bold;
    font-size: 23px;
  }
  .inputs {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    /deep/ .el-input__inner {
      color: rgba(0, 0, 0, 0.9);
      border: none;
      padding: 5px;
      background: #f4f7fa;
      text-align: center;
    }
    .el-input {
      width: 48px;
      height: 36px;
      border-radius: 4px 4px 4px 4px;
      margin-right: 8px;
    }
    span {
      margin-right: 15px;
    }
  }
  .buttons {
    display: flex;
    justify-content: center;
    .close,
    .confirm {
      cursor: pointer;
      text-align: center;
      line-height: 36px;
      font-size: 12px;
      width: 80px;
      height: 36px;
      border-radius: 4px;
    }
    .close {
      margin-right: 12px;
      justify-content: center;
      border: 1px solid #cccccc;
      color: rgba(0, 0, 0, 0.7);
    }
    .confirm {
      color: rgba(255, 255, 255, 0.9);
      background: linear-gradient(136deg, #21dc7a 0%, #17d6a4 100%);
    }
  }
}
</style>