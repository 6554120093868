import EXT_IM from "@/views/live/ext/im";
import { Message } from "element-ui";

export const boardMixin = {
  computed: {},
  methods: {
    // 监听白板事件（按需监听）
    initBoardEvent() {
      // 撤销状态改变
      window.teduBoard.on(
        TEduBoard.EVENT.TEB_OPERATE_CANUNDO_STATUS_CHANGED,
        (enable) => {
          console.log(123123199999);
          this.canUndo = enable ? 1 : 0;
        }
      );

      // 重做状态改变
      window.teduBoard.on(
        TEduBoard.EVENT.TEB_OPERATE_CANREDO_STATUS_CHANGED,
        (enable) => {
          this.canRedo = enable ? 1 : 0;
        }
      );

      // 新增白板
      window.teduBoard.on(TEduBoard.EVENT.TEB_ADDBOARD, (boardIds, fid) => {
        this.proBoardData(window.teduBoard, fid);
      });

      // 白板同步数据回调(收到该回调时需要将回调数据通过信令通道发送给房间内其他人，接受者收到后调用AddSyncData接口将数据添加到白板以实现数据同步)
      window.teduBoard.on(TEduBoard.EVENT.TEB_SYNCDATA, (data) => {
        let msg = {
          type: "group",
          data: data,
          extension: "TXWhiteBoardExt",
        };
        EXT_IM.sendCustomMsg(msg).then(() => {
          window.teduBoard.addAckData(data);
        });
      });

      // 收到白板初始化完成事件后，表示白板已处于正常工作状态（此时白板为空白白板，历史数据尚未拉取完成）
      window.teduBoard.on(TEduBoard.EVENT.TEB_INIT, () => {
        console.log("白板初始化成功");
      });

      window.teduBoard.on(TEduBoard.EVENT.TEB_HISTROYDATA_SYNCCOMPLETED, () => {
        let currentFileId = window.teduBoard.getCurrentFile();
        var fileInfo = window.teduBoard.getFileInfo(currentFileId);
        this.setCurrentFile(fileInfo);
      });

      // 白板错误回调
      window.teduBoard.on(TEduBoard.EVENT.TEB_ERROR, (code, msg) => {
        console.error(
          "======================:  ",
          "TEB_ERROR",
          " code:",
          code,
          " msg:",
          msg
        );
      });

      // 白板警告回调
      window.teduBoard.on(TEduBoard.EVENT.TEB_WARNING, (code, msg) => {
        console.error(
          "======================:  ",
          "TEB_WARNING",
          " code:",
          code,
          " msg:",
          msg
        );
      });

      // 图片状态加载回调
      window.teduBoard.on(
        TEduBoard.EVENT.TEB_IMAGE_STATUS_CHANGED,
        (status, data) => {
          // console.log('======================:  ', 'TEB_IMAGE_STATUS_CHANGED', ' status:', status, ' data:', data)
        }
      );

      // 删除白板页回调
      window.teduBoard.on(TEduBoard.EVENT.TEB_DELETEBOARD, (boardIds, fid) => {
        // console.log('======================:  ', 'TEB_DELETEBOARD', ' boardIds:', boardIds, ' fid:', fid)
        this.proBoardData(window.teduBoard);
      });

      // 跳转白板页回调
      window.teduBoard.on(TEduBoard.EVENT.TEB_GOTOBOARD, (boardId, fid) => {
        this.proBoardData(window.teduBoard, fid);
      });

      // ppt动画步数改变回调
      window.teduBoard.on(TEduBoard.EVENT.TEB_GOTOSTEP, (step, count) => {
        console.log(
          "======================:  ",
          "TEB_GOTOSTEP",
          " step:",
          step,
          " count:",
          count
        );
      });

      // 增加H5动画PPT文件回调
      window.teduBoard.on(TEduBoard.EVENT.TEB_ADDH5PPTFILE, (fid) => {
        // console.log('======================:  ', 'TEB_ADDH5PPTFILE', ' fid:', fid)
        this.proBoardData(window.teduBoard);
      });

      // 增加文件回调
      window.teduBoard.on(TEduBoard.EVENT.TEB_ADDFILE, (fid) => {
        this.proBoardData(window.teduBoard);
      });

      // 增加转码文件回调
      window.teduBoard.on(TEduBoard.EVENT.TEB_ADDTRANSCODEFILE, (fid) => {
        console.log(
          "======================:  ",
          "TEB_ADDTRANSCODEFILE",
          " fid:",
          fid
        );
        this.proBoardData(window.teduBoard);
      });

      // 删除文件回调
      window.teduBoard.on(TEduBoard.EVENT.TEB_DELETEFILE, (fid) => {
        console.log(
          "======================:  ",
          "TEB_DELETEFILE",
          " fid:",
          fid
        );
        this.proBoardData(window.teduBoard);
      });

      // 文件上传状态
      window.teduBoard.on(
        TEduBoard.EVENT.TEB_FILEUPLOADSTATUS,
        (status, data) => {
          console.log(
            "======================:  ",
            "TEB_FILEUPLOADSTATUS",
            status,
            data
          );
          if (status === 1) {
            this.$message.info("上传成功");
          } else {
            this.$message.info("上传失败");
          }
          document.getElementById("file_input").value = "";
        }
      );

      // 切换文件回调
      window.teduBoard.on(TEduBoard.EVENT.TEB_SWITCHFILE, (fid) => {
        console.log(
          "======================:  ",
          "TEB_SWITCHFILE",
          " fid:",
          fid
        );
        this.proBoardData(window.teduBoard);
        this.setUploadFile(null);
      });

      // 上传背景图片的回调
      window.teduBoard.on(
        TEduBoard.EVENT.TEB_SETBACKGROUNDIMAGE,
        (fileName, fileUrl, userData) => {
          console.log(
            "======================:  ",
            "TEB_SETBACKGROUNDIMAGE",
            "  fileName:",
            fileName,
            "  fileUrl:",
            fileUrl,
            " userData:",
            userData
          );
        }
      );

      // 文件上传进度
      window.teduBoard.on(TEduBoard.EVENT.TEB_FILEUPLOADPROGRESS, (data) => {
        console.log("上传进度:" + parseInt(data.percent * 100) + "%");
        let percent = parseInt(data.percent * 100);
        this.setUploadPercent(percent);
      });

      // H5背景加载状态
      window.teduBoard.on(
        TEduBoard.EVENT.TEB_H5BACKGROUND_STATUS_CHANGED,
        (status, data) => {
          console.log(
            "======================:  ",
            "TEB_H5BACKGROUND_STATUS_CHANGED:: status:",
            status,
            "  data:",
            data
          );
        }
      );

      window.teduBoard.on(TEduBoard.EVENT.TEB_TRANSCODEPROGRESS, (res) => {
        console.log("=======  TEB_TRANSCODEPROGRESS 转码进度：", res);
        if (res.code) {
          Message.error("转码失败code:" + res.code + " message:" + res.message);
        } else {
          let status = res.status;
          if (status === "ERROR") {
            this.showErrorTip("转码失败");
          } else if (status === "CREATED") {
            Message.info("创建转码任务");
          } else if (status === "QUEUED") {
            Message.info("正在排队等待转码");
          } else if (status === "PROCESSING") {
            Message.info("转码中，当前进度:" + res.progress + "%");
          } else if (status === "FINISHED") {
            Message.info("转码完成");
          }
        }
      });
    },
    /**
     * 白板事件回调处理
     * @param {*} data
     */
    proBoardData(teduBoard, fid, res) {
      this.currentFileId = fid;
      var fileInfo = teduBoard.getFileInfo(this.currentFileId);
      if (fileInfo) {
        if (fileInfo.fid !== "#DEFAULT") {
          // sessionStorage.currentFile = JSON.stringify(fileInfo)
          this.setIsShowCourseWare(true);
          this.setCurrentFile(fileInfo);
        }
      }
    },
  },
};
