<template>
  <div class="devicetest">
    <i class="iconfont papa-icon-close" @click="close"></i>
    <div class="test">
      {{ generateColumns("device_test") }}
      <i
        class="iconfont papa-icon-renovate"
        @click="renovate"
        :class="rotateRenovate ? 'renovate' : ''"
      ></i>
    </div>

    <div class="facilitys">
      <div class="camera">
        <div style="height: 483px">
          <div class="title_icon">
            <i class="iconfont papa-icon-loading3" v-if="firstandmin"></i>
            <i
              v-else
              class="iconfont"
              :class="
                deviceState.hasCameraConnect && checkdevices.camera
                  ? 'papa-icon-choose2'
                  : 'papa-icon-fasongshibai'
              "
            ></i>
          </div>
          <div class="icon">
            <i class="iconfont papa-icon-video"></i>
          </div>
          <div class="title_icon">{{ generateColumns("camera") }}</div>
          <div>
            <el-select size="small" v-model="activeCameraDeviceId">
              <el-option
                v-for="(item, index) in cameraList"
                :key="index"
                :value="item.deviceId"
                :label="item.label"
              >
                {{ item.label }}
              </el-option>
            </el-select>
          </div>
          <div class="hint_title">{{ generateTips("allow_camera") }}</div>
          <div class="cameraDetector" v-if="!firstandmin">
            <CameraDetector
              :activeCameraDeviceId="activeCameraDeviceId"
            ></CameraDetector>
          </div>
        </div>
        <div v-if="!isShowCheckCamera">
          <devicecheck
            v-if="isShowCheck"
            @checkTest="checkTest($event, 'camera')"
          >
            <template v-slot:title>
              {{ generateTips("can_see_tips") }}
            </template>
          </devicecheck>
        </div>
      </div>

      <div class="microphone">
        <div style="height: 483px">
          <div class="title_icon">
            <i class="iconfont papa-icon-loading3" v-if="firstandmin"></i>

            <i
              v-else
              class="iconfont"
              :class="
                deviceState.hasMicrophoneConnect && checkdevices.microphone
                  ? 'papa-icon-choose2'
                  : 'papa-icon-fasongshibai'
              "
            ></i>
          </div>
          <div class="icon">
            <i class="iconfont papa-icon-microphone"></i>
          </div>
          <div class="title_icon">{{ generateColumns("microphone") }}</div>
          <div>
            <el-select size="small" v-model="activeMicDeviceId">
              <el-option
                v-for="(item, index) in micDeviceList"
                :key="index"
                :value="item.deviceId"
                :label="item.label"
              >
                {{ item.label }}
              </el-option>
            </el-select>
          </div>
          <div class="hint_title">
            {{ generateTips("microphone_tips") }}
          </div>
          <div>
            <MicDetector
              :activeMicDeviceId="activeMicDeviceId"
              v-if="!firstandmin"
            ></MicDetector>
          </div>
        </div>
        <div v-if="!isShowCheckMicrophone">
          <devicecheck
            v-if="isShowCheck"
            @checkTest="checkTest($event, 'microphone')"
          >
            <template v-slot:title>
              {{ generateTips("can_see_volume_tips") }}</template
            >
          </devicecheck>
        </div>
      </div>

      <div class="trumpet">
        <div style="height: 483px">
          <div class="title_icon">
            <i class="iconfont papa-icon-loading3" v-if="firstandmin"></i>
            <i
              v-else
              class="iconfont"
              :class="
                deviceState.hasSpeakerConnect && checkdevices.trumpet
                  ? 'papa-icon-choose2'
                  : 'papa-icon-fasongshibai'
              "
            ></i>
          </div>

          <div class="icon">
            <i class="iconfont papa-icon-volume"></i>
          </div>
          <div class="title_icon">{{ generateColumns("peaker") }}</div>
          <div>
            <el-select size="small" v-model="activeSpeakerDeviceId">
              <el-option
                v-for="(item, index) in speakerList"
                :key="index"
                :value="item.deviceId"
                :label="item.label"
              >
                {{ item.label }}
              </el-option>
            </el-select>
          </div>
          <div class="hint_title">{{ generateTips("speaker_tips") }}</div>
          <div>
            <SpeakerDetector
              v-if="!firstandmin"
              :activeSpeakerDeviceId="activeSpeakerDeviceId"
            ></SpeakerDetector>
          </div>
        </div>
        <div v-if="!isShowCheckTrumpet">·
          <devicecheck
            v-if="isShowCheck"
            @checkTest="checkTest($event, 'trumpet')"
          >
            <template v-slot:title>{{
              generateTips("can_hear_tips")
            }}</template>
            <template v-slot:can_yes>听得到</template>
            <template v-slot:can_no>听不到</template>
          </devicecheck>
        </div>
      </div>
    </div>
    <div class="device_hint" v-if="!isShowCheck">{{ hinttext }}</div>
    <button
      v-if="!isShowCheck || isShowfinishbtn == 3"
      @click="handleTest(btnText)"
      :class="firstandmin ? 'btn_no_click' : 'btn'"
    >
      {{ btnText }}
    </button>

    <deviceConnect v-if="!firstandmin"></deviceConnect>
  </div>
</template>

<script>
import devicetestJs from "./devicetest.js";
export default devicetestJs;
</script>

<style lang='less' scoped>
.renovate {
  //动画
  animation: rotate 1s linear infinite;
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
.devicetest {
  width: 780px;
  min-height: 664px;
  border-radius: 12px 12px 12px 12px;
  background: #ffffff;
  padding: 30px;
  box-sizing: border-box;
  .device_hint {
    text-align: center;
    margin-bottom: 20px;
  }
  .test {
    -webkit-user-select: none;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 23px;
    color: rgba(0, 0, 0, 0.9);
    padding-bottom: 20px;
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 20px;
    .papa-icon-renovate {
      width: 20px;
      height: 20px;
      display: block;
      font-size: 20px;
      margin-left: 10px;
      cursor: pointer;
    }
  }
  .papa-icon-close {
    position: absolute;
    right: 25px;
    top: 25px;
    font-size: 14px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.7);
    cursor: pointer;
  }
  .facilitys {
    display: flex;
    justify-content: space-between;
    .cameraDetector {
      width: 194px;
      height: 145px;
      background-color: #696464;
    }
    .papa-icon-video,
    .papa-icon-volume,
    .papa-icon-microphone {
      width: 120px;
      font-size: 120px;
      color: #000;
    }
    .papa-icon-choose2,
    .papa-icon-fasongshibai,
    .papa-icon-loading3 {
      font-size: 25px;
      animation: rotate 10s linear infinite;
      @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }
        50% {
          transform: rotate(180deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
    .papa-icon-choose2 {
      color: #29d087;
    }
    .papa-icon-fasongshibai {
      color: #f85251;
    }
    .camera,
    .microphone,
    .trumpet {
      //内容居中
      width: 194px;
      .icon {
        width: 120px;
        height: 120px;
        margin: 15px auto 12px;
      }
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .title_icon {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.9);
      text-align: center;
      margin-bottom: 20px;
    }
  }
  .hint_title {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.7);
    // text-align: center;
    margin: 20px 0;
    // white-space: pre-wrap;
  }
  .btn {
    display: block;
    margin: 0 auto;
    width: 144px;
    height: 48px;
    background: linear-gradient(136deg, #21dc7a 0%, #17d6a4 100%);
    border-radius: 4px 4px 4px 4px;
    border: none;
    color: white;
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
    cursor: pointer;
  }
  .btn_no_click {
    display: block;
    margin: 0 auto;
    width: 144px;
    height: 48px;
    background: #696464;
    border-radius: 4px 4px 4px 4px;
    border: none;
    color: white;
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
    //禁止点击
    pointer-events: none;
  }
}
</style>