<template>
  <div class="hand-list-module">
    <div class="hand-list" v-if="handUpList.length > 0">
      <div class="user" v-for="user in handUpList" :key="user.userID">
        <div class="left">
          <div class="avatar-box">
            <div class="avatar">
              <img :src="user.avatar || defaultAvatar" alt="" />
            </div>
            <div class="liner-box" v-if="user.isConnected">
              <span class="lines">
                <span class="hr hr1"></span>
                <span class="hr hr2"></span>
                <span class="hr hr3"></span>
                <span class="hr hr4"></span>
                <span class="hr hr5"></span>
              </span>
            </div>
          </div>
          <div class="account">{{ user.nick || user.userID }}</div>
        </div>
        <div class="right">
          <div
            v-if="!user.isConnected"
            class="allow"
            @click="allowCall(user.userID)"
          >
            {{ generateColumns("invite_speak") }}
          </div>
          <div v-else class="end" @click="endCall(user.userID)">
            {{ generateColumns("hang_up") }}
          </div>
        </div>
      </div>
    </div>
    <div class="no-content" v-else>
      <img src="../img/pic_hand.png" alt="" />
      <span>{{ generateColumns("no_one_hand_up") }}~</span>
    </div>
  </div>
</template>

<script>
import HandListJs from "./handList.js";

export default HandListJs;
</script>

<style lang="less" scoped>
.hand-list-module {
  width: 100%;
  height: 100%;
  position: relative;

  & > .hand-list {
    width: 100%;
    overflow: auto;
    padding-top: 6px;
    box-sizing: border-box;

    & > .user {
      display: flex;
      justify-content: space-between;
      width: 100%;
      box-sizing: border-box;
      padding: 0 16px;
      margin-bottom: 10px;

      & > .left {
        display: flex;
        align-items: center;

        & > .avatar-box {
          width: 44px;
          height: 44px;
          position: relative;
          margin-right: 10px;

          & > .avatar {
            box-sizing: border-box;
            font-size: 0;
            width: 42px;
            height: 42px;
            border-radius: 50%;
            overflow: hidden;

            & > img {
              width: 100%;
            }
          }

          & > .liner-box {
            width: 42px;
            height: 42px;
            border-radius: 50%;
            background: rgba(0, 0, 0, 0.5);
            position: absolute;
            left: 0;
            top: 0;

            & > .lines {
              width: 44px;
              height: 44px;
              display: flex;
              align-items: center;
              justify-content: center;

              .hr {
                background-color: #29d087;
                width: 2px;
                height: 5px;
                float: right;
                margin-left: 2px;
                animation: liner 0.2s ease-in-out;
                animation-iteration-count: infinite;
                animation-direction: alternate;
              }

              .hr1 {
                margin-left: -2px;
                animation-delay: -2s;
              }

              .hr2 {
                animation-delay: -1.9s;
              }

              .hr3 {
                animation-delay: -1.8s;
              }

              .hr4 {
                animation-delay: -1.7s;
              }

              .hr5 {
                animation-delay: -1.6s;
              }
            }
          }
        }

        & > .account {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 5em;
        }

        .linerVisible {
          display: none;
        }
      }

      & > .right {
        display: flex;
        align-items: center;

        & > .allow {
          width: 54px;
          height: 22px;
          border-radius: 12px;
          border: 1px solid #999;
          font-size: 12px;
          text-align: center;
          line-height: 22px;
          color: #999;
          cursor: pointer;
        }

        & > .end {
          width: 54px;
          height: 22px;
          border-radius: 12px;
          border: 1px solid #29d087;
          font-size: 12px;
          text-align: center;
          line-height: 22px;
          color: #29d087;
          cursor: pointer;
        }
      }
    }
  }

  & > .no-content {
    width: 100%;
    text-align: center;
    line-height: 100px;
    color: #555;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 40%;
    margin-top: -30%;
    & > img {
      width: 178px;
    }
    & > span {
      margin-top: 20px;
      color: #ccc;
      font-size: 16px;
    }
  }
}

@keyframes liner {
  0% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(3);
  }
}
</style>
