import EXT_IM from "@/views/live/ext/im";
import { MUTE_TIME } from "@/config/live/live";
import { mapActions, mapState } from "vuex";
import { generateColumns, generateTips } from "@/utils/i18n";
export default {
  data() {
    return {
      defaultAvatar: require("@/assets/img/avatar.png"),
      errorAvatar: 'this.src="' + require("@/assets/img/avatar.png") + '"',
    };
  },
  computed: {
    ...mapState({
      tim: (state) => state.im.tim,
      roomID: (state) => state.live.roomID,
      userList(state) {
        let list = [];
        state.live.userList.forEach((user) => {
          if (user.userID.indexOf("desktop_share_record_user") < 0) {
            list.push(user);
          }
        });
        return list;
      },
    }),
  },
  methods: {
    generateColumns,
    generateTips,
    setMute(user) {
      let account = user.userID;
      let shut_up_time = 0;
      //设置成员禁言时间
      if (user.mute) {
        shut_up_time = 0; // 禁言时间
      } else {
        shut_up_time = MUTE_TIME;
      }
      let options = {
        groupId: this.roomID,
        userId: account,
        muteTime: shut_up_time,
      };
      EXT_IM.forbidSendMsg(this.tim, options).then((res) => {
        console.log(res, "禁言成功");
        if (shut_up_time > 0) {
          this.setUserMute({ userID: account, mute: true });
        } else {
          this.setUserMute({ userID: account, mute: false });
        }
      });
    },
    deleteMember(user) {
      let account = user.userID;
      let options = {
        groupID: this.roomID + "", //群组id
        userID: account + "", //被禁言的成员帐号列表
      };
      EXT_IM.deleteGroupMemeber(this.tim, options).then((res) => {
        EXT_IM.getMemberList(this.roomID, (list) => {
          list = res.data.memberList;
          this.setUserList(list);
        });
      });
    },
    ...mapActions(["setUserMute", "setUserList"]),
  },
};
