import EXT_IM from '@/views/live/ext/im.js'
import EXT_RTC from '@/views/live/ext/rtc.js'
import EXT_BOARD from '@/views/live/ext/board'
import * as CustomMsgType from '@/views/live/constants/customMsgType'
import {
  MsgOpenCamera,
  MsgOpenShare,
} from '@/views/live/constants/customMsgType'
import * as Constant from '@/views/live/constants/constant'
import { mapActions, mapState } from 'vuex'
import * as Api from '@/api/live/live'
import Message from 'element-ui/packages/message/src/main'
import { MessageBox } from 'element-ui'
import AudioMixerPlugin from 'rtc-audio-mixer'
import { rtcMixin } from '@/views/live/mixins/rtcMixin'
import { generateColumns, generateTips } from '@/utils/i18n'
import message from 'element-ui/packages/message'

export default {
  mixins: [rtcMixin],
  data () {
    return {
      flagClickBegin: true,
      flagCamera: true,
      isShowMicTip: true,
      isShowVoiceTip: true,
      value2: 10,
      voiceNumber: 60,
      deviceList: [],
      activeMicDeviceId: '',
      activeCameraDeviceId: '',
      speakerList: [],
      cameraList: [],
      activeSpeakerDeviceId: '',
      isShowSettingPanel: false,
      copyMainStream: null,
      buttons: [
        {
          icon: 'papa-icon-exchange',
          text: this.generateColumns('switch_mode'),
          key: 1,
        },
        {
          icon: 'papa-icon-time',
          text: this.generateColumns('add_time'),
          key: 2,
        },
        {
          icon: 'papa-icon-write',
          text: this.generateColumns('fill_feedback'),
          key: 3,
        },
      ],
      activeKey: null,
      activeMode: 2,
      isBoardModeShare: true,
      Constant
    }
  },
  props: {
    isVideoCall: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isAutoPlay () {
    },
    //口语陪练页面
    routeMode () {
      return this.$route.path.includes('spoken/live')
    },
    windowWidth () {
      //视口宽度
      return document.documentElement.clientWidth <= 1430
    },
    ...mapState({
      tim: ( state ) => state.im.tim,
      roomId: ( state ) => state.live.roomID,
      userId: ( state ) => state.live.userId,
      mainClient: ( state ) => state.rtc.mainClient,
      shareClient: ( state ) => state.rtc.shareClient,
      localStream: ( state ) => state.rtc.localStream,
      shareStream: ( state ) => state.rtc.shareStream,
      micStatus: ( state ) => state.live.micStatus,
      videoStatus: ( state ) => state.live.videoStatus,
      voiceStatus: ( state ) => state.live.voiceStatus,
      shareStatus: ( state ) => state.live.shareStatus,
      cameraStatus: ( state ) => state.live.cameraStatus,
      isShowCourseware: ( state ) => state.live.isShowCourseware,
      volumeValue: ( state ) => state.rtc.volumeValue,
      webRTC: ( state ) => state.rtc.webRTC,
      liveMode: ( state ) => state.live.liveMode,
      currentFile: ( state ) => state.board.currentFile,
      classStatus: ( state ) => state.live.classStatus,
      canTeach: ( state ) => state.live.canTeach,
      teduBoard: ( state ) => state.board.teduBoard,
      recordTaskId: ( state ) => state.live.recordTaskId,
      recordInstance: ( state ) => state.live.recordInstance,
      remoteStream: ( state ) => state.rtc.remoteVideoStream,
      isSupportShare: ( state ) => state.rtc.isSupportShare,
      bgAudioStream: ( state ) => state.rtc.bgAudioStream,
      canBeginClass: ( state ) => state.live.canBeginClass,
      videoMode: ( state ) => state.footer.videoMode,
      packupvideo: ( state ) => state.board.isPackUpVideo,
      beforeShareVideoModeStatus: ( state ) =>
          state.footer.beforeShareVideoModeStatus,
      downTime: ( state ) => state.live.setdownTime,
      cameraDeviceId: ( state ) => state.live.cameraDeviceId,
      speakerDeviceId: ( state ) => state.live.speakerDeviceId,
      micDeviceId: ( state ) => state.live.micDeviceId,
    }),
  },
  mounted () {
    if (this.classStatus === 1) {
      this.toggleMic(1, 'mounted')
    }
    this.getMicDeviceList()
    this.getSpeakerList()
    this.getCameraList()
  },
  methods: {
    generateColumns,
    generateTips,
    beginClass () {
      fundebug.notify('上课', {
        page: 'footer',
      })
      this.setCanBeginClass(false)
      this.$emit('beginClass')
    },
    endClass ( status ) {
      this.$confirm(this.generateTips('end_live'), '', {
        confirmButtonText: this.generateColumns('affirm'),
        cancelButtonText: this.generateColumns('cancel'),
        type: 'warning',
      })
          .then(() => {
            if (this.routeMode) {
              this.setCanBeginClass(true)
            }
            sessionStorage.classStatus = 0
            this.finishClass(status)
            this.removeEvent()
            this.flagClickBegin = true
          })
          .catch(() => {
            this.$message.info(this.generateColumns('cancelled'))
          })
    },
    exitRoom ( status ) {
      let id = this.$route.params.id
      Api.exitClassRoomTip(
          id,
          this.liveMode,
          this.routeMode
              ? Constant.ENTERANCE.speaking
              : Constant.ENTERANCE.course,
          ( message, code ) => {
            this.$confirm(message, '', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            })
                .then(() => {
                  console.log(code)
                  if (parseInt(code) === 202) {
                    if (this.classStatus === 1) {
                      this.finishClass(status).then(() => {
                        this.$emit('quitClassroom')
                      })
                    } else {
                      this.$emit('quitClassroom')
                    }
                  } else {
                    if (this.classStatus === 1) {
                      this.finishClass(status).then(() => {
                        Api.leaveRoom(
                            id,
                            this.liveMode,
                            this.routeMode
                                ? Constant.ENTERANCE.speaking
                                : Constant.ENTERANCE.course,
                            ( res ) => {
                            }
                        )
                        this.$emit('quitClassroom')
                      })
                    } else {
                      Api.leaveRoom(
                          id,
                          this.liveMode,
                          this.routeMode
                              ? Constant.ENTERANCE.speaking
                              : Constant.ENTERANCE.course,
                          ( res ) => {
                            this.$emit('quitClassroom')
                          },
                          () => {
                            this.$emit('quitClassroom')
                          }
                      )
                    }
                  }
                })
                .catch(() => {
                  this.$message.info('已取消')
                })
          }
      )
    },

    toggleVoice ( num ) {
      this.isShowVoiceTip = false
      if (this.classStatus !== 1) {
        if (num === 1) {
          this.setVoiceStatus(1)
        } else if (num === 0) {
          this.setVoiceStatus(0)
        }
      } else {
        if (this.remoteStream) {
          if (num === 1) {
            this.remoteStream.resume()
            this.remoteStream.unmuteAudio()
            this.setVoiceStatus(1)
          } else if (num === 0) {
            this.remoteStream.muteAudio()
            this.setVoiceStatus(0)
          }
        } else {
          if (num === 1) {
            this.setVoiceStatus(1)
          } else if (num === 0) {
            this.setVoiceStatus(0)
          }
        }
      }
    },

    setSpeakerDevice () {
      if (this.classStatus === 1 && this.remoteStream) {
        this.remoteStream
            .setAudioOutput(this.activeSpeakerDeviceId)
            .then(() => {
              // this.$message.success('声音输出设备切换成功~')
            })
            .catch(( err ) => {
              this.$message.error(this.generateTips('video_connect_error'))
            })
      } else {
        this.$message.error(this.generateTips('audio_connect_error'))
      }
    },

    /**
     * 摄像头开关
     */
    toggleCamera ( status ) {
      if (this.classStatus !== 1) {
        this.setCameraStatus(status)
        return
      }

      if (status === 1) {
        // 开启
        if (this.flagCamera) {
          this.flagCamera = false
          new Promise(( res, rej ) => {
            this.setCameraStatus(1)
            if (this.cameraStatus === 1) {
              res()
            } else {
              rej()
            }
          }).then(
              () => {
                this.flagCamera = false
                if (this.localStream && !this.localStream.hasVideo()) {
                  const videoStream = EXT_RTC.createRTCStream(
                      this.userId,
                      true,
                      false
                  )
                  videoStream.setVideoProfile('720')
                  videoStream.initialize().then(() => {
                    const videoTrack = videoStream.getVideoTrack()
                    this.localStream
                        .addTrack(videoTrack)
                        .then(() => {
                        })
                        .catch(( err ) => {
                          console.log('addTRACK error', err)
                        })
                  })
                } else {
                  EXT_RTC.startRTC(true, true)
                }
                this.setVideoStatus(1)
                if (this.classStatus) {
                  let msg = {
                    type: 'group',
                    data: MsgOpenCamera(),
                  }
                  EXT_IM.sendCustomMsg(msg)
                }
              },
              () => {
                this.flagCamera = true
                console.log('error')
              }
          )
        }
      } else if (status === 0) {
        // 关闭
        this.flagCamera = true
        if (this.localStream) {
          const videoTrack = this.localStream.getVideoTrack()
          if (videoTrack) {
            videoTrack.stop()
            this.localStream.removeTrack(videoTrack)
          }
        }
        this.setVideoStatus(0)
        this.setCameraStatus(0)
        console.log('关闭摄像头并停止推流')
      }
    },

    /**
     * 麦克风开关
     */
    toggleMic ( status ) {
      this.setMicStatus(status)
      if (this.classStatus === 1 && this.localStream) {
        if (this.bgAudioStream) {
          if (status === 1) {
            this.localStream.replaceTrack(this.copyMainStream)
          } else {
            this.copyMainStream = this.localStream.getAudioTrack()
            let bg = AudioMixerPlugin.mix({ sourceList: [this.bgAudioStream] })
            this.localStream.replaceTrack(bg)
          }
        } else {
          status === 1
              ? this.localStream.unmuteAudio()
              : this.localStream.muteAudio()
        }
      }
    },
    setCameraDevice () {
      if (this.classStatus === 1 && this.localStream) {
        this.localStream
            .switchDevice('video', this.activeCameraDeviceId)
            .then(() => {
              this.setCameraStatus(1)
              this.localStream.unmuteVideo()
              // this.$message.success('视频输入设备切换成功~')
            })
            .catch(( err ) => {
              this.$message.error(this.generateTips('video_connect_error'))
            })
      } else {
        this.$message.error(this.generateTips('local_video_error'))
      }
    },
    setMicDevice () {
      console.log(this.localStream)
      if (this.classStatus === 1 && this.localStream) {
        this.localStream
            .switchDevice('audio', this.activeMicDeviceId)
            .then(() => {
              this.setMicStatus(1)
              this.localStream.unmuteAudio()
            })
            .catch(( err ) => {
              console.log(err, '设备连接异常')
              this.$message.error(this.generateTips('audio_connect_error'))
            })
      } else {
        this.$message.error(this.generateTips('audio_connect_error'))
      }
    },
    // 屏幕分享
    async shareScreen () {
      if (this.classStatus !== Constant.CLASS_STATUS.STARTED) {
        return this.$message.error(this.generateTips('not_class_time'))
      }
      if (window.TRTC.isScreenShareSupported()) {
        this.setBeforeShareVideoModeStatus(this.videoMode)
        this.isBoardModeShare = true

        await this.$emit('startShareScreen')

        this.isShowMicTip = true
      } else {
        MessageBox.alert(
            this.generateTips('not_support_screen_share'),
            this.generateColumns('hint'),
            {
              confirmButtonText: this.generateColumns('affirm'),
              callback: () => {
                window.open('https://www.google.cn/chrome/')
              },
            }
        )
      }
    },

    stopPush () {
      EXT_RTC.stopPush(this.mainClient, this.localStream).then(() => {
        let msg = {
          data: MsgOpenShare('Off'),
        }
        EXT_IM.sendCustomMsg(msg)
        this.localStream.stop()
        this.localStream.close()
        this.mainClient.leave()
      })
    },
    //changeMode
    stopPushScreen ( status, mode ) {
      if (status === 'stopShare') {
        let params
        if (this.routeMode) {
          params = {
            speaking_appointment_id: this.$route.params.id,
            record_mode: mode ? mode === 1 ? 2 : 3 : 2,
          }
        } else {
          params = {
            course_plan_task_id: this.$route.params.id,
            record_mode: mode ? mode === 1 ? 2 : 3 : 2,
          }
        }
        Api.changeMode(this.$route.params.id,
            this.routeMode
                ? Constant.ENTERANCE.speaking
                : Constant.ENTERANCE.course,
            params, () => {
              //点击关闭屏幕分享 调用changMode 下课不掉用

              return EXT_RTC.stopPush(this.shareClient, this.shareStream).then(() => {
                let msg = {
                  type: 'group',
                  data: MsgOpenShare('Off'),
                }
                EXT_IM.sendCustomMsg(msg)
                this.stopPushSendMsg()

                //发送模式消息
                this.shareStream.stop()
                this.shareStream.close()

                this.shareClient.leave().then(() => {
                  console.log('屏幕分享leave success')
                  this.setShareStream(null)
                  this.setShareStatus(0)
                })
              })
            }, () => {
              return false
            })
      }
    },

    stopPushSendMsg () {
      //退出屏幕分享发消息 开启分享之前的模式是视频2 发送视频2 切换回视频2  分享之前是1 发送白板1 不切换模式
      if (this.$route.path.includes('spoken/live')) {
        if (this.beforeShareVideoModeStatus == 2 && this.isBoardModeShare) {
          let msg = {
            data: CustomMsgType.MsgLiveMode('video'),
          }
          EXT_IM.sendCustomMsg(msg)
          this.activeMode = this.beforeShareVideoModeStatus
          this.setVideoMode(this.beforeShareVideoModeStatus)
        }
        if (this.beforeShareVideoModeStatus == 1 && this.isBoardModeShare) {
          let msg = {
            data: CustomMsgType.MsgLiveMode('board'),
          }
          EXT_IM.sendCustomMsg(msg)
        }
        if (!this.isBoardModeShare) {
          let msg = {
            data: CustomMsgType.MsgLiveMode('video'),
          }
          EXT_IM.sendCustomMsg(msg)
        }
      }
    },
    showFile () {
      this.setIsShowCourseWare(true)
      EXT_BOARD.onGotoBoard(
          this.teduBoard,
          this.currentFile.boardInfoList[ this.currentFile.currentPageIndex ]
              .boardId
      )
    },
    hideFile () {
      this.setIsShowCourseWare(false)
      EXT_BOARD.onGotoBoard(this.teduBoard, '#DEFAULT')
    },
    setVoice ( number ) {
      let value = number / 100
      let obj = document.querySelector('#remote_video_wrapper audio')
      if (obj) {
        obj.volume = value
      }
    },
    setVoiceNumber () {
      if (this.voiceNumber < 1) {
        this.setVoiceStatus(0)
        this.remoteStream.muteAudio()
      } else {
        this.remoteStream.resume()
        this.remoteStream.unmuteAudio()
        this.setVoiceStatus(1)
      }
      if (this.classStatus === 1) {
        this.setVoice(this.voiceNumber)
        this.setVolumeValue(this.voiceNumber)
      } else {
        this.setVolumeValue(this.voiceNumber)
      }
    },
    showAudioBox () {
      Api.teacherAudio('get', '', ( res, pagination ) => {
        this.setAudioList(res)
        this.setAudioPagination(pagination)
      })
      this.$emit('showFileList', 'audio')
    },
    finishClass ( status ) {
      return new Promise(( resolve, reject ) => {
        let msg = {
          data: CustomMsgType.MsgTeacherEndClass,
        }
        EXT_IM.sendCustomMsg(msg)
        const courseId = this.$route.params.id
        Api.stopRecord(
            courseId,
            this.liveMode,
            this.routeMode
                ? Constant.ENTERANCE.speaking
                : Constant.ENTERANCE.course,
            () => {
              sessionStorage.startRecordStatus = 0
              if (this.cameraStatus === 1) {
                this.toggleCamera(0)
              }
              if (this.shareStatus === 1) {
                this.stopPushScreen(status)
              }
              if (this.localStream && this.localStream.hasAudio()) {
                this.stopPush()
              }
              this.setClassStatus(2)
              this.setHandUpList([])
              console.log('下课成功~')
              sessionStorage.canBeginClassCountDown = 5 // todo  15
              sessionStorage.canBeginClassInterval = setInterval(() => {
                sessionStorage.canBeginClassCountDown =
                    parseInt(sessionStorage.canBeginClassCountDown) - 1
                if (sessionStorage.canBeginClassCountDown <= 0) {
                  clearInterval(sessionStorage.canBeginClassInterval)
                }
              }, 1000)
              resolve()
              Message.success('结束录制')
            },
            () => {
              sessionStorage.startRecordStatus = 0
              Message.error(this.generateTips('record_generate_fail'))
              if (this.cameraStatus === 1) {
                this.toggleCamera(0)
              }
              if (this.shareStatus === 1) {
                this.stopPushScreen(status)
              }
              if (this.localStream && this.localStream.hasAudio()) {
                this.stopPush()
                this.localStream.close()
              }
              this.setClassStatus(2)
            }
        )
      })
    },
    closeTip () {
      this.isShowMicTip = false
    },
    formatTooltip ( val ) {
      return val / 100
    },
    getMicDeviceList () {
      EXT_RTC.getDevices().then(( res ) => {
        let olddeviceIndex = res.findIndex(( device ) => {
          return device.deviceId === this.activeMicDeviceId
        })
        this.activeMicDeviceId =
            olddeviceIndex < 0 ? res[ 0 ].deviceId : this.activeMicDeviceId
        this.deviceList = res
      })
    },
    getSpeakerList () {
      EXT_RTC.getSpeakers().then(( res ) => {
        let olddeviceIndex = res.findIndex(( device ) => {
          return device.deviceId === this.activeSpeakerDeviceId
        })
        this.activeSpeakerDeviceId =
            olddeviceIndex < 0 ? res[ 0 ].deviceId : this.activeSpeakerDeviceId
        this.speakerList = res
      })
    },
    getCameraList () {
      EXT_RTC.getCameras().then(( res ) => {
        let olddeviceIndex = res.findIndex(( device ) => {
          return device.deviceId === this.activeCameraDeviceId
        })
        this.activeCameraDeviceId =
            olddeviceIndex < 0 ? res[ 0 ].deviceId : this.activeCameraDeviceId
        this.cameraList = res
      })
    },
    getDeviceList () {
      this.getMicDeviceList()
      this.getSpeakerList()
      this.getCameraList()
    },
    setDevice () {
      if (this.classStatus === 1) {
        this.setSpeakerDevice()
        this.setMicDevice()
        this.setCameraDevice()
      } else {
        Message.error('当前不是上课状态，请上课后重新尝试设置')
      }
      this.isShowSettingPanel = false
    },
    activeBoradOrVideo ( item ) {
      this.activeKey = item.key
    },
    addTimeAndfeed ( mode ) {
      if (mode.key == 2) {
        if (this.classStatus == 1) {
          if (this.downTime)
            return this.$message.error('倒计时已开启，请勿重复开启')
          this.$emit('showSetTimeOut')
        } else {
          let msg = this.generateTips('not_class')
          Message.error(msg)
        }
      }
      if (mode.key == 3) {
        this.$emit('showFeedBack')
      }
    },
    ActiveMode ( mode ) {
      console.log(660)
      let _this = this
      if (mode == this.videoMode) return //如果当前模式和要切换的模式一致，不做处理
      let params
      if (this.routeMode) {
        params = {
          speaking_appointment_id: this.$route.params.id,
          record_mode: mode === 1 ? 2 : 3,
        }
      } else {
        params = {
          course_plan_task_id: this.$route.params.id,
          record_mode: mode === 1 ? 2 : 3,
        }
      }
      if (this.shareStatus == 1) {
        this.stopPushScreen('stopShare', mode)
      } else {
        Api.changeMode(this.$route.params.id, this.routeMode
            ? Constant.ENTERANCE.speaking
            : Constant.ENTERANCE.course, params, () => {
          //mode 1 白板 2视频
          if (_this.shareStatus == 1 && mode == 2) {
            //如果开启了屏幕共享切换模式，先关闭屏幕共享
            _this.isBoardModeShare = false
            _this.stopPushScreen()
          }
          if (_this.shareStatus == 1) {
          } else {
            let msg = {
              data: CustomMsgType.MsgLiveMode(mode == 1 ? 'board' : 'video'),
            }
            EXT_IM.sendCustomMsg(msg)
            console.log(msg, mode)
          }
          _this.activeMode = mode
          _this.setVideoMode(mode)
        })
      }
    },

    ...mapActions([
      'setShareStatus',
      'setVideoStatus',
      'setCameraStatus',
      'setMicStatus',
      'setVolumeValue',
      'setIsShowCourseWare',
      'setVoiceStatus',
      'setClassStatus',
      'setLocalStream',
      'setShareStream',
      'setCurrentFile',
      'setHandUpList',
      'setAudioList',
      'setAudioPagination',
      'setCanBeginClass',
      'setVideoMode',
      'setBeforeShareVideoModeStatus',
    ]),
  },
  watch: {
    remoteStream ( newVal, oldVal ) {
      if (newVal) {
        if (this.classStatus !== 1) {
          this.remoteStream.muteAudio()
        }
      }
    },
    classStatus ( newVal, oldVal ) {
      if (newVal === 1) {
        if (this.remoteStream) {
          // this.remoteStream.unmuteAudio()
        }
      }
    },
    isShowSettingPanel ( newVal ) {
      if (newVal) {
        this.getDeviceList()
      }
    },
  },
}
