<template>
  <div class="file-box">
    <div class="file-list" v-if="currentFile && currentFile.fid !== '#DEFAULT'">
      <div
        class="file-item"
        v-for="item in currentFile.boardInfoList"
        @click="setCurrentPage(item.boardId)"
        :key="item.boardId"
        :class="currentBoardId === item.boardId ? 'active' : ''"
      >
        <img :src="item.backgroundUrl" alt="" />
      </div>
      <div class="file-item" style="background: #ccc"></div>
    </div>

    <div class="no-file" v-else>
      <div class="file-item" @click="showFileBox">
        <b>+</b>
      </div>
    </div>

    <div class="manage-file" ref='manageFile' @click="showFileBox" v-if="isShowPackUp">
      <div >{{ generateColumns("courseware_manage") }}</div>
    </div>
  </div>
</template>

<script>
import FileJs from "./file.js";

export default FileJs;
</script>

<style lang="less" scoped>
.file-box {
  width: 100%;
  height: 100%;
  padding: 20px 0;
  box-sizing: border-box;
  position: relative;

  & > .file-list,
  .no-file {
    width: 100%;
    height: 100%;
    overflow-y: auto;

    & > .file-item {
      min-width: 60px;
      width: calc(100% - 20px);
      min-height: 45px;
      /*height: calc((8.3vw - 20px) * .75);*/
      margin: 0 auto 10px;
      background: #fff;
      text-align: center;
      /*line-height: calc((8.3vw - 20px) * .75);*/
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;

      & > #file_input {
        position: absolute;
        width: 100%;
        height: 100%;
        background: transparent;
        left: 0;
        top: 0;
        opacity: 0;
      }

      b {
        width: 100%;
      }

      & > img {
        width: 100%;
      }
    }

    & > .active {
      border: 1px solid #29d087;
    }
  }

  & > .manage-file {
    min-width: 90px;
    width: 8.3%;
    height: 38px;
    color: #fff;
    text-align: center;
    line-height: 38px;
    position: fixed;
    bottom: 56px;
    cursor: pointer;

    & > div {
      border-radius: 2px;
      min-width: 60px;
      background: #29d087;
      width: calc(100% - 20px);
      margin: 0 auto;
    }
  }
}
</style>
