<template>
  <div class="online-list-page">
    <div class="user-list">
      <div class="user" v-for="user in userList" :key="user.userID">
        <div class="user-desc">
          <div class="user-avatar">
            <img
              :src="user.avatar || defaultAvatar"
              alt=""
              :onerror="errorAvatar"
            />
          </div>
          <div class="user-name">
            {{ user.nick || user.userID }}
          </div>
          <div class="user-tag" v-if="user.identity === 'teacher'">
            {{ generateColumns("teacher") }}
          </div>
          <div class="user-tag" v-if="user.identity === 'assistant'">
            {{ generateColumns("assistant") }}
          </div>
        </div>
        <div class="action" v-show="user.role !== 'Admin'">
          <i
            class="iconfont papa-icon-banned"
            @click="setMute(user)"
            :class="user.mute ? 'active' : ''"
          ></i>
          <!--          <i class="iconfont papa-icon-kick_out" @click="deleteMember(user)"></i>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OnlineList from "./onlineList.js";

export default OnlineList;
</script>

<style lang="less" scoped>
.online-list-page {
  width: 100%;
  height: 100%;

  & > .user-list {
    padding-top: 6px;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;

    & > .user {
      width: 100%;
      height: 38px;
      box-sizing: border-box;
      padding: 0 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;

      & > .user-desc {
        display: flex;
        align-items: center;

        & > .user-avatar {
          font-size: 0;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          overflow: hidden;

          & > img {
            width: 100%;
          }
        }

        & > .user-name {
          font-size: 14px;
          color: #555;
          font-weight: 500;
          margin: 0 8px 0 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 10em;
        }

        & > .user-tag {
          width: 44px;
          height: 22px;
          text-align: center;
          font-size: 14px;
          line-height: 22px;
          border-radius: 2px;
          background: #dfeee8;
          color: #29d087;
        }
      }

      & > .action {
        & > .iconfont {
          font-size: 22px;
          color: #777;
          cursor: pointer;
        }

        .papa-icon-kick_out {
          margin-left: 5px;
        }

        & > .active {
          color: #29d087;
        }
      }
    }
    .user:hover {
      background-color: #eee;
    }
  }
}
</style>
