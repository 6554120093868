<template>
  <div class="file-box">
    <div class="top">
      <div class="title">{{ fileBoxTitle }}</div>
      <div v-if="!isEdit" class="edit" @click="edit(true)">
        {{ generateColumns("manage") }}
      </div>
      <div v-else class="edit" @click="edit(false)">
        {{ generateColumns("complete") }}
      </div>
    </div>
    <div class="bottom">
      <div class="list">
        <div class="list-box">
          <el-upload
            class="upload"
            ref="uploadDom"
            :data="uploadData"
            :action="uploadUrl"
            :accept="uploadAccept"
            :headers="uploadHeaders"
            :show-file-list="false"
            :on-change="handleUploadChange"
            :before-upload="beforeFileUpload"
            :on-progress="handleUploadProgress"
            :on-success="handleUploadSuccess"
            :on-error="handleUploadFail"
            :http-request="httpRequest"
          >
            <div class="upload__title">
              <i class="iconfont papa-icon-AddFile"></i>
              {{ generateTips("upload_local") }}
              <span class="upload_tip" v-if="boxType === 'file'">{{
                generateTips("upload_courseware_tips")
              }}</span>
              <span class="upload_tip" v-else>{{
                generateTips("upload_tips")
              }}</span>
            </div>
          </el-upload>
          <div v-if="boxType === 'file'" class="scroll-box">
            <div class="upload-pre" v-if="loadingFileList.length > 0">
              <template v-if="boxType === 'file'">
                <div
                  class="item_box"
                  v-for="loadingFile in loadingFileList"
                  :key="loadingFile.name"
                >
                  <div class="item_left">
                    <div class="item-title">
                      <i
                        v-if="loadingFile.name.indexOf('ppt') > -1"
                        class="iconfont papa-icon-data_ppt"
                      ></i>
                      <i
                        v-else-if="loadingFile.name.indexOf('pdf') > -1"
                        class="iconfont papa-icon-data_pdf"
                      ></i>
                      <i
                        v-else-if="loadingFile.name.indexOf('doc') > -1"
                        class="iconfont papa-icon-data_word"
                      ></i>
                      <i v-else class="iconfont papa-icon-data_ppt"></i>
                      <span>{{ loadingFile.name }}</span>
                    </div>
                  </div>
                  <div class="item_right">
                    <div class="status_tip">{{ UploadProgress }}</div>
                    <div class="spinner"></div>
                  </div>
                </div>
              </template>
              <template v-else-if="boxType === 'audio'">
                <div class="item_box">
                  <div class="item_left">
                    <div class="item-title">
                      <i
                        class="iconfont papa-icon-data_audio"
                        style="color: #29d087"
                      ></i>
                      <span>{{ loadingFile.name }}</span>
                    </div>
                  </div>
                  <div class="item_right">
                    <div class="spinner"></div>
                  </div>
                </div>
              </template>
            </div>
            <div
              class="list-item"
              v-for="file in fileList"
              v-show="file.title !== '#DEFAULT'"
              :key="file.fid"
            >
              <div
                class="item_box"
                v-if="file.status === 3"
                @click="handleChooseFile(file)"
              >
                <div class="item_left" @click="handleFileItem(file.id)">
                  <div class="item_choose" v-if="isEdit">
                    <i
                      v-if="isEdit && chooseFileId.indexOf(file.id) < 0"
                      class="iconfont papa-icon-choose"
                    ></i>
                    <i
                      v-show="isEdit && chooseFileId.indexOf(file.id) > -1"
                      class="iconfont choose_icon papa-icon-choose2"
                    ></i>
                  </div>
                  <div class="item-title">
                    <i
                      v-if="file.document_ext.indexOf('ppt') > -1"
                      class="iconfont papa-icon-data_ppt"
                    ></i>
                    <i
                      v-else-if="file.document_ext.indexOf('pdf') > -1"
                      class="iconfont papa-icon-data_pdf"
                    ></i>
                    <i
                      v-else-if="file.document_ext.indexOf('doc') > -1"
                      class="iconfont papa-icon-data_word"
                    ></i>
                    <i v-else class="iconfont papa-icon-data_ppt"></i>
                    <span>{{ file.document_name }}</span>
                  </div>
                </div>
                <div class="item_right">
                  <div class="item-edit">
                    <i
                      v-if="!isEdit"
                      v-show="chooseFile.id === file.id"
                      class="iconfont papa-icon-duigou"
                    ></i>
                    <i
                      v-show="isEdit"
                      class="iconfont papa-icon-editor_delete"
                      @click="deleteFile(file.id)"
                    ></i>
                  </div>
                </div>
              </div>
              <div class="item_box" style="opacity: 0.4" v-else>
                <div class="item_left">
                  <div class="item-title">
                    <i
                      v-if="file.document_ext.indexOf('ppt') > -1"
                      class="iconfont papa-icon-data_ppt"
                    ></i>
                    <i
                      v-else-if="file.document_ext.indexOf('pdf') > -1"
                      class="iconfont papa-icon-data_pdf"
                    ></i>
                    <i
                      v-else-if="file.document_ext.indexOf('doc') > -1"
                      class="iconfont papa-icon-data_word"
                    ></i>
                    <i v-else class="iconfont papa-icon-data_ppt"></i>
                    <span>{{ file.document_name }}</span>
                  </div>
                </div>
                <div class="item_right">
                  <span class="status_tip">转码中</span>
                  <div class="spinner"></div>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="boxType === 'audio'" class="scroll-box">
            <div class="upload-pre" v-if="loadingFileList.length > 0">
              <template v-if="boxType === 'file'">
                <div class="item_box">
                  <div class="item_left">
                    <div class="item-title">
                      <i
                        v-if="loadingFile.name.indexOf('ppt') > -1"
                        class="iconfont papa-icon-data_ppt"
                      ></i>
                      <i
                        v-else-if="loadingFile.name.indexOf('pdf') > -1"
                        class="iconfont papa-icon-data_pdf"
                      ></i>
                      <i
                        v-else-if="loadingFile.name.indexOf('doc') > -1"
                        class="iconfont papa-icon-data_word"
                      ></i>
                      <i v-else class="iconfont papa-icon-data_ppt"></i>
                      <span>{{ loadingFile.name }}</span>
                    </div>
                  </div>
                  <div class="item_right">
                    <div class="spinner"></div>
                  </div>
                </div>
              </template>
              <template v-else-if="boxType === 'audio'">
                <div
                  class="item_box"
                  v-for="loadingFile in loadingFileList"
                  :key="loadingFile.uid"
                >
                  <div class="item_left">
                    <div class="item-title">
                      <i
                        class="iconfont papa-icon-data_audio"
                        style="color: #29d087"
                      ></i>
                      <span>{{ loadingFile.name }}</span>
                    </div>
                  </div>
                  <div class="item_right">
                    <div class="spinner"></div>
                  </div>
                </div>
              </template>
            </div>
            <div
              class="list-item"
              v-for="file in audioList"
              v-show="file.title !== '#DEFAULT'"
              :key="file.fid"
              @click="handleChooseFile(file)"
            >
              <div class="item_box">
                <div class="item_left" @click="handleFileItem(file.id)">
                  <div class="item_choose" v-if="isEdit">
                    <i
                      v-if="isEdit && chooseFileId.indexOf(file.id) < 0"
                      class="iconfont papa-icon-choose"
                    ></i>
                    <i
                      v-show="isEdit && chooseFileId.indexOf(file.id) > -1"
                      class="iconfont choose_icon papa-icon-choose2"
                    ></i>
                  </div>
                  <div class="item-title">
                    <i
                      class="iconfont papa-icon-data_audio"
                      style="color: #29d087"
                    ></i>
                    <span>{{ file.audio_name }}</span>
                  </div>
                </div>
                <div class="item_right">
                  <div class="item-edit">
                    <i
                      v-if="!isEdit"
                      v-show="chooseFile.id === file.id"
                      class="iconfont papa-icon-duigou"
                    ></i>
                    <i
                      v-show="isEdit"
                      class="iconfont papa-icon-editor_delete"
                      @click="deleteFile(file.id)"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <el-pagination
          style="margin-top: 30px"
          v-if="getPagination"
          @size-change="getFileList"
          @current-change="getFileList"
          :hide-on-single-page="true"
          background
          layout="prev, pager, next"
          :page-size="getPagination.per_page"
          :total="getPagination.total"
        >
        </el-pagination>
      </div>
      <div class="bottom-btn-wrapper">
        <div class="add" v-if="!isEdit">
          <div
            class="bottom__btn bottom__btn--green"
            @click="confirmFile"
            :class="fileList.length < 1 ? 'bottom__btn--disable' : ''"
            v-if="boxType === 'file'"
          >
            {{ generateTips("open_courseware") }}
          </div>
          <div
            class="bottom__btn bottom__btn--green"
            @click="confirmAudio"
            :class="chooseFile.audio_url ? '' : 'bottom__btn--disable'"
            v-if="boxType === 'audio'"
          >
            {{ generateColumns("add_audio") }}
          </div>
          <div class="bottom__btn bottom__btn--gray" @click="close">
            {{ generateColumns("close") }}
          </div>
        </div>
        <div class="delete" v-else>
          <div class="choose_all">
            <div>
              <i
                v-if="isEdit && !chooseAll"
                class="iconfont papa-icon-choose"
                @click="chooseAllFile('all')"
              ></i>
              <i
                v-show="isEdit && chooseAll"
                class="iconfont choose_icon papa-icon-choose2"
                @click="chooseAllFile('none')"
              ></i>

              <span>
                {{ generateColumns("selected") }} ({{
                  chooseFileId.length
                }})</span
              >
            </div>
          </div>
          <div class="button-delete" @click="deleteFile('all')">
            {{ generateColumns("delete") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileBox from "./fileBox.js";

export default FileBox;
</script>

<style lang="less" scoped>
.file-box {
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 24px 20px 30px;
  box-sizing: border-box;

  & > .top {
    display: flex;
    justify-content: space-between;
    color: #555;
    height: 60px;

    & > .edit {
      cursor: pointer;
    }

    & > .close-btn {
      cursor: pointer;
    }
  }

  & > .bottom {
    display: flex;
    height: 55vh;
    width: 100%;
    flex-direction: column;

    .upload {
      width: 100%;
      height: 40px;
      position: relative;
      box-sizing: border-box;

      .upload__title {
        width: 100%;
        height: 100%;
        line-height: 40px;
        padding: 0 20px;
        box-sizing: border-box;
        position: absolute;
        left: 00px;
        top: 0;
        text-align: left;
      }

      .upload_tip {
        font-size: 12px;
        color: red;
      }

      input {
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
      }
    }

    & > .list {
      width: 100%;
      height: 45vh;
      /*overflow: auto;*/
      background: #f5f5f5;
      border-radius: 6px;
      box-sizing: border-box;
      padding: 16px 0;

      & > .list-box {
        height: 85%;

        .scroll-box {
          height: 85%;
          overflow: auto;
        }

        /deep/ .upload {
          height: 40px;
          width: 100%;
        }

        .upload-pre {
          opacity: 0.4;
        }

        .list-item,
        .upload-pre {
          width: 100%;
          /*height: 50px;*/

          .item_box {
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            cursor: pointer;
            box-sizing: border-box;
            padding: 0 20px;
            justify-content: space-between;
          }

          .item_left {
            display: flex;

            .item_choose {
              margin-right: 12px;
            }

            .item-title {
              & > i {
                color: red;
                margin-right: 20px;
              }

              & > span {
                color: #555;
              }
            }
          }

          .item_right {
            display: flex;

            .status_tip {
              margin-right: 15px;
            }

            .item-edit {
              .icon-duigou {
                color: #29d087;
              }
            }
          }
        }

        .list-item:hover {
          background: #f1f1f1;
        }
      }
    }
  }

  .choose_icon {
    color: @papaGreen;
  }

  .bottom-btn-wrapper {
    width: 100%;
    margin-top: 30px;

    .add,
    .delete {
      display: flex;
      justify-content: space-between;
    }

    .button-delete {
      .button--small;
      .button--delete--solid;
    }
  }

  .bottom__btn {
    width: 46%;
    height: 46px;
    border-radius: 2px;
    font-size: 16px;
    font-weight: 500;
    line-height: 46px;
    text-align: center;
    color: #fff;
    cursor: pointer;
  }

  .bottom__btn--gray {
    background: #ccc;
  }

  .bottom__btn--green {
    background: #29d087;
  }

  .bottom__btn--disable {
    opacity: 0.3;
    cursor: not-allowed;
  }

  .spinner {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: @papaGreen;
    animation: loading 0.8s linear infinite;
  }

  @keyframes loading {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
</style>
