<template>
  <div class="chatroom-module">
    <el-tabs
      v-model="activeName"
      stretch
      @tab-click="changeTab"
      v-if="liveMode === Constant.LIVE_MODE.class"
    >
      <el-tab-pane name="chatList">
        <div slot="label">
          {{ generateColumns("chat") }}
          <span
            v-show="msgNumber - baseMsgNumber > 0 && activeName !== 'chatList'"
            >({{ msgNumber - baseMsgNumber }})</span
          >
        </div>
        <ChatList @sendMsg="sendMsg" @loadMoreMsg="loadMoreMsg"></ChatList>
      </el-tab-pane>
      <el-tab-pane :label="generateColumns('current_online')" name="onlineList">
        <span slot="label">
          {{ generateColumns("currently_online") }}({{ onlineNumber }})</span
        >
        <OnlineList></OnlineList>
      </el-tab-pane>
      <el-tab-pane name="handsList">
        <div slot="label">
          {{ generateColumns("speakers_list") }}
          <span v-show="handUpMember > 0">({{ handUpMember }})</span>
        </div>
        <HandList></HandList>
      </el-tab-pane>
    </el-tabs>
    <el-tabs
      v-model="privateActiveName"
      class="private_tab"
      stretch
      @tab-click="changeTab"
      v-else
    >
      <el-tab-pane name="chatListPrivate">
        <div slot="label" class="member_status">
          <div class="teacher">
            <span class="member_name">{{ generateColumns("me") }}</span>
            <div
              v-if="true"
              class="online_status online_status-on iconfont papa-icon-dian"
            >
              {{ generateColumns("online") }}
            </div>
            <div
              v-else
              class="online_status online_status-off iconfont papa-icon-dian"
            >
              {{ generateColumns("offline") }}
            </div>
          </div>
          <div class="student">
            <span class="member_name">{{ privateUser.cn_name }}</span>
            <div
              v-if="studentOnlineStatus"
              class="online_status online_status-on iconfont papa-icon-dian"
            >
              {{ generateColumns("online") }}
            </div>
            <div
              v-else
              class="online_status online_status-off iconfont papa-icon-dian"
            >
              {{ generateColumns("offline") }}
            </div>
          </div>
        </div>
        <ChatList @sendMsg="sendMsg" @loadMoreMsg="loadMoreMsg"></ChatList>
      </el-tab-pane>
    </el-tabs>
    <audio src="https://ncdn.papaen.com/assets/audio/enterInto.mp3" v-show="false" ref="enterInfoAudioRef" />
  </div>
</template>

<script>
import ChatroomJs from "./chatroom.js";

export default ChatroomJs;
</script>

<style lang="less" scoped>
.chatroom-module {
  width: 100%;
  height: 100%;

  /deep/ .el-tabs__item {
    color: #393d3a;
  }

  /deep/ .el-tabs__header {
    margin-bottom: 0;
  }

  /deep/ .el-tabs--top {
    height: 100%;

    & > .el-tabs__content {
      width: 100%;
      height: calc(100% - 40px);

      & > .el-tab-pane {
        height: 100%;
      }
    }
  }

  /deep/ .member_status {
    .teacher,
    .student {
      box-sizing: border-box;
      width: 50%;
      padding-left: 20px;
      float: left;
      display: flex;
      .member_name {
        font-size: 16px;
        color: #393d3a;
        font-weight: bold;
      }
      .online_status {
        font-size: 12px;
        margin-left: 12px;
      }
      .online_status-on {
        color: @papaGreen;
      }

      .online_status-off {
        color: #ccc;
      }
    }
  }

  .private_tab {
    /deep/ .el-tabs__active-bar {
      height: 1px;
      background-color: #e6e6e6;
    }
  }
}
</style>
