import TRTC from "trtc-js-sdk";
import EXT_RTC from "@/views/live/ext/rtc.js";
import CameraDetector from "../../modules/devicetest/components/cameraDetector.vue";
import MicDetector from "../devicetest/components/micDetector.vue";
import SpeakerDetector from "../devicetest/components/speakerDetector.vue";
import deviceConnect from "../devicetest/components/deviceConnect.vue";
import devicecheck from "./components/devicecheck.vue";
import { mapActions, mapState } from "vuex";
import { generateColumns,generateTips } from "@/utils/i18n";

export default {
  name: "",
  components: {
    CameraDetector,
    MicDetector,
    SpeakerDetector,
    deviceConnect,
    devicecheck,
  },
  props: {},
  data() {
    return {
      activeCameraDeviceId: "",
      activeSpeakerDeviceId: "",
      activeMicDeviceId: "",
      cameraList: [],
      micDeviceList: [],
      speakerList: [],
      localStream: null,
      rotateRenovate: false,
      firstandmin: true,
      isShowCheck: false,
      isShowCheckCamera: false,
      isShowCheckMicrophone: false,
      isShowCheckTrumpet: false,
      isShowfinishbtn: 0,
      checkdevices: {
        camera: "1",
        microphone: "1",
        trumpet: "1",
      },
      btnText: this.generateColumns('start_etection'),
      hinttext: this.generateTips('device_connecting'),
    };
  },
  computed: {
    ...mapState({
      remoteStream: (state) => state.rtc.remoteVideoStream,
      deviceState: (state) => state.footer.deviceState,
    }),
  },
  watch: {
    isShowfinishbtn: {
      handler(val) {
        if (val == 3) {
          this.btnText = this.generateColumns('detection_success');
        }
      },
    },
    deviceState: {
      handler(newVal) {
        if (newVal) {
          this.hinttext = this.generateTips('allow_browser');
          if (!newVal.hasCameraConnect) {
            this.hinttext = this.hinttext + this.generateColumns('camera');
            this.btnText = this.generateColumns('res_detection');
          } else if (!newVal.hasMicrophoneConnect) {
            this.hinttext = this.hinttext + this.generateColumns('microphone');
            this.btnText = this.generateColumns('res_detection');
          } else if (!newVal.hasSpeakerConnect) {
            this.hinttext = this.hinttext + this.generateColumns('peaker');
            this.btnText = this.generateColumns('res_detection');
          } else {
            this.hinttext = this.generateTips('device_connected');
            this.btnText = this.generateColumns('start_etection');
          }
        }
      },
    },
    activeCameraDeviceId: {
      handler(newVal) {
        this.setCameraDEviceId(newVal);
      },
      immediate: true,
      //立即监听
    },
    activeSpeakerDeviceId: {
      handler(newVal) {
        this.setSpeakerDeviceId(newVal);
        // console.log(newVal,TRTC);1
        // if(newVal){
        //   TRTC.setAudioOutputDevice(newVal,()=>{
        //     console.log('setAudioOutputDevice success');
        //   },err=>{
        //     console.log('setAudioOutputDevice failed',err);
        //   })
        // }
      },
      immediate: true,
    },
    activeMicDeviceId: {
      handler(newVal) {
        this.setMicDeviceId(newVal);
      },
      immediate: true,
    },
  },
  created() {},
  mounted() {
    this.getMicDeviceList();
    this.getSpeakerList();
    this.getCamerasList();
    this.setTimeHintText();
  },
  methods: {
    generateColumns,
    generateTips,
    renovate() {
      this.rotateRenovate = true;
      this.getMicDeviceList();
      this.getSpeakerList();
      this.getCamerasList();
      setTimeout(() => {
        this.rotateRenovate = false;
      }, 1000);
    },
    // 获取摄像头设备
    getCamerasList() {
      EXT_RTC.getCameras().then((res) => {
        console.log(res);
        let olddeviceIndex = res.findIndex((device) => {
          return device.deviceId === this.activeCameraDeviceId;
        });
        this.activeCameraDeviceId =
          olddeviceIndex < 0 ? res[0].deviceId : this.activeCameraDeviceId;
        this.cameraList = res;
      });
    },

    // 获取麦克风设备
    getMicDeviceList() {
      EXT_RTC.getDevices().then((res) => {
        console.log(res);
        let olddeviceIndex = res.findIndex((device) => {
          return device.deviceId === this.activeMicDeviceId;
        });
        this.activeMicDeviceId =
          olddeviceIndex < 0 ? res[0].deviceId : this.activeMicDeviceId;
        this.micDeviceList = res;
      });
    },

    // 获取扬声器设备
    getSpeakerList() {
      EXT_RTC.getSpeakers().then((res) => {
        let olddeviceIndex = res.findIndex((device) => {
          return device.deviceId === this.activeSpeakerDeviceId;
        });
        this.activeSpeakerDeviceId =
          olddeviceIndex < 0 ? res[0].deviceId : this.activeSpeakerDeviceId;
        this.speakerList = res;
      });
    },
    close() {
      this.$emit("closeDeviceTest");
    },
    setTimeHintText() {
      setTimeout(() => {
        this.firstandmin = false;
      }, 1000);
    },
    handleTest(text) {
      console.log(text);
      if (text == this.generateColumns('res_detection')) {
        this.firstandmin = true;
        this.setTimeHintText();
      } else if (text == this.generateColumns('start_etection')) {
        this.isShowCheck = true;
      } else if (text == this.generateColumns('detection_success')) {
        this.setTestStatus(true);
        sessionStorage.setItem("isTestDevice", false);
        this.close();
      }
      // this.hinttext = "设备正在连接中，请稍后";
    },
    checkTest(isOk, device) {
      if (device === "camera") {
        this.checkdevices.camera = isOk;
        this.isShowCheckCamera = true;
        this.isShowfinishbtn++;
      }
      if (device === "microphone") {
        this.checkdevices.microphone = isOk;
        this.isShowCheckMicrophone = true;
        this.isShowfinishbtn++;
      }
      if (device === "trumpet") {
        this.checkdevices.trumpet = isOk;
        this.isShowCheckTrumpet = true;
        this.isShowfinishbtn++;
      }
    },
    ...mapActions([
      "setTestStatus",
      "setCameraDEviceId",
      "setSpeakerDeviceId",
      "setMicDeviceId",
    ]),
  },
};
