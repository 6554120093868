<template>
  <div class="live_page">
    <span
      v-if="
        $route.path.indexOf('spoken/live') == 1 &&
        videoMode == 1 &&
        isShowPackUpVideo
      "
    >
    </span>
    <div class="main">
      <div class="count_time" v-if="isShowCountTime">
        <ShowCountTime
          :endTime="endTime"
          :endText="'计时结束'"
          @countTime="countTime"
        ></ShowCountTime>
      </div>

      <div class="setTimeout" v-if="isShowSetTimeOut">
        <Countdown @close="close" @confirm="confirm"></Countdown>
      </div>
      <div class="showFeedBack" v-if="iSshowFeedBack">
        <FeedBack
          @close="close"
          @confirm="confirm"
          @closeDialog="closeDialog"
        ></FeedBack>
      </div>
      <div class="devicetest" v-if="isShowdeviceTest && isTestDevice">
        <Devicetest @closeDeviceTest="closeDeviceTest"></Devicetest>
      </div>
      <!-- 视频模式 -->
      <!-- <div v-draggable> -->

      <!-- ref="myDraggable" -->
      <Video
        v-draggable
        :class="routeSpoken ? isShowPackUpVideo?'poken_video_mode_scale':'poken_video_mode' : 'video_mode' "
        v-show="
          (videoMode == 2 && isVideoCall) || (videoMode == 1 && isVideoCall)
        "
        :courseDesc="courseDesc"
        :privateUser="privateUser"
      >
      </Video>
·
      <!-- </div> -->

      <!-- 文件上传 直播模式为白板1 并且没有开启屏幕分享shareStatus 0关闭 1开启 或者是原来的直播 展示 -->
      <div
        class="main_left"
        :class="isShowPackUp ? '' : 'isShow_packUp'"
        v-show="
          (videoMode == 1 && !shareStatus) ||
          $route.path.includes('private/live')
        "
      >
        <File @showFileList="showFileList" :isShowPackUp="isShowPackUp"></File>
        <div
          class="pack_up"
          @click="packUp()"

        >
          <div class="pack_up_left" v-if="isShowPackUp">
            <i class="iconfont papa-icon-audio_play"></i>
          </div>
          <div class="pack_up_right" v-if="!isShowPackUp">
            <i class="iconfont papa-icon-audio_play"></i>
          </div>
        </div>
      </div>

      <div
        class="main_center"
        v-show="videoMode == 1 || $route.path.includes('private/live')"
      >
        <div class="countDownMask" v-show="flagRecordCountDown">
          <span :class="flagRecordCountDown ? 'countDown-active' : ''">{{
            countDownNum
          }}</span>
        </div>
        <board @beginClass="beforeBeginClass" :courseDesc="courseDesc"></board>
      </div>

      <div class="main_right">
        <!-- 只有private展示原来的rtc -->
        <!-- <div
          v-show="$route.path.includes('private/live') || videoMode == 1"
          class="draggable"
        >
          <div v-show="isVideoCall">
            <RTC :privateUser="privateUser"></RTC>
          </div>
        </div> -->

        <div
          class="right_bottom"
          :class="
            isVideoCall
              ? (isVideoCall && videoMode == 2) ||
                ($route.path.indexOf('spoken/live') == 1 && videoMode == 1)
                ? 'full_chat'
                : ''
              : 'full_chat'
          "
        >
          <!-- <div class="right_bottom" :class="isVideoCall ? '' : 'full_chat'"> -->
          <chat-room
            v-if="liveMode === Constant.LIVE_MODE.class"
            ref="chatroom"
            @loadMoreMsg="getHistory"
            @finishbus="finishBus"
          ></chat-room>
          <chat-room
            v-else
            ref="chatroom"
            :privateUser="privateUser"
            @loadMoreMsg="getHistory"
            @finishbus="finishBus"
          ></chat-room>
        </div>
      </div>
    </div>
    <div class="footer">
      <Footer
        @beginClass="beforeBeginClass"
        @quitClassroom="quitClassroom"
        @showFileList="showFileList"
        @startShareScreen="startShareScreen"
        @showSetTimeOut="showSetTimeOut"
        @showFeedBack="showFeedBack"
        :isVideoCall="isVideoCall"
        ref="footerRefs"
      ></Footer>
    </div>
    <papa-dialog
      class="file_list_dialog"
      :dialogVisible="fileListVisible"
      :show-close="false"
      :destroy-on-close="true"
      @dialogClose="dialogClose"
    >
      <div slot="dialog" class="file_list">
        <file-box
          @dialogClose="dialogClose"
          box-type="file"
          :file-box-title="generateColumns('select_courseware')"
        ></file-box>
      </div>
    </papa-dialog>
    <papa-dialog
      class="file_list_dialog"
      :dialogVisible="audioListVisible"
      :show-close="false"
      @dialogClose="dialogClose"
    >
      <div slot="dialog" class="file_list">
        <file-box
          @dialogClose="dialogClose"
          box-type="audio"
          :file-list="audioList"
          :file-box-title="generateColumns('select_audio')"
        ></file-box>
      </div>
    </papa-dialog>
    <papa-dialog
      class="announcement_dialog"
      :dialog-visible="isShowNotificationBox"
      :show-close="false"
      @dialogClose="dialogClose"
    >
      <div slot="dialog" class="announcement_info">
        <div class="announcement_box">
          <div class="box_top">
            <div class="left">{{ generateColumns("notice_board") }}</div>
            <i class="iconfont papa-icon-close"></i>
          </div>
          <div class="box_info">
            <textarea
              v-model="announcement"
              name=""
              id=""
              maxlength="100"
              :placeholder="generateTips('say_to_students') + '......'"
            >
            </textarea>
            <div class="info_footer">
              <div class="footer_left">{{ announcement.length }}/100</div>
              <div
                class="footer_button"
                @click="modifyGroup({ notification: announcement })"
              >
                {{ generateColumns("confirm_publish") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </papa-dialog>
    <papa-dialog
      class="big_pic_box"
      :dialog-visible="isShowBigPic"
      @dialogClose="dialogClose"
    >
      <div slot="dialog">
        <img draggable="false" width="100%" :src="bigImgSrc" alt="" />
      </div>
    </papa-dialog>
    <open-camera v-if="isShowOpenCameraDialog"
                 @beginClass="beginClassWithOpenCamera"
                 @closeDialog="onCloseCameraTip"></open-camera>
  </div>
</template>

<script>
import LiveJs from "./live.js";

export default LiveJs;
</script>

<style lang="less" scoped>
.spoken_main {
  position: relative;
}
.isShow_packUp {
  width: 0 !important;
}

.live_page {
  width: 100%;
  height: 100%;
  height: 100vh;
  margin: 0 auto;
  font-family: "PingFang SC", "Helvetica Neue", "Hiragino Sans GB", "Segoe UI",
    "Microsoft YaHei", "微软雅黑", sans-serif;
  position: relative;
  .count_time {
    position: absolute;
    left: 35%;
    transform: translate(calc(-50%), 0);
    z-index: 999;
  }
  .setTimeout {
    z-index: 1000;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 348px;
    height: 223px;
    border-radius: 8px;
    transform: translate(-50%, -50%);
    // 阴影
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  }
  .showFeedBack {
    z-index: 999;
    position: absolute;
    left: 40%;
    bottom: 50px;
  }
  .devicetest {
    z-index: 1000;
    position: absolute;
    left: 25%;
    bottom: 50px;
  }
  .main {
    width: 100%;
    height: calc(100vh - 38px);
    display: flex;
    .video_mode {
      width: 100%;
    }
    .main_left {
      // min-width: 90px;
      width: 11.3%;
      height: 100%;
      background: #ccc;
      z-index: 2;
      position: relative;
      .tools_pen {
        position: absolute;
        top: 80%;
        right: 0px;
      }
      .pack_up {
        width: 32px;
        height: 72px;
        background: #ebebeb;
        position: absolute;
        bottom: -2%;
        right: -32px;
        transform: translate(0, -50%);
        border-radius: 0 12px 12px 0;
        .pack_up_left {
          .papa-icon-audio_play {
            display: inline-block;
            transform: rotate(180deg);
            //翻转向左
            font-size: 26px;
            color: rgba(0, 0, 0, 0.7);
          }
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .pack_up_right {
          //向右的三角
          .papa-icon-audio_play {
            //翻转向左
            font-size: 26px;
            color: rgba(0, 0, 0, 0.7);
          }
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .main_center {
      min-width: 640px;
      width: 100%;
      height: 100%;
      position: relative;
      background: #575757;

      .countDownMask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        line-height: calc(100vh - 45px);
        color: @papaGreen;
        z-index: 99;
        font-size: 150px;
      }

      @keyframes count {
        from {
          transform: scale(0.1);
          opacity: 1;
        }
        to {
          transform: scale(3.5);
          opacity: 0;
          display: none;

        }
      }

      .countDown-active {
        animation: count 1s;
      }
    }

    .main_right {
      width: 330px;
      height: 100%;
      min-width: 330px;
      position: relative;
      .draggable {
        position: absolute;
      }

      .right_top {
        background: #424242;
        width: 100%;
        height: calc(25vw * 3 / 4);
      }

      .right_bottom {
        width: 100%;
        height: 100%;
        // height: calc(100% - 25vw * 3 / 4);
      }

      .full_chat {
        height: 100%;
      }
    }
  }

  .file_list_dialog,
  .announcement_dialog {
    /deep/ .el-dialog__header {
      padding-top: 0;
    }

    /deep/ .el-dialog__body {
      padding: 0;
    }
  }

  .announcement_dialog {
    /deep/ .el-dialog__header {
      padding: 0;
    }
  }

  .announcement_box {
    width: 100%;
    height: 158px;
    background: #fff;
    position: relative;
    border-radius: 6px;

    .box_top {
      box-sizing: border-box;
      background: #f1f1f1;
      width: 100%;
      height: 40px;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
    }

    .box_info {
      width: 100%;
      height: 118px;

      textarea {
        box-sizing: border-box;
        width: 100%;
        height: 68px;
        border: none;
        outline: none;
        resize: none;
        padding: 10px;
        color: #333;
      }

      .info_footer {
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        width: 100%;
        padding: 10px;

        .footer_button {
          width: 60px;
          height: 26px;
          background: @papaGreen;
          font-size: 12px;
          color: #fff;
          text-align: center;
          line-height: 26px;
          border-radius: 3px;
          cursor: pointer;
        }
      }
    }
  }

  .disAbleMask {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: not-allowed;
    z-index: 999;
  }

  .footer {
    width: 100%;
    height: 38px;
    background: #333439;
  }
}
.poken_video_mode {
  width: 300px;
  height: 532px;
  z-index: 9999;
  //阴影
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  display: inline-block;
  position: absolute;
}
.poken_video_mode_scale{
  width: 300px;
  height: 532px;
  // width: 150px;
  // height: 266px;
  z-index: 9999;
  scale: 0.5;
  // padding-left: 75px;
  // padding-top: 133px;
  //阴影
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  //过渡动画
  // transition: all 0.2s;
  display: inline-block;
  position: absolute;
}
</style>
