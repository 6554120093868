const draggable = {
  inserted: function(el, binding) {
    console.log(el);
    el.style.cursor = "move";
    (el.style.right = 0),
      (el.style.top = "10%"),
      (el.onmousedown = function(e) {
        let disx = e.pageX - el.offsetLeft;
        let disy = e.pageY - el.offsetTop;
        document.onmousemove = function(e) {
          let x = e.pageX - disx;
          let y = e.pageY - disy;
          let maxX =
            document.body.clientWidth -
            parseInt(window.getComputedStyle(el).width);
          let maxY =
            document.body.clientHeight -
            parseInt(window.getComputedStyle(el).height);
          if (x < 0) {
            x = 0;
          } else if (x > maxX) {
            x = maxX;
          }

          if (y < 0) {
            y = 0;
          } else if (y > maxY) {
            y = maxY;
          }

          el.style.left = x + "px";
          el.style.top = y + "px";
        };
        document.onmouseup = function() {
          document.onmousemove = document.onmouseup = null;
        };
      });
  },
  update(el, bingding) {
    console.dir(el);
    el.style.cursor = "move";
    (el.style.right = 0),
      (el.style.top = "10%"),
      (el.onmousedown = function(e) {
        console.log(e.pageX);
        let disx = e.pageX - el.offsetLeft;
        let disy = e.pageY - el.offsetTop;
        document.onmousemove = function(e) {
          let x
          let y
          if(el.classList[0]=="poken_video_mode_scale"){
             x = e.pageX - disx-75;
             y = e.pageY - disy-133;
          }else{
             x = e.pageX - disx;
             y = e.pageY - disy;
          }
          let maxX =
            document.body.clientWidth -
            parseInt(window.getComputedStyle(el).width);
          let maxY =
            document.body.clientHeight -
            parseInt(window.getComputedStyle(el).height);
          if (x < -75) {
           // x = 0;
          } else if (x > maxX) {
            x = maxX;
          }

          if (y < -133) {
            // y = 0;
          } else if (y > maxY) {
            y = maxY;
          }
          if(el.classList[0]=="poken_video_mode_scale"){
            //如果在中心左边-75
            if(el.style.left=="75px"){
              el.style.left = (x) + "px";
            }else{
              el.style.left = (x+75) + "px";
            }
            if(el.style.top=='133px'){
              el.style.top = y + "px";
            }else{
              el.style.top = (y+133) + "px";
            }
          }else{
            el.style.left = x + "px";
            el.style.top = y + "px";
          }
        };
        document.onmouseup = function() {
          document.onmousemove = document.onmouseup = null;
        };
      });
    }
};
export default draggable;
