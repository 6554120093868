<template>
  <div
    :class="
      $route.path.includes('private/live')
        ? 'video-module'
        : $route.path.includes('spoken/live') && videoMode == 1
        ? 'video-module'
        : 'video_module_zhibo'
    "
  >
    <div class="left">
      <div id="teacher_video_wrapper" class="teacher_video_wrapper">
        <div class="local_video-default" :class="hasLocalVideo ? 'hidden' : ''">
          <i class="iconfont papa-icon-video_off"></i>
        </div>
        <div class="local_video"></div>
      </div>
    </div>
    <div class="right">
      <div
        v-show="liveMode === Constant.LIVE_MODE.private"
        id="remote_video_wrapper"
        class="remote_video_wrapper"
        :style="[
          {
            background:
              remoteVideoStream && remoteVideoStream.hasVideo() ? 'black' : '',
          },
        ]"
      >
        <div>
          <i class="iconfont papa-icon-video_off"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RtcJs from "./rtc.js";

export default RtcJs;
</script>

<style lang="less" scoped>
.p_rtc {
  width: 100%;
}
.video-module {
  width: 100%;
  height: 100%;
  // position: relative;

  // & > .video_wrap {
  //   width: 100%;
  //   height: 100%;
  //   position: relative;

  //   .big {
  //     width: 100%;
  //     height: 100%;
  //     cursor: pointer;
  //     position: absolute;
  //   }

  //   .small {
  //     position: absolute;
  //     right: 0;
  //     bottom: 0;
  //     width: 120px;
  //     height: 90px;
  //     z-index: 999;
  //   }

  //   .remote_video,
  //   .local_video {
  //     width: 100%;
  //     height: 100%;
  //   }

  //   .online_status {
  //     font-size: 12px;
  //     position: absolute;
  //     bottom: 12px;
  //     left: 16px;
  //     z-index: 99;
  //   }

  //   .online_status-on {
  //     color: @papaGreen;
  //   }

  //   .online_status-off {
  //     color: @papaRed;
  //   }

  //   /deep/ video {
  //     z-index: 99;
  //   }

  //   /deep/ .remote_video {
  //     width: 100%;
  //     height: 100%;
  //   }

  //   .local_video-default {
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     width: 100%;
  //     height: 100%;
  //     background: #424242;
  //     z-index: 9;
  //   }

  //   .papa-icon-video_off {
  //     position: absolute;
  //     top: 50%;
  //     margin-top: -30px;
  //     left: 50%;
  //     margin-left: -30px;
  //     font-size: 60px;
  //   }

  //   .papa-icon-video_off {
  //     color: #b6b6b6;
  //   }

  //   .remote_video-default {
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     z-index: 9;
  //     width: 100%;
  //     height: 100%;
  //     background: #1a1a1a;

  //     .papa-icon-video_off {
  //       position: absolute;
  //       top: 50%;
  //       margin-top: -15px;
  //       left: 50%;
  //       margin-left: -15px;
  //       font-size: 30px;
  //       z-index: 9;
  //     }
  //   }
  // }

  // & > .tool-bar {
  //   width: 100%;
  //   height: 54px;
  //   position: absolute;
  //   background: #2e2e2e;
  //   opacity: 0.5;
  //   position: absolute;
  //   bottom: 0;
  //   left: 0;
  //   color: #ffffff;
  //   line-height: 100%;

  //   .resolution {
  //     position: absolute;
  //     width: 80px;
  //     height: 100%;
  //     top: 0;
  //     left: 0;

  //     .resolution_box {
  //       width: 100%;
  //       position: absolute;
  //       top: -62px;
  //       left: 0;
  //       border: 1px solid #ccc;
  //     }

  //     .resolution_item {
  //       width: 100%;
  //       height: 20px;
  //       text-align: center;
  //       line-height: 20px;
  //       cursor: pointer;
  //     }

  //     .resolution_choose {
  //       width: 100%;
  //       height: 100%;
  //       line-height: 54px;
  //       text-align: center;
  //       cursor: pointer;
  //     }
  //   }
  // }
}
.video_module_zhibo {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  .left,
  .right {
    height: 700px;
    width: 420px;
    background: #333333;
  }
  .teacher_video_wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    .local_video-default {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #424242;
      z-index: 9;
    }

    .papa-icon-video_off {
      position: absolute;
      top: 50%;
      margin-top: -30px;
      left: 50%;
      margin-left: -30px;
      font-size: 60px;
    }

    .papa-icon-video_off {
      color: #b6b6b6;
    }

    .hidden {
      display: none;
    }

    .local_video {
      width: 100%;
      height: 100%;
      /deep/video {
        object-fit: inherit !important;
      }
    }
  }
  .remote_video_wrapper {
    width: 100%;
    height: 100%;
    background: #424242;
    position: relative;
    /deep/ .remote_video {
      // height: 100% !important;
      background: #424242 !important;
      width: 100% !important;
      height: 100%;
      position: relative;
      top: 50%;
      transform: translate(0, -50%);
    }
    /deep/video {
      object-fit: inherit !important;
    }
    .hidden {
      // background-color: black !important;
    }
    .papa-icon-video_off {
      position: absolute;
      color: #b6b6b6;
      top: 50%;
      margin-top: -30px;
      left: 50%;
      margin-left: -30px;
      font-size: 60px;
    }
  }
}
</style>
