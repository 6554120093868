<template>
  <div class="board-module">
    <div
      :class="
        routeIsSpoken
          ? 'class-on iconfont papa-icon-dian'
          : 'class-on-skpoen iconfont papa-icon-dian'
      "
      v-show="classStatus === 1"
    >
      {{ routeIsSpoken ? generateColumns("course_living") : generateColumns("in_class") }}
    </div>
    <div
      :class="routeIsSpoken ? 'netWorkQuality' : 'netWorkQuality_spoken'"
      v-show="classStatus === 1"
    >
      <div class="upload">
        <div>{{ generateColumns("up") }}</div>
        <div class="qualityList">
          <template v-if="upQuality === 0">
            <div class="item item-one"></div>
            <div class="item item-two"></div>
            <div class="item item-three"></div>
            <div class="item item-four"></div>
            <div class="item item-five"></div>
          </template>
          <template v-else>
            <div
              class="item item-one"
              :class="upQuality < 5 ? 'item-highlight' : ''"
            ></div>
            <div
              class="item item-two"
              :class="upQuality < 4 ? 'item-highlight' : ''"
            ></div>
            <div
              class="item item-three"
              :class="upQuality < 3 ? 'item-highlight' : ''"
            ></div>
            <div
              class="item item-four"
              :class="upQuality < 2 ? 'item-highlight' : ''"
            ></div>
            <div
              class="item item-five"
              :class="upQuality <= 1 ? 'item-highlight' : ''"
            ></div>
          </template>
        </div>
      </div>
      <div class="download">
        <div>{{ generateColumns("down") }}</div>
        <div class="qualityList">
          <template v-if="downQuality === 0">
            <div class="item item-one"></div>
            <div class="item item-two"></div>
            <div class="item item-three"></div>
            <div class="item item-four"></div>
            <div class="item item-five"></div>
          </template>
          <template v-else>
            <div
              class="item item-one"
              :class="downQuality < 5 ? 'item-highlight' : ''"
            ></div>
            <div
              class="item item-two"
              :class="downQuality < 4 ? 'item-highlight' : ''"
            ></div>
            <div
              class="item item-three"
              :class="downQuality < 3 ? 'item-highlight' : ''"
            ></div>
            <div
              class="item item-four"
              :class="downQuality < 2 ? 'item-highlight' : ''"
            ></div>
            <div
              class="item item-five"
              :class="downQuality <= 1 ? 'item-highlight' : ''"
            ></div>
          </template>
        </div>
      </div>
    </div>
    <div :class="routeIsSpoken ? 'course_desc' : 'course_desc_spoken'">
      <div class="desc_title">{{ courseDesc.title }}</div>
      <div class="desc_time">{{ courseDesc.time }}</div>
      <div class="current_time" v-show="routeIsSpoken">{{ tt }}</div>
    </div>

    <!-- <div
      class="left-btn iconfont papa-icon-rt"
      v-show="isShowPageBtn"
      @click="changeCourseware('-1')"
      v-if="$route.path.includes('private/live')"
    ></div> -->
    <div
      class="paint_box"
      @mouseenter="mouseOverBoard"
      @mouseleave="mouseLeaveBoard"
      v-show="shareStatus === 0"
    >
      <div class="btns_paint_info">
        <mask-con v-show="classStatus !== 1 && teduBoard">
          <button class="begin-class" @click="beginClass" :disabled="isBenginClassDisabled"> {{ generateColumns("start_class") }}</button>
        </mask-con>
        <div
          class="paint_info"
          id="paint_box"
          v-show="$route.path.includes('private/live') || videoMode === 1"
        ></div>
        <div class="change_btn">
          <div class="pack_up_left_btn" @click="changeCourseware('-1')">
            <i class="left-btn iconfont papa-icon-rt"></i>
          </div>
          <div v-if="currentFile" class="page">
            {{ currentFile.currentPageIndex + 1 }}/{{ currentFile.pageCount }}
          </div>
          <div class="pack_up_right_btn" @click="changeCourseware(1)">
            <i class="iconfont papa-icon-right"></i>
          </div>
        </div>
        <!-- 视频 -->
        <!-- <div
          :class="isShowPackUp ? 'close_video' : 'close_video_left'"
          @click="handelPackUpVideo"
          v-show="isShowPackUpVideo"
        >
          <div class="triangle"></div>
          <div class="text">{{ generateColumns("video_window") }}</div>
        </div> -->
      </div>
    </div>

    <div
      class="share-video-wrap_spoken"
      v-show="shareStatus === 1"
      id="share_video_wrap"
    >
      <div class="share_video"></div>

      <!-- <div class="close_video_footer" v-if="shareStatus === 1 && packupvideo">
        <div class="open_video" @click="openPackupVideo"></div>
        <div class="text">{{ generateColumns("video_window") }}</div>
      </div> -->
    </div>
    <!-- 在spoken页面并且开启屏幕分享 关闭画笔 -->
    <span v-show="classStatus == 1">
      <div
        class="spoken_pen_tools"
        v-show="!shareStatus"
      >
        <transition
          :duration="1000"
          enter-active-class="animated bounceInLeft"
          :class="isShowTools ? '' : ''"
          leave-active-class="animated bounceOutLeft"
        >
          <div
            class="close-tools"
            v-if="!isShowTools"
          >
            <div class="spoken_tool_box">
              <div
                class="iconfont papa-icon-editor_laser"
                :class="currentToolType === 3 ? 'active' : ''"
                @click="onSetToolType(3)"
              ></div>
              <div
                class="iconfont papa-icon-editor_pen"
                :class="currentToolType === 1 ? 'active' : ''"
                @click.stop="onSetToolType(1)"
              >
                <ColorList
                  :class="currentToolType === 1 ? 'no_active' : ''"
                  v-if="currentToolType === 1"
                  @setColor="onSetBrushColor"
                  :currentToolColor="brushColor"
                ></ColorList>
              </div>
              <div
                class="iconfont papa-icon-editor_line"
                :class="currentToolType === 4 ? 'active' : ''"
                @click="onSetLineToolType(1)"
              ></div>
              <div
                class="iconfont papa-icon-editor_arrow"
                :class="currentToolType === 10 ? 'active' : ''"
                @click="onSetLineToolType(2)"
              ></div>
              <div
                class="iconfont papa-icon-editor_box"
                :class="currentToolType === 6 ? 'active' : ''"
                @click="onSetToolType(6)"
              ></div>
              <div
                class="iconfont papa-icon-editor_font"
                :class="currentToolType === 11 ? 'active' : ''"
                @click="onSetToolType(11)"
              >
                <ColorList
                  :class="currentToolType === 11 ? 'no_active' : ''"
                  v-if="currentToolType === 11"
                  @setColor="onSetTextColor"
                  :current-tool-color="textColor"
                ></ColorList>
              </div>
              <div
                class="iconfont papa-icon-fangdasuoxiao_X"
                @click="zoomBoard(10)"
              ></div>
              <div
                class="iconfont papa-icon-fangdasuoxiao_Y"
                @click="zoomBoard(-10)"
              ></div>
              <div
                class="iconfont papa-icon-editor_go_back"
                @click="onUndo()"
              ></div>
              <div
                class="iconfont papa-icon-yidong"
                :class="currentToolType === 12 ? 'active' : ''"
                @click="onSetToolType(12)"
              ></div>
              <div
                class="iconfont papa-icon-editor_delete"
                @click="onclearDraws()"
              ></div>
            </div>
            <!-- <div
              class="close-btn"
              @click="closeToolBar"
              v-if="$route.path.includes('private')"
            >
              <div class="triangle"></div>
            </div> -->
          </div>
        </transition>
      </div>
    </span>

    <audio-bar v-if="isShowAudioBar"></audio-bar>
  </div>
</template>

<script>
import BoardJs from "./board.js";

export default BoardJs;
</script>

<style lang="less" scoped>
.spoken_tool_box {
  width: 50px;
  height: 530px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  background: #ffffff;
  border-radius: 0px 8px 8px 0px;
  & > .close_tool_bar {
    cursor: pointer;
    width: 16px;
    height: 4px;
    border-radius: 1px;
    background-color: #434343;
  }

  & > .iconfont {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.9);
    font-weight: bold;
    position: relative;
  }

  & > .active {
    color: #29d087;
    scale: 0.8;
    .no_active {
      scale: 1.4;
    }
  }
}

.spoken_pen_tools {
  position: absolute;
  top: 70px;

  .iconfont {
    cursor: pointer;
  }

  & > .close-tools {
    & > .tool-box {
      width: 680px;
      height: 50px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      background: #fff;
      border-radius: 3px;

      & > .close_tool_bar {
        cursor: pointer;
        width: 16px;
        height: 4px;
        border-radius: 1px;
        background-color: #434343;
      }

      & > .iconfont {
        font-size: 22px;
        color: #9d9d9d;
        font-weight: bold;
        position: relative;
      }

      & > .active {
        color: #29d087;
      }
    }

    & > .close-btn {
      cursor: pointer;
      position: absolute;
      right: -30px;
      top: 2px;
      width: 30px;
      height: 46px;
      background: #29d087;
      border-radius: 0 5px 5px 0;

      & > .triangle {
        position: absolute;
        top: 13px;
        left: 0px;
        width: 0;
        height: 0;
        border-width: 10px;
        border-style: solid;
        border-color: transparent #fff transparent transparent;
      }
    }
  }

  & > .open-tools {
    & > .close-btn {
      cursor: pointer;
      position: absolute;
      left: -88px;
      bottom: -50px;
      width: 10vh;
      height: 46px;
      background: #f2f2f2;
      height: 3vh;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      & > .triangle {
        width: 15px;
        height: 13px;
        border: 2px solid black;
        border-top: 4px solid black;
        margin-right: 10px;
        box-sizing: border-box;
        &::after {
          content: "";
          position: absolute;
          top: 9px;
          left: 31px;
          width: 1px;
          height: 13px;
          background-color: #8b8b8b;
        }
      }
      .text {
        font-size: 12px;
        color: #8b8b8b;
      }
    }
  }
}
.pen_brush {
  .pen-tools {
    position: absolute;
    right: 10px;
    bottom: 10px;

    .iconfont {
      cursor: pointer;
    }

    & > .close-tools {
      & > .tool-box {
        width: 680px;
        height: 50px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        background: #fff;
        border-radius: 3px;

        & > .close_tool_bar {
          cursor: pointer;
          width: 16px;
          height: 4px;
          border-radius: 1px;
          background-color: #434343;
        }

        & > .iconfont {
          font-size: 22px;
          color: #9d9d9d;
          font-weight: bold;
          position: relative;
        }

        & > .active {
          color: #29d087;
        }
      }

      & > .close-btn {
        cursor: pointer;
        position: absolute;
        right: -30px;
        top: 2px;
        width: 30px;
        height: 46px;
        background: #29d087;
        border-radius: 0 5px 5px 0;

        & > .triangle {
          position: absolute;
          top: 13px;
          left: 0px;
          width: 0;
          height: 0;
          border-width: 10px;
          border-style: solid;
          border-color: transparent #fff transparent transparent;
        }
      }
    }

    & > .open-tools {
      & > .close-btn {
        cursor: pointer;
        position: absolute;
        left: -88px;
        bottom: -50px;
        width: 10vh;
        height: 46px;
        background: #f2f2f2;
        height: 3vh;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        & > .triangle {
          width: 15px;
          height: 13px;
          border: 2px solid black;
          border-top: 4px solid black;
          margin-right: 10px;
          box-sizing: border-box;
          &::after {
            content: "";
            position: absolute;
            top: 9px;
            left: 31px;
            width: 1px;
            height: 13px;
            background-color: #8b8b8b;
          }
        }
        .text {
          font-size: 12px;
          color: #8b8b8b;
        }
      }
    }
  }
}

.board-module {
  width: 100%;
  height: 100%;
  position: relative;
  background: #575757;
  padding: 6% 0;
  box-sizing: border-box;
  .netWorkQuality_spoken {
    position: absolute;
    top: 21px;
    right: 20px;
    z-index: 999;
    font-size: 14px;
    color: #fff;
    display: flex;

    .upload,
    .download {
      display: flex;
      align-items: center;
      margin-right: 10px;
    }

    .qualityList {
      display: flex;
      align-items: baseline;
      margin-left: 6px;

      .item {
        background: #fff;
        margin-right: 2px;
        width: 2px;
      }

      .item-highlight {
        background: #29d087;
      }

      .item-one {
        height: 2px;
      }

      .item-two {
        height: 4px;
      }

      .item-three {
        height: 6px;
      }

      .item-four {
        height: 8px;
      }

      .item-five {
        height: 10px;
      }
    }
  }
  .netWorkQuality {
    position: absolute;
    top: 21px;
    left: 160px;
    z-index: 999;
    color: #fff;
    font-size: 14px;
    display: flex;

    .upload,
    .download {
      display: flex;
      align-items: center;
      margin-right: 10px;
    }

    .qualityList {
      display: flex;
      align-items: baseline;
      margin-left: 6px;

      .item {
        background: #fff;
        margin-right: 2px;
        width: 2px;
      }

      .item-highlight {
        background: #29d087;
      }

      .item-one {
        height: 2px;
      }

      .item-two {
        height: 4px;
      }

      .item-three {
        height: 6px;
      }

      .item-four {
        height: 8px;
      }

      .item-five {
        height: 10px;
      }
    }
  }

  .begin-class {
    width: 80px;
    height: 40px;
    background: #29d087;
    text-align: center;
    line-height: 40px;
    border-radius: 6px;
    font-size: 16px;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -20px;
    margin-left: -40px;
    border: none;
    cursor: pointer;
  }

  & > .class-on {
    color: red;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 999;
  }
  .class-on-skpoen {
    color: red;
    position: absolute;
    top: 24px;
    left: 105px;
    z-index: 999;
    font-size: 12px;
  }
  .course_desc_spoken {
    position: absolute;
    top: 20px;
    left: 30px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    color: #fff;
    z-index: 999;

    .desc_title {
      font-weight: bold;
      font-size: 16px;
      margin-right: 20px;
    }

    .desc_time,
    .current_time {
      font-size: 12px;
      color: #ccc;
    }
    .desc_time {
      margin-top: 5px;
    }
    .current_time {
      width: 5em;
      margin-left: 5px;
    }
  }
  .course_desc {
    position: absolute;
    top: 20px;
    right: 30px;
    display: flex;
    color: #fff;
    align-items: center;
    z-index: 999;

    .desc_title {
      font-weight: bold;
      font-size: 16px;
      margin-right: 20px;
    }

    .desc_time,
    .current_time {
      font-size: 12px;
      color: #ccc;
    }

    .current_time {
      width: 5em;
      margin-left: 5px;
    }
  }

  & > .left-btn,
  .right-btn {
    width: 60px;
    height: 80px;
    font-size: 20px;
    color: #fff;
    text-align: center;
    line-height: 80px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    position: absolute;
    font-weight: bold;
    cursor: pointer;
  }

  & > .left-btn {
    left: 10px;
    top: 50%;
  }

  & > .right-btn {
    right: 10px;
    top: 50%;
  }

  & > .paint_box {
    width: 77%;
    height: 100%;
    margin: 0 10%;
    position: relative;
    box-sizing: border-box;
  }
  .btns_paint_info {
    width: 98%;
    // height: calc(73vw * 0.8 * 3 / 4);
    height: calc(100vh - 38px - 11% );
    position: absolute;
    top: 49%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .change_btn {
    z-index: 999;
    position: absolute;
    bottom: -44px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .page {
      color: white;
      padding-bottom: 4px;
    }
    .pack_up_left_btn,
    .pack_up_right_btn {
      width: 40px;
      height: 40px;
      background: #434343;
      border-radius: 50%;
      cursor: pointer;
      i {
        font-size: 18px;
        color: white;
        //居中
        display: block;
        width: 100%;
        text-align: center;
        line-height: 40px;
      }
    }
  }

  .close_video {
    z-index: 999;
    position: absolute;
    bottom: -40px;
    right: 0;
    width: 109px;
    height: 28px;
    border-radius: 5px;
    background-color: white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    .triangle {
      width: 14px;
      height: 8px;
      border: 2px solid rgba(0, 0, 0, 0.9);
      border-top: 5px solid rgba(0, 0, 0, 0.9);
      border-radius: 3px;
      margin-top: 3px;
      &::after {
        content: "";
        position: absolute;
        top: 9px;
        left: 40px;
        width: 1px;
        height: 13px;
        background-color: #8b8b8b;
      }
    }
    .text {
      font-size: 12px;
      color: #8b8b8b;
    }
  }
  .close_video_left {
    z-index: 999;
    position: absolute;
    bottom: -40px;
    right: 65px;
    width: 109px;
    height: 28px;
    border-radius: 5px;
    background-color: white;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    .triangle {
      width: 15px;
      height: 13px;
      border: 2px solid black;
      border-top: 4px solid black;
      margin-right: 10px;
      box-sizing: border-box;

      &::after {
        content: "";
        position: absolute;
        top: 9px;
        left: 38px;
        width: 1px;
        height: 13px;
        background-color: #8b8b8b;
      }
    }
    .text {
      font-size: 12px;
      color: #8b8b8b;
    }
  }
  .paint_info {
    overflow: hidden;
    width: 99%;
    height: calc(100vh - 38px - 88px );
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: calc(30px);
    .pen_brush {
      z-index: 999;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  & > .share-video-wrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    .share_video {
      width: 100%;
      height: 100%;
    }
  }
  & > .share-video-wrap_spoken {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 87%;
    height: 87%;
    transform: translate(-50%, -50%);
    .share_video {
      width: 100%;
      height: 100%;
    }
    .close_video_footer {
      position: absolute;
      right: 0;
      bottom: -35px;
      width: 109px;
      height: 28px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-around;
      align-items: center;
      cursor: default !important;
      .open_video {
        width: 14px;
        height: 8px;
        border: 2px solid rgba(0, 0, 0, 0.9);
        border-top: 5px solid rgba(0, 0, 0, 0.9);
        border-radius: 3px;
        position: relative;
        cursor: pointer !important;

        &::after {
          position: absolute;
          right: -14px;
          top: -3px;
          content: "";
          display: block;
          width: 1px;
          height: 12px;
          background-color: #d9d9d9;
        }
      }
      .text {
        color: #8b8b8b;
        line-height: 2px;
        font-size: 12px;
      }
    }
  }

  & > .pen-tools {
    position: absolute;
    left: 0;
    bottom: 10px;

    .iconfont {
      cursor: pointer;
    }

    & > .close-tools {
      & > .tool-box {
        width: 680px;
        height: 50px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        background: #fff;
        border-radius: 3px;

        & > .move-tool-btn {
          cursor: pointer;
        }

        & > .iconfont {
          font-size: 22px;
          color: #9d9d9d;
          font-weight: bold;
          position: relative;
        }

        & > .active {
          color: #29d087;
        }
      }

      & > .close-btn {
        cursor: pointer;
        position: absolute;
        right: -30px;
        top: 2px;
        width: 30px;
        height: 46px;
        background: #29d087;
        border-radius: 0 5px 5px 0;

        & > .triangle {
          position: absolute;
          top: 13px;
          left: 0px;
          width: 0;
          height: 0;
          border-width: 10px;
          border-style: solid;
          border-color: transparent #fff transparent transparent;
        }
      }
    }

    & > .open-tools {
      & > .close-btn {
        cursor: pointer;
        position: absolute;
        left: 0;
        bottom: 0px;
        width: 30px;
        height: 46px;
        background: #29d087;
        border-radius: 0 5px 5px 0;

        & > .triangle {
          position: absolute;
          top: 13px;
          left: 10px;
          width: 0;
          height: 0;
          border-width: 10px;
          border-style: solid;
          border-color: transparent transparent transparent #fff;
        }
      }
    }
  }
}
</style>
