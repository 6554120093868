import { mapState } from "vuex";
import { RESOLUTION } from "@/views/live/constants/constant";
import EXT_RTC from "@/views/live/ext/rtc";
import * as Constant from "@/views/live/constants/constant";

export default {
  data() {
    return {
      Constant,
      viewType: 1,
      RESOLUTION,
      resolutionBoxStatus: false,
      resolutionValue: "标清",
      hasLocalVideo: false,
    };
  },
  computed: {
    ...mapState({
      classStatus: (state) => state.live.classStatus,
      liveMode: (state) => state.live.liveMode,
      trtcClient: (state) => state.rtc.trtcClient,
      localStream: (state) => state.rtc.localStream,
      remoteVideoStream: (state) => state.rtc.remoteVideoStream,
      cameraStatus: (state) => state.live.cameraStatus,
      videoMode: (state) => state.footer.videoMode,
    }),
  },
  props: {
    privateUser: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
  mounted() {
    if (this.remoteVideoStream) {
      if (this.classStatus !== 1) {
        this.remoteVideoStream.muteAudio();
      }
    }
  },
  methods: {
    changeView() {
      if (this.liveMode === Constant.LIVE_MODE.private) {
        this.viewType = this.viewType === 1 ? 2 : 1;
      } else {
        return;
      }
    },
    changeResolution(type) {
      EXT_RTC.stopPush(this.trtcClient, this.localStream).then(() => {
        switch (type) {
          case "SD":
            this.resolutionValue = "标清";
            EXT_RTC.startRTC(true, false, RESOLUTION.SD);
            break;
          case "HD":
            this.resolutionValue = "高清";
            EXT_RTC.startRTC(true, false, RESOLUTION.HD);
            break;
          case "UHD":
            this.resolutionValue = "超清";
            EXT_RTC.startRTC(true, false, RESOLUTION.UHD);
            break;
          default:
            EXT_RTC.startRTC(true, false, RESOLUTION.SD);
            break;
        }
      });
    },
    showResolutionBox() {
      this.resolutionBoxStatus = !this.resolutionBoxStatus;
    },
  },
  watch: {
    cameraStatus(newVal) {
      if (newVal === 0) {
        this.hasLocalVideo = false;
      } else {
        this.hasLocalVideo = true;
      }
    },
  },
};
