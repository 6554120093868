<template>
  <div class="p_count_time">
    <i class="iconfont papa-icon-close" @click="close"></i>
    <div class="count_time">
      <span :endTime="endTime" :endText="endText">
        <slot>{{ content }}</slot>
      </span>
    </div>
  </div>
</template>

<script>
import * as CustomMsgType from "@/views/live/constants/customMsgType";
import EXT_IM from "@/views/live/ext/im.js";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      content: null,
    };
  },
  props: {
    //接收父组件的数据
    endTime: { type: String, default: "" },
    endText: { type: String, default: "活动已结束" },
  },
  watch: {
    //监听时间的变化
    endTime() {
      this.countdowm(this.endTime);
    },
  },
  mounted() {
    this.countdowm(this.endTime);
  },
  methods: {
    countdowm(timestamp) {
      console.log(timestamp);
      timestamp = timestamp - 1;
      clearInterval(timer);
      let self = this;
      self.formatTime(timestamp);
      self.content = "倒计时: " + self.formatTime(timestamp);
      let timer = setInterval(function () {
        let nowTime = new Date();
        let endTime = new Date(timestamp * 1000);
        let t = endTime.getTime() - nowTime.getTime();
        if (t > 0) {
          let day = Math.floor(t / 86400000);
          let hour = Math.floor((t / 3600000) % 24);
          let min = Math.floor((t / 60000) % 60);
          let sec = Math.floor((t / 1000) % 60);
          hour = hour < 10 ? "0" + hour : hour;
          min = min < 10 ? "0" + min : min;
          sec = sec < 10 ? "0" + sec : sec;
          let format = "";
          if (day > 0) {
            format = `${day}:${hour}:${min}:${sec}`;
          }
          if (day <= 0 && hour > 0) {
            format = `${hour}:${min}:${sec}`;
          }
          if (day <= 0 && hour <= 0) {
            format = `${min}:${sec}`;
          }
          //format变成秒数
          let Sformat = day * 24 * 60 * 60 + hour * 60 * 60 + min * 60 + sec;
          self.setDownTime(Sformat);
          self.content = "倒计时: " + format;
        } else {
          clearInterval(timer);
          self.setDownTime(0);
          self.content = self.endText;
          self.$emit("countTime");
          // alert("倒计时结束啦~");
        }
      }, 1000);
    },
    formatTime(timestamp) {
      let nowTime = new Date();
      let endTime = new Date(timestamp * 1000);
      let t = endTime.getTime() - nowTime.getTime();
      let day = Math.floor(t / 86400000);
      let hour = Math.floor((t / 3600000) % 24);
      let min = Math.floor((t / 60000) % 60);
      let sec = Math.floor((t / 1000) % 60);
      hour = hour < 10 ? "0" + hour : hour;
      min = min < 10 ? "0" + min : min;
      sec = sec < 10 ? "0" + sec : sec;
      let format = "";
      if (day > 0) {
        format = `${day}:${hour}:${min}:${sec}`;
      }
      if (day <= 0 && hour > 0) {
        format = `${hour}:${min}:${sec}`;
      }
      if (day <= 0 && hour <= 0) {
        format = `${min}:${sec}`;
      }
      return format;
    },
    close(status) {
      let msg = {
        data: CustomMsgType.MsgStartOrEndCountDown(0),
      };
      EXT_IM.sendCustomMsg(msg);
      this.$emit("countTime");
    },
    ...mapActions(["setDownTime"]),
  },
};
</script>

<style lang='less' scoped>
.p_count_time {
  -webkit-user-select: none;
  position: absolute;
  width: 200px;
  margin-top: 0px;
  // height: 50px;
  // background: rgba(0, 0, 0, 0.3);
  border-radius: 4px 4px 4px 4px;
  .papa-icon-close {
    position: absolute;
    right: 18px;
    top: 19px;
    font-size: 12px;
    color: #fff;
    cursor: pointer;
  }
  .count_time {
    text-align: center;
    line-height: 50px;
    color: #ffffff;
    font-size: 16px;
    font-family: PingFang SC-Regular, PingFang SC;
    span {
      margin-left: 10px;
    }
  }
}
</style>