import * as CustomMsgType from "@/views/live/constants/customMsgType";
import { mapActions, mapState } from "vuex";
import { generateColumns, generateTips } from "@/utils/i18n";
import EXT_IM from "@/views/live/ext/im";
export default {
  data() {
    return {
      defaultAvatar: require("@/assets/img/avatar.png"),
    };
  },
  computed: {
    ...mapState({
      handUpList: (state) => state.live.handUpList,
      tic: (state) => state.live.tic,
    }),
  },
  methods: {
    generateColumns,
    generateTips,
    allowCall(user) {
      let self = this;
      let canConnectFlag = true;
      let connectUser = {};
      // 通知学生同意上麦请求
      this.handUpList.forEach((user) => {
        if (user.isConnected) {
          canConnectFlag = false;
          connectUser = user;
        }
      });
      if (canConnectFlag) {
        let allowMsg = {
          type: "c2c",
          targetId: user,
          data: CustomMsgType.MsgTeacherAllowConnect(user),
        };
        EXT_IM.sendCustomMsg(allowMsg).then(() => {
          self.setUserConnectStatus({ userID: user, isConnected: true });
          let msg = {
            type: "group",
            data: CustomMsgType.MsgConnectUser(),
          };
          EXT_IM.sendCustomMsg(msg).catch((e) => console.log(42, e));
        });
      } else {
        this.$confirm("每次只可有一位学生连麦，是否取消之前连麦", "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            self.endCall(connectUser.userID).then((res) => {
              let allowMsg = {
                type: "c2c",
                targetId: user,
                data: CustomMsgType.MsgTeacherAllowConnect(user),
              };
              EXT_IM.sendCustomMsg(allowMsg).then((res) => {
                self.setUserConnectStatus({ userID: user, isConnected: true });
                // 通知群组当前麦上学生
                let msgGroup = {
                  type: "group",
                  data: CustomMsgType.MsgConnectUser(),
                };
                EXT_IM.sendCustomMsg(msgGroup).then(
                  (res) => {
                    console.log(res);
                  },
                  (error) => {
                    console.log(error);
                  }
                );
              });
            });
          })
          .catch(() => {});
      }
    },
    endCall(user) {
      return new Promise((resolve, reject) => {
        let msg = {
          type: "c2c",
          targetId: user,
          data: CustomMsgType.MsgTeacherAllowConnect(user),
        };
        EXT_IM.sendCustomMsg(msg).then((res) => {
          console.log("===sendCustomGroupMsg:", res);
          this.setUserConnectStatus({ userID: user, isConnected: false });
          this.setUserHandStatus({ userID: user, handUp: false });
          let msgGroup = {
            type: "group",
            data: CustomMsgType.MsgHandUpUserList(),
          };
          EXT_IM.sendCustomMsg(msgGroup).then(
            (res) => {
              console.log(res);
              resolve();
            },
            (error) => {
              console.log(error);
              reject();
            }
          );
        });
      });
    },
    ...mapActions([
      "setUserConnectStatus",
      "setUserHandStatus",
      "setConnectList",
    ]),
  },
  watch: {},
};
