<template>
  <div></div>
</template>

<script>
import { isOnline, handleGetUserMediaError } from "./utils";
import TRTC from "trtc-js-sdk";
import { mapActions } from "vuex";
import { generateColumns, generateTips } from "@/utils/i18n";
const deviceFailAttention =
  "1. 若浏览器弹出提示，请选择“允许”<br>" +
  "2. 若杀毒软件弹出提示，请选择“允许”<br>" +
  "3. 检查系统设置，允许浏览器访问摄像头及麦克风<br>" +
  "4. 检查浏览器设置，允许网页访问摄像头及麦克风<br>" +
  "5. 检查摄像头/麦克风是否正确连接并开启<br>" +
  "6. 尝试重新连接摄像头/麦克风<br>" +
  "7. 尝试重启设备后重新检测";
const networkFailAttention =
  "1. 请检查设备是否联网<br>" +
  "2. 请刷新网页后再次检测<br>" +
  "3. 请尝试更换网络后再次检测";

export default {
  name: "deviceConnect",
  components: {},
  props: {
    stepNameList: {
      type: Array,
      default: () => [],
    },
    startDeviceDetect: Function,
  },
  data() {
    return {
      progress: 0,
      deviceState: {},
      connectResult: {},
      showConnectResult: false,
      progressInterval: null,
      showRemind: true,
    };
  },
  computed: {
    prepareInfo() {
      return (
        "设备检测前请确认设备连接了" +
        `${this.hasCameraDetect ? "摄像头" : ""}` +
        `${this.hasMicrophoneDetect ? "、麦克风" : ""}` +
        `${this.hasSpeakerDetect ? "、扬声器" : ""}` +
        `${this.hasNetworkDetect ? "和网络" : ""}`
      );
    },
    hasCameraDetect() {
      return this.stepNameList.indexOf("camera") >= 0;
    },
    hasMicrophoneDetect() {
      return this.stepNameList.indexOf("microphone") >= 0;
    },
    hasSpeakerDetect() {
      return this.stepNameList.indexOf("speaker") >= 0;
    },
    hasNetworkDetect() {
      return this.stepNameList.indexOf("network") >= 0;
    },
    showReconnectButton() {
      const { deviceState } = this;
      return (
        this.showConnectResult &&
        !(
          deviceState.hasCameraConnect &&
          deviceState.hasMicrophoneConnect &&
          deviceState.hasSpeakerConnect &&
          deviceState.hasNetworkConnect
        )
      );
    },
    showStartDetectButton() {
      const { deviceState } = this;
      return (
        this.showConnectResult &&
        deviceState.hasCameraConnect &&
        deviceState.hasMicrophoneConnect &&
        deviceState.hasSpeakerConnect &&
        deviceState.hasNetworkConnect
      );
    },
  },
  watch: {
    showConnectResult: {
      immediate: true,
      handler(val) {
        if (!val) {
          this.progressInterval = setInterval(() => {
            this.progress += 10;
            if (this.progress === 100) {
              clearInterval(this.progressInterval);
              this.showConnectResult = true;
            }
          }, 200);
        }
      },
    },
    deviceState: {
      immediate: true,
      deep: true,
      handler(val) {
        console.log(val);
        this.$store.commit("set_device_state", val);
      },
    },
    showConnectResult: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          this.$store.commit("set_show_connect_result", val);
        }
      },
    },
  },
  methods: {
    generateColumns,
    generateTips,
    setShowRemind(val) {
      this.showRemind = val;
    },
    handleReset() {
      this.progress = 0;
      this.connectResult = {};
      this.showConnectResult = false;
    },
    async getDeviceConnectResult() {
      let cameraList = [];
      let micList = [];
      let speakerList = [];
      try {
        cameraList = await TRTC.getCameras();
        micList = await TRTC.getMicrophones();
        speakerList = await TRTC.getSpeakers();
      } catch (error) {
        console.log("rtc-device-detector getDeviceList error", error);
      }
      console.log(micList);
      const hasCameraDevice = cameraList.length > 0;
      const hasMicrophoneDevice = micList.length > 0;
      const hasSpeakerDevice = this.hasSpeakerDetect
        ? speakerList.length > 0
        : true;
      const hasNetworkConnect = this.hasNetworkDetect ? await isOnline() : true;
      let deviceStateObj = {
        hasCameraDevice,
        hasMicrophoneDevice,
        hasSpeakerDevice,
        hasNetworkConnect,
        hasCameraConnect: false,
        hasMicrophoneConnect: false,
        hasSpeakerConnect: hasSpeakerDevice,
      };
      this.deviceState = deviceStateObj;
      this.connectResult = this.getDeviceConnectInfo(deviceStateObj);

      if (hasCameraDevice) {
        navigator.mediaDevices
          .getUserMedia({ video: true, audio: false })
          .then((stream) => {
            deviceStateObj = {
              ...deviceStateObj,
              hasCameraConnect: true,
            };
            this.deviceState = deviceStateObj;
            // 显示设备连接信息
            this.connectResult = this.getDeviceConnectInfo(deviceStateObj);
            // 释放摄像头设备
            stream.getTracks()[0].stop();
          })
          .catch((error) => {
            handleGetUserMediaError(error);
          });
      }

      if (hasMicrophoneDevice) {
        navigator.mediaDevices
          .getUserMedia({ video: false, audio: hasMicrophoneDevice })
          .then((stream) => {
            deviceStateObj = {
              ...deviceStateObj,
              hasMicrophoneConnect: hasMicrophoneDevice,
            };
            this.deviceState = deviceStateObj;

            // 显示设备连接信息
            this.connectResult = this.getDeviceConnectInfo(deviceStateObj);
            // 释放麦克风设备
            stream.getTracks()[0].stop();
          })
          .catch((error) => {
            handleGetUserMediaError(error);
          });
      }
    },
    getDeviceConnectInfo(deviceState) {
      console.log("deviceState", deviceState);
      let connectInfo = "连接出错，请重试";
      if (
        deviceState.hasCameraConnect &&
        deviceState.hasMicrophoneConnect &&
        deviceState.hasSpeakerConnect &&
        deviceState.hasNetworkConnect
      ) {
        connectInfo = this.hasNetworkDetect
          ? "设备及网络连接成功，请开始设备检测"
          : this.generateTips("device_connect_success");
        return {
          info: connectInfo,
          success: true,
        };
      }
      // 第一步：浏览器未检测到摄像头/麦克风/扬声器设备的提示
      if (
        !(
          deviceState.hasCameraDevice &&
          deviceState.hasMicrophoneDevice &&
          deviceState.hasSpeakerDevice
        )
      ) {
        connectInfo = `未检测到${
          deviceState.hasCameraDevice ? "" : "【摄像头】"
        }${deviceState.hasMicrophoneDevice ? "" : "【麦克风】"}${
          deviceState.hasSpeakerDevice ? "" : "【扬声器】"
        }设备，请检查设备连接`;
        console.log("connectInfo", connectInfo);
        return {
          info: connectInfo,
          success: false,
        };
      }
      // 第二步：浏览器未拿到摄像头/麦克风权限的提示
      if (!deviceState.hasCameraConnect) {
        connectInfo = deviceState.hasNetworkConnect
          ? "请允许浏览器及网页访问摄像头"
          : "请允许浏览器及网页访问摄像头，并检查网络连接";
      }
      if (!deviceState.hasMicrophoneConnect) {
        connectInfo = deviceState.hasNetworkConnect
          ? "请允许浏览器及网页访问麦克风设备"
          : "请允许浏览器及网页访问麦克风设备，并检查网络连接";
      }
      // 第三步：浏览器检测未连接网络的提示
      if (!deviceState.hasNetworkConnect) {
        connectInfo = "网络连接失败，请检查网络连接";
      }
      return {
        info: connectInfo,
        success: false,
      };
    },
  },
  mounted() {
    this.getDeviceConnectResult();
  },
  ...mapActions(["set_device_state", "set_show_connect_result"]),
};
</script>

<style lang='less' scoped>
</style>