<template>
  <div :class="routePath ? 'p_video' : ''">
    <div class="title" v-show="routePath">
      <div class="title_left">
        <span class="course"
          >{{ courseDesc.title }}
          <span class="in_class" v-show="classStatus === 1">{{
            generateColumns("in_class")
          }}</span>
        </span>
        <span class="time">{{ courseDesc.time }}</span>
      </div>
      <div class="title_right" v-show="classStatus === 1">
        <div class="upload">
          <div>{{ generateColumns("up") }}</div>
          <div class="qualityList">
            <template v-if="upQuality === 0">
              <div class="item item-one"></div>
              <div class="item item-two"></div>
              <div class="item item-three"></div>
              <div class="item item-four"></div>
              <div class="item item-five"></div>
            </template>
            <template v-else>
              <div
                class="item item-one"
                :class="upQuality < 5 ? 'item-highlight' : ''"
              ></div>
              <div
                class="item item-two"
                :class="upQuality < 4 ? 'item-highlight' : ''"
              ></div>
              <div
                class="item item-three"
                :class="upQuality < 3 ? 'item-highlight' : ''"
              ></div>
              <div
                class="item item-four"
                :class="upQuality < 2 ? 'item-highlight' : ''"
              ></div>
              <div
                class="item item-five"
                :class="upQuality <= 1 ? 'item-highlight' : ''"
              ></div>
            </template>
          </div>
        </div>
        <div class="download">
          <div>{{ generateColumns("down") }}</div>
          <div class="qualityList">
            <template v-if="downQuality === 0">
              <div class="item item-one"></div>
              <div class="item item-two"></div>
              <div class="item item-three"></div>
              <div class="item item-four"></div>
              <div class="item item-five"></div>
            </template>
            <template v-else>
              <div
                class="item item-one"
                :class="downQuality < 5 ? 'item-highlight' : ''"
              ></div>
              <div
                class="item item-two"
                :class="downQuality < 4 ? 'item-highlight' : ''"
              ></div>
              <div
                class="item item-three"
                :class="downQuality < 3 ? 'item-highlight' : ''"
              ></div>
              <div
                class="item item-four"
                :class="downQuality < 2 ? 'item-highlight' : ''"
              ></div>
              <div
                class="item item-five"
                :class="downQuality <= 1 ? 'item-highlight' : ''"
              ></div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div :class="routePath ? 'split_screen' : ''">
      <!-- <RTC :privateUser="privateUser"></RTC> -->
      <div :class="routePath ? '' : 'header'" v-show="!routePath">
        <div class="minimize_btn" @click="videoWindownBlowUp" v-if='isShowPackUpVideo'></div>
        <div class="shrinkup_btn" @click="videoWindownShrinkUp" v-if='!isShowPackUpVideo'></div>
        <div class="video_title">{{ generateColumns("video_window") }}</div>
      </div>
      <div
        :class="
          $route.path.includes('private/live')
            ? 'video-module'
            : $route.path.includes('spoken/live') && videoMode == 1
            ? 'video-module'
            : 'video_module_zhibo'
        "
      >
        <div
          :class="
            routePath
              ? 'left'
              : localBlowUp
              ? 'localBlowUp_spoken_right'
              : 'spoken_left'
          "
          @click="blowUp('spoken_left')"
        >
          <div id="teacher_video_wrapper" class="teacher_video_wrapper">
            <div
              class="local_video-default"
              :class="hasLocalVideo ? 'hidden' : ''"
            >
              <i
                :class="routePath ? 'iconfont papa-icon-video_off' : ''"
                v-if="$route.path.includes('private/live')"
              ></i>
              <div
                class="default_avatar"
                v-if="$route.path.includes('spoken/live') && !hasLocalVideo"
              >
                <img
                  class="default_avatar"
                  :src="userInfo.avatar"
                  alt=""
                  v-if="userInfo.avatar"
                />
                <img
                  class="default_avatar"
                  src="./defaultpic-L.png"
                  alt=""
                  v-else
                />
              </div>
            </div>
            <div class="local_video"></div>
          </div>
        </div>
        <div
          :class="
            routePath
              ? 'right'
              : remoteBlowUp
              ? 'spoken_right'
              : 'remoteBlowUp_spoken_left'
          "
          @click="blowUp('spoken_right')"
        >
          <div
            v-show="liveMode === Constant.LIVE_MODE.private"
            id="remote_video_wrapper"
            class="remote_video_wrapper"
            :style="[
              {
                background:
                  remoteVideoStream &&
                  remoteVideoStream.hasVideo() &&
                  $route.path.includes('private/live')
                    ? 'black'
                    : '',
              },
            ]"
          >
            <div>
              <i
                :class="routePath ? 'iconfont papa-icon-video_off' : ''"
                v-if="$route.path.includes('private/live')"
              ></i>
              <div
                class="default_avatar"
                v-show="$route.path.includes('spoken/live') && !remaoteVideo"
              >
                <img src="./defaultpic-L.png" alt="" class="default_avatar" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RTC from "@/views/live/modules/rtc/rtc.vue";
import { mapActions, mapState } from "vuex";
import { RESOLUTION } from "@/views/live/constants/constant";
import EXT_RTC from "@/views/live/ext/rtc";
import * as Constant from "@/views/live/constants/constant";
import { generateColumns, generateTips } from "@/utils/i18n";

export default {
  name: "",
  components: {},
  props: {
    privateUser: {
      type: Object,
      default: function () {
        return {};
      },
    },
    courseDesc: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      Constant,
      viewType: 1,
      RESOLUTION,
      resolutionBoxStatus: false,
      resolutionValue: "标清",
      hasLocalVideo: false,
      localBlowUp: false, //自己画面是否放大
      remoteBlowUp: true, //对方画面是否放大
    };
  },
  computed: {
    ...mapState({
      videoMode: (state) => state.footer.videoMode,
      classStatus: (state) => state.live.classStatus,
      liveMode: (state) => state.live.liveMode,
      trtcClient: (state) => state.rtc.trtcClient,
      localStream: (state) => state.rtc.localStream,
      remoteVideoStream: (state) => state.rtc.remoteVideoStream,
      downQuality: (state) => state.live.downloadQuality,
      upQuality: (state) => state.live.uploadQuality,
      cameraStatus: (state) => state.live.cameraStatus,
      remaoteVideo: (state) => state.live.remoteStreamVideo,
      userInfo: (state) => state.user.userInfo,
      isShowPackUpVideo: (state) => state.board.isPackUpVideo,
    }),
    routePath() {
      return this.$route.path.includes("spoken/live") && this.videoMode == 2;
    },
  },
  mounted() {
    if (this.remoteVideoStream) {
      if (this.classStatus !== 1) {
        this.remoteVideoStream.muteAudio();
      }
    }
  },
  methods: {
    generateColumns,
    generateTips,
    ...mapActions(["setIsPackUpVideo"]),
    videoWindownBlowUp() {
      this.setIsPackUpVideo(false);
    },
    videoWindownShrinkUp(){
      this.setIsPackUpVideo(true);
    },
    changeView() {
      if (this.liveMode === Constant.LIVE_MODE.private) {
        this.viewType = this.viewType === 1 ? 2 : 1;
      } else {
        return;
      }
    },
    changeResolution(type) {
      EXT_RTC.stopPush(this.trtcClient, this.localStream).then(() => {
        switch (type) {
          case "SD":
            this.resolutionValue = "标清";
            EXT_RTC.startRTC(true, false, RESOLUTION.SD);
            break;
          case "HD":
            this.resolutionValue = "高清";
            EXT_RTC.startRTC(true, false, RESOLUTION.HD);
            break;
          case "UHD":
            this.resolutionValue = "超清";
            EXT_RTC.startRTC(true, false, RESOLUTION.UHD);
            break;
          default:
            EXT_RTC.startRTC(true, false, RESOLUTION.SD);
            break;
        }
      });
    },
    showResolutionBox() {
      this.resolutionBoxStatus = !this.resolutionBoxStatus;
    },
    blowUp(type) {
      console.log(type);
      switch (type) {
        case "spoken_left": //自己画面是否放大
          this.remoteBlowUp = false;
          this.localBlowUp = true;
          break;
        case "spoken_right": //对方画面是否放大
          this.remoteBlowUp = true;
          this.localBlowUp = false;
          break;
        default:
          break;
      }
    },
  },
  watch: {
    cameraStatus(newVal) {
      console.log(newVal);
      if (newVal === 0) {
        this.hasLocalVideo = false;
      } else {
        this.hasLocalVideo = true;
      }
    },
  },

  components: {
    RTC,
  },
};
</script>

<style lang='less' scoped>
.title_right {
  z-index: 999;
  color: #fff;
  font-size: 14px;
  display: flex;

  .upload,
  .download {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  .qualityList {
    display: flex;
    align-items: baseline;
    margin-left: 6px;

    .item {
      background: #fff;
      margin-right: 2px;
      width: 2px;
    }

    .item-highlight {
      background: #29d087;
    }

    .item-one {
      height: 2px;
    }

    .item-two {
      height: 4px;
    }

    .item-three {
      height: 6px;
    }

    .item-four {
      height: 8px;
    }

    .item-five {
      height: 10px;
    }
  }
}
.p_video {
  background: #575757;
  padding: 20px 30px 30px 30px;
  .title {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    span {
      display: block;
    }
    .course {
      font-size: 16px;
      font-weight: 700;
      color: rgba(255, 255, 255, 0.9);
      margin-right: 5px;
      .in_class {
        display: inline-block;
        font-weight: 400;
        color: red;
        margin-left: 22px;
        position: relative;
        font-size: 12px;
        &::after {
          content: "";
          display: block;
          width: 5px;
          height: 5px;
          border-radius: 5px;
          background: red;
          position: absolute;
          top: 6px;
          left: -12px;
        }
      }
    }
    .time {
      margin-top: 5px;
      color: rgba(255, 255, 255, 0.7);
    }
  }
  .split_screen {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    // div {
    //   width: 32vw;
    //   height: 78vh;
    //   background: #333333;
    // }
  }
}
.video_module_zhibo {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  .left,
  .right {
    height: calc(100vh - 138px);
    background: #333333;
    aspect-ratio: 3/5;
    z-index: 998;
  }
  .left {
    .teacher_video_wrapper {
      width: 100%;
      height: 100%;
      position: relative;
      .local_video-default {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #424242;
        z-index: 9;
      }

      .papa-icon-video_off {
        position: absolute;
        top: 50%;
        margin-top: -30px;
        left: 50%;
        margin-left: -30px;
        font-size: 60px;
      }
      .default_avatar {
        z-index: 1;
        position: absolute;
        width: 120px;
        height: 120px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 8px;
        object-fit: cover;
      }
      .papa-icon-video_off {
        color: #b6b6b6;
      }

      .hidden {
        display: none;
      }

      .local_video {
        width: 100%;
        height: 100%;
        /deep/video {
          z-index: 9;
          object-fit: cover !important;
        }
      }
    }
  }

  .right {
    .remote_video_wrapper {
      width: 100%;
      height: 100%;
      background: #424242;
      // border-radius: 8px;
      position: relative;
      /deep/ .remote_video {
        // height: 100% !important;
        background: #424242 !important;
        width: 100% !important;
        height: 100%;
        position: relative;
        top: 50%;
        transform: translate(0, -50%);
        z-index: 9;
      }
      /deep/div {
        background-color: #424242 !important;
      }
      /deep/video {
        object-fit: cover !important;
      }
      .hidden {
        background-color: black !important;
      }
      .papa-icon-video_off {
        position: absolute;
        color: #b6b6b6;
        top: 50%;
        left: 50%;
        margin-top: -30px;
        margin-left: -30px;
        font-size: 60px;
      }
      .default_avatar {
        z-index: 10;
        position: absolute;
        width: 120px;
        height: 120px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 8px;
        object-fit: cover;
      }
    }
  }
}
.video-module {
  height: 500px !important;
  height: 300px !important;
  .spoken_left {
    width: 120px;
    height: 200px;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 9;
    .teacher_video_wrapper {
      .default_avatar {
        z-index: 3;
        position: absolute;
        width: 34px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        img {
          object-fit: cover;
          width: 34px;
          height: 34px;
          border-radius: 8px;
        }
      }
      width: 120px;
      height: 200px;
      .hidden {
        background-color: none !important;
      }
      /deep/ .local_video {
        width: 120px !important;
        height: 200px;
        // /deep/div {
        // }
      }
      /deep/div {
        // width: 120px;
        // height: 200px;
        background: #111111;
        border-radius: 8px;
        z-index: 2;
      }
      /deep/ video {
        z-index: 9;
        width: 120px;
        height: 200px;
        object-fit: cover !important;
        border-radius: 8px;
      }
    }
  }
  .spoken_right {
    width: 300px;
    height: 500px;

    .remote_video_wrapper {
      .default_avatar {
        z-index: 2;
        position: absolute;
        width: 54px;

        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        img {
          object-fit: cover;
          width: 54px;
          height: 54px;
          border-radius: 8px;
        }
      }
      width: 300px;
      height: 500px;
      background: #333333;
      border-radius: 8px;
      .hidden {
        background-color: none !important;
      }
      /deep/ .remote_video {
        width: 300px !important;
        height: 500px;
      }
      /deep/div {
        background-color: #333333 !important;
        border-radius: 0 0 8px 8px;
        z-index: 1;
      }
      /deep/ video {
        z-index: 9;
        width: 300px;
        height: 500px;
        object-fit: cover;
        border-radius: 8px;
      }
    }
  }
  .localBlowUp_spoken_right {
    width: 300px;
    height: 500px;
    .teacher_video_wrapper {
      width: 300px;
      height: 500px;
      .default_avatar {
        z-index: 2;
        position: absolute;
        width: 54px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        img {
          width: 54px;
          height: 54px;
          border-radius: 8px;
          object-fit: cover;
        }
      }
      .hidden {
        background-color: none !important;
      }
      /deep/ .local_video {
        width: 300px !important;
        border-radius: 8px;
        height: 500px;
      }
      /deep/div {
        // background-color: white !important;
        z-index: 1;
        background: #111111;

        border-radius: 0 0 8px 8px;
      }
      /deep/ video {
        z-index: 9;
        width: 300px !important;
        height: 500px !important;
        border-radius: 8px;
        object-fit: cover !important;
      }
    }
  }
  .remoteBlowUp_spoken_left {
    width: 120px;
    height: 200px;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 9;
    background: #333333 !important;
    border-radius: 8px;

    .remote_video_wrapper {
      .default_avatar {
        z-index: 10;
        position: absolute;
        width: 34px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        img {
          object-fit: cover;
          width: 34px;
          height: 34px;
          border-radius: 8px;
        }
      }
      width: 120px;
      height: 200px;
      .hidden {
        background-color: none !important;
      }
      /deep/ .remote_video {
        width: 120px !important;
        height: 200px;
      }
      /deep/div {
        border-radius: 8px !important;
        background-color: #333333 !important;
        z-index: 9;
      }
      /deep/ video {
        width: 120px;
        height: 200px;
        object-fit: cover !important;
        border-radius: 8px;
      }
    }
  }
}
.header {
  width: 100%;
  height: 32px;
  background-color: white;
  border-radius: 8px 8px 0px 0;
  display: flex;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
  position: relative;
  &::after {
    content: "";
    display: block;
    //画一个三角形
    width: 9px;
    height: 9px;
    background-image: radial-gradient(
      9px at top left,
      rgba(0, 0, 0, 0),
      9px,
      white
    );
    position: absolute;
    bottom: -9px;
    right: 0px;
    z-index: 9999;
    rotate: -90deg;
  }
  &::before {
    content: "";
    display: block;
    //画一个三角形
    width: 9px;
    height: 9px;
    background-image: radial-gradient(
      9px at top left,
      rgba(0, 0, 0, 0),
      9px,
      white
    );
    position: absolute;
    bottom: -9px;
    left: 0px;
    rotate: -180deg;
    z-index: 9999;
  }

  .shrinkup_btn{
    width: 16px;
    height: 3px;
    background: rgba(0, 0, 0, 0.9);
    cursor: pointer;
  }
  .minimize_btn {
    width: 16px;
    height: 3px;
    background: rgba(0, 0, 0, 0.9);
    cursor: pointer;
    position: relative;
    &::before {
      content: "";
      display: block;
      width: 16px;
      background: rgba(0, 0, 0, 0.9);
      height: 3px;
      position: absolute;
      top: 6%;
      rotate: 90deg;
    }
  }
  .video_title {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    margin-left: 17px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 3px;
      left: -8px;
      display: inline-block;
      width: 1px;
      height: 12px;
      background: #d9d9d9;
    }
  }
}
</style>
