var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:_vm.$route.path.includes('private/live')
      ? 'video-module'
      : _vm.$route.path.includes('spoken/live') && _vm.videoMode == 1
      ? 'video-module'
      : 'video_module_zhibo'},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"teacher_video_wrapper",attrs:{"id":"teacher_video_wrapper"}},[_c('div',{staticClass:"local_video-default",class:_vm.hasLocalVideo ? 'hidden' : ''},[_c('i',{staticClass:"iconfont papa-icon-video_off"})]),_c('div',{staticClass:"local_video"})])]),_c('div',{staticClass:"right"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.liveMode === _vm.Constant.LIVE_MODE.private),expression:"liveMode === Constant.LIVE_MODE.private"}],staticClass:"remote_video_wrapper",style:([
        {
          background:
            _vm.remoteVideoStream && _vm.remoteVideoStream.hasVideo() ? 'black' : '',
        },
      ]),attrs:{"id":"remote_video_wrapper"}},[_vm._m(0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('i',{staticClass:"iconfont papa-icon-video_off"})])
}]

export { render, staticRenderFns }