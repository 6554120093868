var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"devicetest"},[_c('i',{staticClass:"iconfont papa-icon-close",on:{"click":_vm.close}}),_c('div',{staticClass:"test"},[_vm._v(" "+_vm._s(_vm.generateColumns("device_test"))+" "),_c('i',{staticClass:"iconfont papa-icon-renovate",class:_vm.rotateRenovate ? 'renovate' : '',on:{"click":_vm.renovate}})]),_c('div',{staticClass:"facilitys"},[_c('div',{staticClass:"camera"},[_c('div',{staticStyle:{"height":"483px"}},[_c('div',{staticClass:"title_icon"},[(_vm.firstandmin)?_c('i',{staticClass:"iconfont papa-icon-loading3"}):_c('i',{staticClass:"iconfont",class:_vm.deviceState.hasCameraConnect && _vm.checkdevices.camera
                ? 'papa-icon-choose2'
                : 'papa-icon-fasongshibai'})]),_vm._m(0),_c('div',{staticClass:"title_icon"},[_vm._v(_vm._s(_vm.generateColumns("camera")))]),_c('div',[_c('el-select',{attrs:{"size":"small"},model:{value:(_vm.activeCameraDeviceId),callback:function ($$v) {_vm.activeCameraDeviceId=$$v},expression:"activeCameraDeviceId"}},_vm._l((_vm.cameraList),function(item,index){return _c('el-option',{key:index,attrs:{"value":item.deviceId,"label":item.label}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1),_c('div',{staticClass:"hint_title"},[_vm._v(_vm._s(_vm.generateTips("allow_camera")))]),(!_vm.firstandmin)?_c('div',{staticClass:"cameraDetector"},[_c('CameraDetector',{attrs:{"activeCameraDeviceId":_vm.activeCameraDeviceId}})],1):_vm._e()]),(!_vm.isShowCheckCamera)?_c('div',[(_vm.isShowCheck)?_c('devicecheck',{on:{"checkTest":function($event){return _vm.checkTest($event, 'camera')}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.generateTips("can_see_tips"))+" ")]},proxy:true}],null,false,73049861)}):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"microphone"},[_c('div',{staticStyle:{"height":"483px"}},[_c('div',{staticClass:"title_icon"},[(_vm.firstandmin)?_c('i',{staticClass:"iconfont papa-icon-loading3"}):_c('i',{staticClass:"iconfont",class:_vm.deviceState.hasMicrophoneConnect && _vm.checkdevices.microphone
                ? 'papa-icon-choose2'
                : 'papa-icon-fasongshibai'})]),_vm._m(1),_c('div',{staticClass:"title_icon"},[_vm._v(_vm._s(_vm.generateColumns("microphone")))]),_c('div',[_c('el-select',{attrs:{"size":"small"},model:{value:(_vm.activeMicDeviceId),callback:function ($$v) {_vm.activeMicDeviceId=$$v},expression:"activeMicDeviceId"}},_vm._l((_vm.micDeviceList),function(item,index){return _c('el-option',{key:index,attrs:{"value":item.deviceId,"label":item.label}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1),_c('div',{staticClass:"hint_title"},[_vm._v(" "+_vm._s(_vm.generateTips("microphone_tips"))+" ")]),_c('div',[(!_vm.firstandmin)?_c('MicDetector',{attrs:{"activeMicDeviceId":_vm.activeMicDeviceId}}):_vm._e()],1)]),(!_vm.isShowCheckMicrophone)?_c('div',[(_vm.isShowCheck)?_c('devicecheck',{on:{"checkTest":function($event){return _vm.checkTest($event, 'microphone')}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.generateTips("can_see_volume_tips")))]},proxy:true}],null,false,764697017)}):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"trumpet"},[_c('div',{staticStyle:{"height":"483px"}},[_c('div',{staticClass:"title_icon"},[(_vm.firstandmin)?_c('i',{staticClass:"iconfont papa-icon-loading3"}):_c('i',{staticClass:"iconfont",class:_vm.deviceState.hasSpeakerConnect && _vm.checkdevices.trumpet
                ? 'papa-icon-choose2'
                : 'papa-icon-fasongshibai'})]),_vm._m(2),_c('div',{staticClass:"title_icon"},[_vm._v(_vm._s(_vm.generateColumns("peaker")))]),_c('div',[_c('el-select',{attrs:{"size":"small"},model:{value:(_vm.activeSpeakerDeviceId),callback:function ($$v) {_vm.activeSpeakerDeviceId=$$v},expression:"activeSpeakerDeviceId"}},_vm._l((_vm.speakerList),function(item,index){return _c('el-option',{key:index,attrs:{"value":item.deviceId,"label":item.label}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1),_c('div',{staticClass:"hint_title"},[_vm._v(_vm._s(_vm.generateTips("speaker_tips")))]),_c('div',[(!_vm.firstandmin)?_c('SpeakerDetector',{attrs:{"activeSpeakerDeviceId":_vm.activeSpeakerDeviceId}}):_vm._e()],1)]),(!_vm.isShowCheckTrumpet)?_c('div',[_vm._v("· "),(_vm.isShowCheck)?_c('devicecheck',{on:{"checkTest":function($event){return _vm.checkTest($event, 'trumpet')}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.generateTips("can_hear_tips")))]},proxy:true},{key:"can_yes",fn:function(){return [_vm._v("听得到")]},proxy:true},{key:"can_no",fn:function(){return [_vm._v("听不到")]},proxy:true}],null,false,3472210940)}):_vm._e()],1):_vm._e()])]),(!_vm.isShowCheck)?_c('div',{staticClass:"device_hint"},[_vm._v(_vm._s(_vm.hinttext))]):_vm._e(),(!_vm.isShowCheck || _vm.isShowfinishbtn == 3)?_c('button',{class:_vm.firstandmin ? 'btn_no_click' : 'btn',on:{"click":function($event){return _vm.handleTest(_vm.btnText)}}},[_vm._v(" "+_vm._s(_vm.btnText)+" ")]):_vm._e(),(!_vm.firstandmin)?_c('deviceConnect'):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"iconfont papa-icon-video"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"iconfont papa-icon-microphone"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"iconfont papa-icon-volume"})])
}]

export { render, staticRenderFns }