import { MsgBoard } from "@/views/live/constants/customMsgType";
import { Message } from "element-ui";
import store from "@/store";
import * as Api from "@/api/live/live";
import EXT_IM from "./im";
/* eslint-disable */
export default {
  // 创建白板实例
  getBoardInstance(config) {
    return new Promise((resolve, reject) => {
      console.log(config);
      resolve(new TEduBoard(config));
    });
  },

  // 添加到白板
  addToBoard(teduBoard, res) {
    let fid = teduBoard.addTranscodeFile({
      url: res.result_url,
      title: res.title,
      pages: res.pages,
      resolution: res.resolution,
    });
    var fileInfo = teduBoard.getFileInfo(fid);
    store.dispatch("setCurrentFile", fileInfo);
  },

  /**
   * 白板事件回调处理
   * @param {*} data
   */
  proBoardData(teduBoard, fid, res) {
    // this.fileInfoList = teduBoard.getFileInfoList()
    // store.dispatch('setFileList', this.fileInfoList)
    this.currentFileId = fid;
    var fileInfo = teduBoard.getFileInfo(this.currentFileId);
    if (fileInfo) {
      if (fileInfo.fid !== "#DEFAULT") {
        console.log(fileInfo);
        // sessionStorage.currentFile = JSON.stringify(fileInfo)
        store.dispatch("setIsShowCourseWare", true);
      }
    }
  },

  // 上传文件
  uploadFile(teduBoard, file, type) {
    console.log(file, type);
    var params = new FormData();
    if (type === "file") {
      params.append("file", file);
      console.log(193);
      Api.teacherDocument("post", params, () => {
        console.log("上传课件成功~");
      });
    } else {
      params.append("file", file);
      Api.teacherAudio("post", params, () => {
        console.log("上传音频成功~");
      });
    }
  },

  // 切换课件
  onSwitchFile(teduBoard, file) {
    teduBoard.switchFile(file.fid);
  },

  // 切换课件页面
  onGotoBoard(teduBoard, boardId) {
    teduBoard.gotoBoard(boardId, false);
  },

  getImages(teduBoard, fid) {
    return teduBoard.getThumbnailImages(fid);
  },

  getCurrentBoardId(teduBoard) {
    return teduBoard.getCurrentBoard();
  },
  getSize(teduBoard) {},

  /*
   * scale Number
   * */
  getBoardScale(teduBoard) {
    return new Promise((resolve, reject) => {
      let scale = teduBoard.getBoardScale();
      console.log(scale);
      resolve(scale);
    });
  },

  setBoardScale(teduBoard, scale) {
    if (scale < 100) {
      Message.error("已经最小了~");
    } else {
      teduBoard.setBoardScale(scale);
    }
  },

  // 设置画笔粗细
  setPenSize(teduBoard, size) {
    switch (size) {
      case "lighter":
        teduBoard.setBrushThin(30);
        break;
      case "bold":
        teduBoard.setBrushThin(60);
        break;
      case "bolder":
        teduBoard.setBrushThin(90);
        break;
      default:
        teduBoard.setBrushThin(40);
        break;
    }
  },

  // 重置白板
  resetBoard(teduBoard) {
    teduBoard.reset();
  },
};
