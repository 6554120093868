import EXT_BOARD from "@/views/live/ext/board";
import { mapActions, mapState } from "vuex";
import { generateColumns, generateTips } from "@/utils/i18n";
import * as Api from "@/api/live/live";
import store from "@/store";

export default {
  data() {
    return {};
  },
  props: {
    isShowPackUp: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      teduBoard: (state) => state.board.teduBoard,
      currentFile: (state) => state.board.currentFile,
      currentBoardId: (state) => state.board.currentBoardId,
    }),
  },
  mounted(){
    if(this.$refs.manageFile.innerHTML.length>5){
      this.$refs.manageFile.style.bottom = '84px';
    }
  },
  methods: {
    generateColumns,
    setCurrentPage(boardId) {
      this.setCurrentBoardId(boardId);
      EXT_BOARD.onGotoBoard(this.teduBoard, boardId);
    },

    showFileBox() {
      Api.teacherDocument("get", "", (res, pagination) => {
        this.setFileList(res);
        this.setFilePagination(pagination);
      });
      this.$emit("showFileList", "file", "选择课件");
    },

    /**
     * 上传文件
     */
    uploadFile() {
      var file = document.getElementById("file_input").files[0];
      EXT_BOARD.uploadFile(this.teduBoard, file);
    },

    // 删除文件

    deleteFile(id) {
      this.teduBoard.deleteFile({
        fileId: id,
      });
    },
    ...mapActions([
      "setCurrentBoardId",
      "setFileBoxStatus",
      "setFileList",
      "setFilePagination",
    ]),
  },
};
