<template>
  <div class="mask-box">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'maskCon'
  }
</script>

<style lang="less">
  .mask-box{
    // width: 100vw;
    width: 100%;
    height: calc(100vh - 38px - 96px );
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: calc(30px);

    background: rgba(0,0,0,.3);
    z-index: 9;
  }

</style>
