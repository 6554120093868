/* eslint-disable */
import TRTC from "trtc-js-sdk";

export default {
  createRTCClient(config) {
    return TRTC.createClient(config);
  },
  // 启动推流(推摄像头)
  startRTC(video, audio, resolution = "720p") {
    let cameraStream = this.createRTCStream(video, audio);
    // 设置视频分辨率等参数
    cameraStream.setVideoProfile(resolution);
    console.log("cameraStream", cameraStream);
    return new Promise((resolve, reject) => {
      this.publishLocalStream(cameraStream)
        .then(() => {
          resolve(cameraStream);
        })
        .catch(() => {
          console.log("推流失败");
          reject();
        });
    });
  },

  createRTCStream(userId, video, audio, screen) {
    return TRTC.createStream({
      userId: userId,
      audio: Boolean(audio),
      video: Boolean(video),
      screen: screen,
    });
  },

  stopPush(client, stream) {
    return client.unpublish(stream);
  },

  // 获取当前网络传输状况

  getTransportStats() {
    window.trtcClient.getTransportStats().then((stats) => {
      console.log("RTT: " + stats.rtt);
    });
  },

  getLocalAudioStats() {
    window.trtcClient.getLocalAudioStats().then((stats) => {
      for (let userId in stats) {
        console.log(
          "userId: " +
            userId +
            " bytesSent: " +
            stats[userId].bytesSent +
            " packetsSent: " +
            stats[userId].packetsSent
        );
      }
    });
  },

  //  获取媒体输入输出设备列表
  getDevices() {
    let list = [];
    return TRTC.getMicrophones()
      .then((res) => {
        list = res;
        return list;
      })
      .catch((e) => {
        console.log(e);
      });
    return list;
  },

  // 获取 输出设备列表
  getSpeakers() {
    let list = [];
    return TRTC.getSpeakers()
      .then((res) => {
        list = res;
        return list;
      })
      .catch((e) => {
        console.log(e);
      });
    return list;
  },
  //获取摄像头列表
  getCameras() {
    let list = [];
    return TRTC.getCameras()
      .then((res) => {
        list = res;
        return list;
      })
      .catch((e) => {
        console.log(e);
      });
    return list;
  },
};
