<script>
  import CameraDetector from '@/views/live/modules/devicetest/components/cameraDetector.vue'

  export default {
    name: 'openCamera',
    data(){
      return{
        isChoiceAlwaysOpen: false
      }
    },

    components: {
      CameraDetector
    },
    methods: {
      handleClose () {
        this.$emit('closeDialog')
      },
      handleBeginClass(){
        this.$emit('beginClass')
      },
      handleIsAlways(){
        this.isChoiceAlwaysOpen = !this.isChoiceAlwaysOpen
        localStorage.isChoiceAlwaysOpen = this.isChoiceAlwaysOpen
      }
    }
  }
</script>

<template>
  <div class="open-camera">
    <div class="container">
      <div class="header">
        <div class="title">视频预览</div>
        <div class="close-btn" @click="handleClose">
          <i class="iconfont papa-icon-close"></i>
        </div>
      </div>
      <div class="body">
        <div class="camera">
          <camera-detector></camera-detector>
        </div>
        <div class="tips">摄像头已自动开启， 点击继续将开始上课~</div>
        <div class="begin-btn" @click="handleBeginClass">继续</div>
        <div class="open-tips" @click="handleIsAlways">
          <img v-if="!isChoiceAlwaysOpen" src="../../../assets/img/select_default.png" alt="">
          <img v-else src="../../../assets/img/select_active.png" alt="">
          上课后自动开启摄像头，不再提示视频预览页面
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
  .open-camera {
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;

    .container {
      box-sizing: border-box;
      padding: 30px;
      width: 54vw;
      //height:51vh;
      background: #fff;
      border-radius: 12px;
      position: relative;

      .header {
        width: 100%;
        height: 50px;
        border-bottom: 1px solid #ccc;
        font-weight: bold;

        .title {
          font-size: 23px;
          color: rgba(0, 0, 0, 0.9);
        }

        .iconfont {
          position: absolute;
          right: 25px;
          top: 25px;
          font-size: 12px;
          cursor: pointer;
        }
      }

      .body {
        box-sizing: border-box;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .camera {
          width: 18vw;
          height: 30vw;
          background: #444;
          overflow: hidden;

         /deep/.camera-video{
           width: 100%;
           height: 100%;
           div{
              display: flex;
              align-items: center;
             justify-content: center;
              video{
                width: unset!important;
              }
            }
          }
        }

        .tips {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.9);
          margin: 20px 0;
        }

        .begin-btn {
          width: 112px;
          height: 48px;
          background: linear-gradient(136deg, #21DC7A 0%, #17D6A4 100%);
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          line-height: 48px;
          text-align: center;
          color: #fff;
          margin-bottom: 10px;
          cursor: pointer;
        }

        .open-tips {
          color: rgba(0, 0, 0, 0.7);
          font-size: 14px;
          display: flex;
          align-items: center;
          cursor: pointer;
        }
      }
    }
  }
</style>
