<template>
  <div>
    <papa-dialog
      :dialogVisible="true"
      :title="generateColumns('feedback')"
      @dialogClose="closeDialog"
    >
      <div slot="dialog" v-if="true">
        <edit
          :id="lineId"
          @closeDialog="closeDialog"
          :category="currentCategory"
          :end-time="currentEndTime"
          :status="currentStatus"
          :class_type="currentClassType"
        ></edit>
      </div>
    </papa-dialog>
  </div>
</template>

<script>
import { generateColumns } from "@/utils/i18n";
import edit from "@/views/trainingSpeaking/appointments/edit.vue";
export default {
  name: "",
  components: { edit },
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    generateColumns,
    closeDialog() {
      this.$emit("closeDialog");
    },
  },
};
</script>

<style lang='less' scoped>
.spoken {
  width: 50%;
}
</style>