<template>
  <div class="audioBox"
       id="audioBox"
       :style="{left: left, top: top}">
    <div class="audioName"       v-drag
    >  {{currentAudio.audio_name }}</div>
    <div class="audioBar"
    >
      <div class="playAndPause" @click="handleSetBgAudioStatus">
      <span class="pause" v-if="isPlayBg" >
        <i class="iconfont papa-icon-audio_pause"></i>
      </span>
        <span class="play" v-else >
                <i class="iconfont papa-icon-audio_play"></i>

      </span>
      </div>
      <div class="audioProgress">
        <div class="currentTime">
          {{ currentTime }}
        </div>
        <el-slider v-model="audioProgress" style="width: 210px"
                   :format-tooltip="formatToolTip"
                   :debounce="300"
                   @mousedown.native="isChange = true"
                   @mouseup.native="isChange = false"
                   @change="handleDragAudioBar"></el-slider>
        <div class="totalTime">
          {{endTime }}
        </div>
      </div>
      <div class="speed" @click="handleChangeSpeed">
        <i v-if="currentSpeed === 0.5" class="iconfont papa-icon-a-audio_speed05"></i>
        <i v-if="currentSpeed === 0.75" class="iconfont papa-icon-a-audio_speed075"></i>
        <i v-if="currentSpeed === 1" class="iconfont papa-icon-a-audio_speed10"></i>
        <i v-if="currentSpeed === 1.25" class="iconfont papa-icon-a-audio_speed125"></i>
        <i v-if="currentSpeed === 1.5" class="iconfont papa-icon-a-audio_speed15"></i>
        <i v-if="currentSpeed === 2" class="iconfont papa-icon-a-audio_speed20"></i>
      </div>
      <div class="close" @click="handleCloseBar">关闭</div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import AudioMixerPlugin from 'rtc-audio-mixer'
  import moment from 'moment';
  import momentDurationFormatSetup from 'moment-duration-format';

  momentDurationFormatSetup(moment);


  export default {
    name: 'audioBar',
    data(){
      return{
        audioProgress: 0,
        isPlayBg: false,
        bgAudioElement: null,
        audioSourceA: null,
        duration: 0,
        currentTime: '00:00',
        endTime: '00:00',
        currentSpeed: 1,
        speedIndex: 2,
        speedList: [0.5,0.75,1,1.25,1.5,2],
        isChange: false,
        left: '10px' ,
        top: '700px',
        flag: false
      }
    },
    computed: {
      ...mapState({
        mainStream: state => state.rtc.localStream,
        currentAudio: state => state.board.currentAudio
      })
    },
    methods: {
      handleSetBgAudioStatus(){
        this.isPlayBg = !this.isPlayBg
        if(this.isPlayBg){
          this.bgAudioElement.play();
        }else{
          this.bgAudioElement.pause();
        }
      },
      handleCloseBar(){
        this.audioSourceA.stop()
        this.setAudioBarStatus(false)
      },
      createTrack(){
        this.bgAudioElement = document.createElement('audio');
        this.bgAudioElement.src = this.currentAudio.audio_url;
        this.bgAudioElement.load();
        let audioSourceA = AudioMixerPlugin.createAudioSourceFromElement(this.bgAudioElement);
        this.audioSourceA = audioSourceA
        this.setBgAudioStream(audioSourceA)
        let self = this
        audioSourceA.on('canplay', function (  ) {
          const duration = audioSourceA.duration()
          self.duration = duration
          self.endTime = moment.duration(duration, 'seconds').format('mm:ss', {trim: false})
        })
        audioSourceA.on('timeupdate', function ( e ) {
          const currentTime = audioSourceA.getPosition()
          self.currentTime = moment.duration(currentTime, 'seconds').format('mm:ss', {trim: false})
          if(self.isChange) return
          self.audioProgress = currentTime*100000000 / (self.duration * 1000000)
          if(currentTime === self.duration){
            self.audioProgress =0
            self.currentTime = '00:00'
            self.isPlayBg = false
          }
        })
        let originAudioTrack = this.mainStream.getAudioTrack();
        let mixedAudioTrack = AudioMixerPlugin.mix({targetTrack: originAudioTrack, sourceList: [audioSourceA]});
        this.mainStream.replaceTrack(mixedAudioTrack);

      },
      handleChangeSpeed(){
        let speedLength = this.speedList.length
        let index = this.speedIndex
        if(this.speedIndex >=speedLength - 1){
          index = 0
        }else{
          index++
        }
        this.speedIndex = index
        this.currentSpeed = this.speedList[index]
        this.audioSourceA.setPlayBackRate(this.currentSpeed)
        // this.bgAudioElement.setPlayBackRate(this.currentSpeed)
      },
      replaceTrack(url){
        this.bgAudioElement.src = url
        this.bgAudioElement.load();
        this.isPlayBg = false
        this.speedIndex = 2
        this.currentSpeed = 1
      },
      handleDragAudioBar(e){
        this.audioSourceA.setPosition(this.duration*e / 100)
        this.isChange = false
      },
      formatToolTip(e){
        return moment.duration(e*this.duration / 100, 'seconds').format('mm:ss', {trim: false})
      },
      ...mapActions(["setAudioBarStatus", "setBgAudioStream"])
    },
    directives: {
      drag ( el ) {
        const target = document.querySelector('#audioBox')
        const oDiv = el
        document.onselectstart = () => {
          return false
        }
        oDiv.onmousedown = e => {
          const disX = e.clientX - target.offsetLeft
          const disY = e.clientY - target.offsetTop
          document.onmousemove = e => {
            e.preventDefault()
            const l = e.clientX - disX
            const t = e.clientY - disY
            target.style.left = l + 'px'
            target.style.top = t + 'px'
          }
          document.onmouseup = e => {
            document.onmousemove = null
            document.onmouseup = null
          }
          return false
        }
      }
    },
    mounted () {
      this.createTrack()
    },
    watch:{
      currentAudio:{
        deep: true,
        handler: function ( newVal ) {
          this.replaceTrack(newVal.audio_url)
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  .audioBox{
    padding: 13px 15px;
    position: absolute;
    bottom: 60px;
    left: 10px;
    width: 430px;
    height: 50px;
    background: #fff;
    border-radius: 2px;
    z-index: 999;
    box-shadow: 0px 4px 16px 0px rgba(131,131,131,0.08);
    .audioName{
      font-size: 12px;
      color: #333;
      text-align: left;
      cursor: move;
    }
    .audioBar{
      z-index: 999;
      box-sizing: border-box;
      display: flex;

      align-items: center;
      user-select: none;
      i{
        color: #29d087;
        font-size: 24px;
      }
      .playAndPause{
        width: 16px ;
        cursor: pointer;
        margin-right: 18px;
      }
      .audioProgress{
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #666666;
        .currentTime{
          width: 3em;
        }
        .el-slider{
          margin: 0 20px;
        }
        /deep/ .el-slider__button{
          width: 11px;
          height: 11px;
          background: #29d087;
        }

      }
      .speed{
        width: 54px;
        height: 24px;
        text-align: center;
        cursor: pointer;
      }
      .close{
        color: #666666;
        font-size: 12px;
        cursor: pointer;
      }
    }

  }
</style>
