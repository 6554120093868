import EXT_IM from "@/views/live/ext/im";
import EXT_RTC from "@/views/live/ext/rtc";
import * as CustomMsgType from "@/views/live/constants/customMsgType";
import * as Config from "@/config/live/live";
import { mapActions, mapState } from "vuex";
import { MsgOpenShare } from "@/views/live/constants/customMsgType";
import * as Constant from "@/views/live/constants/constant";
import { Message, MessageBox } from "element-ui";
import * as Api from "@/api/live/live";

export const rtcMixin = {
  data() {},
  computed: {
    ...mapState({
      userId: state => state.live.userId,
      userToken: state => state.live.userToken,
      videoMode: state => state.footer.videoMode
    })
  },
  methods: {
    startRTC() {
      const hasVideo = this.cameraStatus;
      console.log(this.userId, this.cameraStatus);
      const mainStream = EXT_RTC.createRTCStream(
        this.userId,
        Boolean(hasVideo),
        true
      );
      mainStream.setAudioProfile("high");
      mainStream
        .initialize()
        .catch(error => {
          switch (error.name) {
            case "NotReadableError":
              Message.error(
                "暂时无法访问摄像头/麦克风，请确保当前没有其他应用请求访问摄像头/麦克风，并重试。"
              );
              break;
            case "NotAllowedError":
              Message.error(
                "当前摄像头/麦克风未授权访问， 无法进行音视频通话,请修改浏览器设置，并重试。"
              );
              break;
            case "NotFoundError":
              Message.error("没找到可用的摄像头/麦克风。");
              break;
            default:
              Message.error("由于某些未知原因导致设备无法被使用。");
              break;
          }
          console.error("failed initialize localStream " + error, error.name);
        })
        .then(() => {
          this.setLocalStream(mainStream);
          console.log("localStream initialize success", mainStream);
          return this.mainClient.publish(mainStream);
        })
        .then(() => {
          let mainStreamId = mainStream.getId();
          let mainStreamWrapEl = document.getElementsByClassName(
            "local_video"
          )[0];
          mainStreamWrapEl.innerHTML = "";
          mainStreamWrapEl.id = mainStreamId;
          document
            .querySelector("#teacher_video_wrapper")
            .insertBefore(mainStreamWrapEl, null);
          mainStream.play(mainStreamWrapEl, {
            objectFit: "contain",
            mute: true
          });
          if (this.micStatus === 2) {
            this.$refs.footerRefs.toggleMic(2);
          }
          if (!this.voiceStatus) {
            this.setVolumeValue(0);
          }
        });
    },

    startShareScreen() {
      const shareClientConfig = {
        mode: "rtc",
        // sdkAppId: Config.sdkAppId,
        sdkAppId: JSON.parse(localStorage.getItem("SDKAppId")),

        userId: this.shareUser.identifier,
        userSig: this.shareUser.sign,
        autoSubscribe: false
      };
      const shareClient = EXT_RTC.createRTCClient(shareClientConfig);
      const shareStream = EXT_RTC.createRTCStream(
        shareClientConfig.userId,
        false,
        false,
        true
      );
      shareStream.setScreenProfile({width: 1080, height: 720, frameRate: 5, bitrate: 1200});
      // shareStream.setScreenProfile("720p");
      shareStream
        .initialize()
        .then(async () => {
          console.log(1);
          let params;
          if (this.$route.path.includes("spoken/live")) {
            params = {
              speaking_appointment_id: this.$route.params.id,
              record_mode: 4
            };
          } else {
            params = {
              course_plan_task_id: this.$route.params.id,
              record_mode: 4
            };
          }
          let _this = this
          await Api.changeMode(
            this.$route.params.id,
            this.$route.path.includes("spoken/live")
              ? Constant.ENTERANCE.speaking
              : Constant.ENTERANCE.course,
            params,()=>{
                console.log(_this.roomId)
                shareClient.join({ roomId: parseInt(_this.roomId) }).then(()=>{
                  return shareClient.publish(shareStream);
                })
              }, ()=>{
              throw Error('123')
              }
          );
        })




        .then(async () => {
          let shareStreamId = shareStream.getId();
          let shareVideoWrapEl = document.getElementsByClassName(
            "share_video"
          )[0];
          shareVideoWrapEl.id = shareStreamId;
          document
            .querySelector("#share_video_wrap")
            .insertBefore(shareVideoWrapEl, null);
          shareStream.play(shareVideoWrapEl, {
            objectFit: "contain",
            muted: true
          });
          let msg = {
            type: "group",
            data: MsgOpenShare()
          };
          EXT_IM.sendCustomMsg(msg);
          this.setShareClient(shareClient);
          this.setShareStream(shareStream);
          this.setShareStatus(1);
          this.setVideoMode(1); //切换白板模式
          this.shareScreenSendMsg();
          console.log("开启了屏幕分享");
        });
    },
    shareScreenSendMsg() {
      if (this.$route.path.includes("spoken/live")) {
        let msg = {
          data: CustomMsgType.MsgLiveMode(
            this.beforeShareVideoModeStatus == 2 ? "board" : "board"
          )
        };
        EXT_IM.sendCustomMsg(msg);
      }
    },
    stopShareScreen() {},

    initRTCEvent() {
      let mainClient = this.mainClient;
      mainClient.on("stream-added", event => {
        const remoteStream = event.stream;
        const remoteUserId = remoteStream.getUserId();
        console.log(remoteUserId);
        if (remoteUserId.indexOf("desktop_share_record_user") < 0) {
          mainClient.subscribe(remoteStream);
        } else {
          mainClient.unsubscribe(remoteStream);
        }
      });

      // 监听‘stream-removed’事件
      mainClient.on("stream-removed", event => {
        console.log("stream-removed", event);
        const remoteStream = event.stream;
        let streamUserId = remoteStream.getUserId();
        if (streamUserId.indexOf("desktop_share_record_user") > -1) {
          return;
        }
        let userId = this.remoteVideoStream.getUserId();
        console.log(streamUserId, userId);
        if (streamUserId == userId) {
          this.setRemoteVideoStream(null);
        }
        console.log(
          "remoteStream ID: " + remoteStream.getId() + " has been removed"
        );
        // 停止播放并删除相应<video>标签
        remoteStream.stop();
        document.getElementById(remoteStream.getId()).remove();
      });

      // 监听‘stream-updated’事件
      mainClient.on("stream-updated", event => {
        const remoteStream = event.stream;
        console.log(
          "remoteStream ID: " +
            remoteStream.getId() +
            " was updated hasAudio: " +
            remoteStream.hasAudio() +
            " hasVideo: " +
            remoteStream.hasVideo()
        );
        //区分是屏幕分享还是视频流
        let streamUserId = remoteStream.getUserId();
        console.log(remoteStream.hasVideo());
        if (streamUserId.indexOf("tiw_record_user") > -1) return;
        if (streamUserId.indexOf("desktop_share_record_user") > -1) return;
        this.setRemoteStreamVideo(remoteStream.hasVideo());
      });

      // 监听‘stream-subscribed’事件
      mainClient.on("stream-subscribed", event => {
        const remoteStream = event.stream;
        console.log(remoteStream);
        const remoteUserId = remoteStream.getUserId();
        console.log(remoteUserId, "videoIdvideoId");
        let videoId = remoteStream.getId();
        console.log(videoId, "videoIdvideoId");

        // 远端流订阅成功
        // 播放该远端流
        if (remoteUserId.indexOf("desktop_share_record_user") < 0) {
          // remoteUserId 包含 tiw_record_user_1773230434 时为白板
          if (remoteUserId.indexOf("tiw_record_user") < 0) {
            let remoteVideoWrapEl = document.getElementsByClassName(
              "remote_video"
            )[0];
            if (!remoteVideoWrapEl) {
              remoteVideoWrapEl = document.createElement("div");
              remoteVideoWrapEl.className = "remote_video";
            } else {
            }
            remoteVideoWrapEl.id = videoId;
            document
              .querySelector("#remote_video_wrapper")
              .insertBefore(remoteVideoWrapEl, null);
            remoteStream.muteAudio();
            remoteStream.play(remoteVideoWrapEl).catch(e => {
              console.log("自动播放音视频流失败：", e);
              const eCode = e.getCode();
              if (eCode === 16451) {
                remoteStream.resume();
              }
            });
            let obj = document.querySelector("#remote_video_wrapper audio");
            obj.volume = 1;
            this.setRemoteVideoStream(remoteStream);
            this.setVoiceStatus(0);
            remoteStream.muteAudio();
          }
        }
      });

      mainClient.on("connection-state-changed", event => {
        console.log(event);
        const prevState = event.prevState;
        if (prevState === "CONNECTED" && this.classStatus !== 2) {
          MessageBox.alert("网络异常，请检查网络状态。", "提示", {
            confirmButtonText: "重试",
            callback: () => {
              location.reload();
            }
          });
        }
      });

      mainClient.on("peer-join", event => {
        if (event.userId.includes('tiw_record_user')) return
        console.log("peer-join", event);
        // if (event.userId.includes('tiw_record_user')) return
        // 如果不是老师自己加入房间，也不是录制的流，就提示有人加入房间
        this.setStudentInClassStatus(true)
      });

      mainClient.on("peer-leave", event => {
        if (event.userId.includes('tiw_record_user')) return
        console.log("peer-leave", event);
        this.setStudentInClassStatus(false)
        // if (sessionStorage.privateUser) {
        //   let privateUser = JSON.parse(sessionStorage.privateUser)
        //   if (sessionStorage.classStatus === '1') {
        //     if (privateUser.uuid === event.userId) {
        //       Message.error(`${privateUser.cn_name}退出房间`)
        //       store.dispatch('addMsg', {
        //         send: 'system',
        //         content: privateUser.cn_name + '退出房间',
        //         type: 'notify',
        //         avatar: privateUser.avatar,
        //         identity: '',
        //         status: status
        //       })
        //     }
        //   }
        // }
        if (event.userId.indexOf("desktop_share_record_user") < 0) {
          this.setUserConnectStatus({
            userID: event.userId,
            isConnected: false
          });
        }
      });

      mainClient.on("mute-audio", event => {
        console.log("mute-audio", event);
        const userId = event.userId;
        // Message.info(`${userId}关闭了麦克风`)
      });

      mainClient.on("mute-video", event => {
        console.log("mute-video", event);
        const userId = event.userId;
        // Message.info(`${userId}关闭了摄像头`)
      });

      mainClient.on("unmute-audio", event => {
        console.log("unmute-audio", event);
        const userId = event.userId;
        // Message.info(`${userId}打开了麦克风`)
      });

      mainClient.on("unmute-video", event => {
        console.log("unmute-video", event);
        const userId = event.userId;
        // Message.info(`${userId}打开了摄像头`)
      });

      mainClient.on("error", error => {
        console.error("client error observed: " + error);
        const errorCode = error.getCode();
        // 根据ErrorCode列表查看详细错误原因。
      });

      mainClient.on("client-banned", error => {
        console.error("client-banned observed: " + error);
      });

      mainClient.on("network-quality", event => {
        console.log(event)
        this.setUploadQuality(event.uplinkNetworkQuality);
        if(this.studentOnlineStatus){
          this.setDownloadQuality(event.downlinkNetworkQuality);
        }else{
          this.setDownloadQuality(0);
        }
      });
    },
    removeEvent() {
      const mainClient = this.mainClient;
      mainClient.off("*");
    },

    createMainClient(clientConfig) {
      console.log("createMainClient", clientConfig);
      let routeMode = this.$route.path.includes("spoken/live");
      let config = {
        mode: "rtc",
        // useStringRoomId: routeMode ? true : false,
        ...clientConfig
      };
      return EXT_RTC.createRTCClient(config);
    },
    ...mapActions([
      "setShareStream",
      "setShareClient",
      "setShareStatus",
      "setVideoMode",
        "setStudentInClassStatus"
    ])
  }
};
