<template>
  <div class="spoken_color_list">
    <div
      class="color-item"
      v-for="color in colorList"
      :key="color"
      :style="{ background: color }"
      :class="currentToolColor === color ? 'active' : ''"
      @click.stop="setColor(color)"
    ></div>
    <div class="arrow"></div>
  </div>
</template>

<script>
export default {
  name: "colorList",
  data() {
    return {
      colorList: [
        "#000",
        "#FF4040",
        "#F1A325",
        "#38B03F",
        "#2A70FF",
        "#FF3DE1",
        "#FFFFFF",
      ],
    };
  },
  computed: {
    routeIsSpoken() {
      return !this.$route.path.includes("spoken");
    },
  },
  methods: {
    setColor(color) {
      this.$emit("setColor", color);
    },
  },
  props: {
    currentToolColor: {
      type: String,
      default: "#FF4040",
    },
  },
};
</script>

<style lang="less" scoped>
.spoken_color_list {
  width: 40px;
  height: 236px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.3);
  align-items: center;
  justify-content: space-around;
  position: absolute;
  top: 20px;
  left: 68px;
  & > .color-item {
    border-radius: 3px;
    width: 15px;
    height: 15px;
  }
  & > .arrow {
    position: absolute;
    left: -10px;
    top: 20px;
    width: 0;
    height: 0;
    border-width: 5px;
    transform: rotate(90deg);
    border-style: solid;

    border-color: rgba(0, 0, 0, 0.3) transparent transparent transparent;
  }
  & > .active {
    border-radius: 3px;
    border: 1px solid #29d087;
    //缩小
  }
}
.color-list {
  width: 160px;
  height: 30px;
  display: flex;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: space-around;
  position: absolute;
  top: -50px;
  left: 0;
  padding: 0 8px;
  & > .color-item {
    width: 15px;
    height: 15px;
  }
  & > .arrow {
    position: absolute;
    left: 12px;
    bottom: -10px;
    width: 0;
    height: 0;
    border-width: 5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.5) transparent transparent transparent;
  }
  & > .active {
    border: 1px solid #fff;
  }
}
</style>
