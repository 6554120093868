<template>
  <div class="footer-module">
    <div class="left">
      <div class="logo">
        <img src="./img/logo.png" alt="" />
        <span>v1.3.11</span>
      </div>
      <div class="tools">
        <div class="voice">
          <div class="tip_box" v-show="voiceStatus === 0">
            <div class="tip_con">
              <div class="con">
                <span class="con_text"
                  >{{ generateColumns("click_here") }}<br />{{
                    generateColumns("open")
                  }}<br />{{ generateColumns("sound") }}</span
                >
              </div>
            </div>
            <div class="tip_point"></div>
          </div>
          <i
            v-if="voiceStatus === 1"
            class="iconfont papa-icon-volume"
            @click="toggleVoice(0)"
          ></i>
          <i
            v-else-if="voiceStatus === 0"
            class="iconfont papa-icon-volume_off"
            @click="toggleVoice(1)"
          ></i>
          <el-slider
            style="width: 105px; margin-left: 10px"
            v-model="voiceNumber"
            :min="0"
            :max="100"
            @change="setVoiceNumber"
          ></el-slider>
        </div>
        <div class="mic">
          <i
            v-if="micStatus === 1"
            class="iconfont papa-icon-microphone"
            @click="toggleMic(2)"
          ></i>
          <i
            v-else-if="micStatus === 2"
            class="iconfont papa-icon-microphone_off"
            @click="toggleMic(1)"
          ></i>
          <i v-else class="iconfont papa-icon-microphone_off"></i>
        </div>
        <div class="video">
          <div v-show="isVideoCall">
            <i
              v-if="cameraStatus === 1"
              class="iconfont papa-icon-video"
              @click="toggleCamera(0)"
            ></i>
            <i
              v-else-if="cameraStatus === 0"
              class="iconfont papa-icon-video_off"
              @click="toggleCamera(1)"
            ></i>
            <i v-else class="iconfont papa-icon-video_off"></i>
          </div>
        </div>
        <div :class="windowWidth ? 'setting_screen' : 'setting'">
          <el-popover
            popper-class="devicesPopover"
            placement="top"
            trigger="click"
            v-model="isShowSettingPanel"
          >
            <el-form>
              <el-form-item :label="generateColumns('camera')">
                <el-select v-model="activeCameraDeviceId">
                  <el-option
                    v-for="item in cameraList"
                    :key="item.deviceId"
                    :label="item.label"
                    :value="item.deviceId"
                  >
                    {{ item.label }}
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="generateColumns('peaker')">
                <el-select v-model="activeSpeakerDeviceId">
                  <el-option
                    v-for="item in speakerList"
                    :key="item.deviceId"
                    :label="item.label"
                    :value="item.deviceId"
                  >
                    {{ item.label }}
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="generateColumns('microphone')">
                <el-select v-model="activeMicDeviceId">
                  <el-option
                    v-for="item in deviceList"
                    :key="item.deviceId"
                    :label="item.label"
                    :value="item.deviceId"
                  >
                    {{ item.label }}
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item align="right">
                <el-button type="primary" size="mini" @click="setDevice">{{
                  generateColumns("affirm")
                }}</el-button>
                <el-button size="mini" @click="isShowSettingPanel = false">{{
                  generateColumns("close")
                }}</el-button>
              </el-form-item>
            </el-form>
            <i slot="reference" class="iconfont papa-icon-setup">{{
              generateColumns("setting")
            }}</i>
          </el-popover>
        </div>
        <div class="begin">
          <i
            v-if="classStatus !== 1"
            class="iconfont papa-icon-coures"
            @click="beginClass"
            >{{ generateColumns("start_class") }}</i
          >
          <i v-else class="iconfont papa-icon-coures" @click="endClass('endClass')">{{
            generateColumns("end_class")
          }}</i>
        </div>
        <div class="play">
          <i class="iconfont papa-icon-cd_icon" @click="showAudioBox">{{
            generateColumns("audio")
          }}</i>
        </div>
        <div
          v-if="shareStatus === 0 && isSupportShare"
          class="share"
          :class="classStatus !== Constant.CLASS_STATUS.STARTED?'share-disabled':''"
          @click="shareScreen"
        >
          <i class="iconfont papa-icon-share">{{
            generateColumns("screen_share")
          }}</i>
        </div>

        <div v-if="shareStatus === 1" class="exitShare">
          <div class="tip_box" v-if="shareStatus === 1 && isShowMicTip">
            <div class="tip_con">
              <div class="con">
                <div
                  class="con_close iconfont icon-papa-closewhite"
                  @click="closeTip"
                >
                  x
                </div>
                <span class="con_text">退出屏幕分享<br />请点击这里</span>
              </div>
            </div>
            <div class="tip_point"></div>
          </div>
          <i class="iconfont papa-icon-share_quit" @click="stopPushScreen('stopShare')">{{
            generateColumns("stop_share")
          }}</i>
        </div>
        <div>
          <i
            class="iconfont papa-icon-ppt_display"
            v-if="
              !isShowCourseware && currentFile && currentFile.fid !== '#DEFAULT'
            "
            @click="showFile"
            >{{generateColumns("show_courseware")}}</i
          >
          <i
            class="iconfont papa-icon-ppt_hide"
            v-else-if="
              isShowCourseware && currentFile && currentFile.fid !== '#DEFAULT'
            "
            @click="hideFile"
            >{{generateColumns("hide_courseware")}}</i
          >
        </div>
        <div class="buttons" v-if="$route.path.includes('spoken/live')">
          <div
            v-for="(button, index) in buttons"
            :key="button.key"
            class="btn"
            @click="activeBoradOrVideo(button)"
          >
            <el-dropdown trigger="click" v-if="index == 0">
              <span class="el-dropdown-link"
                ><i class="iconfont" :class="button.icon"> </i>
                {{ button.text }}
              </span>
              <el-dropdown-menu slot="dropdown">
                <div @click="ActiveMode(1)">
                  <el-dropdown-item
                    :class="videoMode == 1 ? 'active_mode' : 'none_hover'"
                    >{{ generateColumns("whiteboard_mode") }}</el-dropdown-item
                  >
                </div>
                <div @click="ActiveMode(2)">
                  <el-dropdown-item
                    :class="videoMode == 2 ? 'active_mode' : 'none_hover'"
                    >{{ generateColumns("video_mode") }}</el-dropdown-item
                  >
                </div>
              </el-dropdown-menu>
            </el-dropdown>
            <span v-else @click="addTimeAndfeed(button)">
              <i class="iconfont" :class="button.icon"> </i>
              {{ button.text }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="audioBox"></div>
    <div class="exit">
      <i class="iconfont papa-icon-index_dropout" @click="exitRoom('exitRoom')">{{
        generateColumns("exit_classroom")
      }}</i>
    </div>
  </div>
</template>

<script>
import footerJs from "./footer.js";
import { CLASS_STATUS } from '@/views/live/constants/constant'

export default footerJs;
</script>

<style lang="less" scoped>
.active_mode {
  color: #29d087;
  &:hover {
    background: none;
  }
}
.none_hover {
  &:hover {
    background: none;
    color: #333;
  }
}

.footer-module {
  width: 100%;
  height: 100%;
  color: #bec7d0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 20px;

  & > .left {
    display: flex;
    align-items: center;

    & > .logo {
      width: 146px;
      display: flex;
      align-items: center;

      & > img {
        width: 75%;
      }

      & > span {
        font-size: 12px;
        margin-left: 5px;
      }
    }

    & > .tools {
      margin-left: 56px;
      display: flex;
      align-items: center;
      font-size: 14px;
      .buttons {
        margin-left: 30px;
        .active {
          color: #29d087;
        }
        display: flex;
        cursor: pointer;
        i {
          margin: 4px 0 0 0;
        }
        i:before {
          margin-right: 5px;
        }
        .btn {
          margin-right: 40px;
          display: flex;
        }
        .el-dropdown-link {
          color: #bec7d0;
        }
      }
      & > div {
        cursor: pointer;
      }

      i:before {
        margin-right: 5px;
      }

      i {
        font-size: 14px;
      }

      & > .mic,
      .voice {
        margin-right: 20px;
        color: #29d087;
        display: flex;
        align-items: center;

        i:before {
          font-size: 18px;
        }
      }

      .mic {
        position: relative;
      }

      & > .voice {
        width: 120px;
        position: relative;
      }

      .tip_box {
        position: absolute;
        top: -132px;
        left: 14px;
        z-index: 999;

        .tip_con {
          width: 121px;
          height: 116px;
          border-radius: 50%;
          background: @papaGreen;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;

          .con {
            position: relative;
            width: 100%;
            text-align: center;

            .con_close {
              position: absolute;
              right: -5px;
              top: 0;
              color: #888888;
              opacity: 0.7;
              background: #fff;
              border-radius: 50%;
              width: 15px;
              height: 15px;
            }
          }
        }

        .tip_point {
          width: 14px;
          height: 12px;
          border-radius: 50%;
          background: @papaGreen;
        }
      }

      & > .video {
        color: #29d087;
        margin-right: 20px;
        display: flex;
        align-items: center;
        position: relative;

        i:before {
          font-size: 22px;
        }
      }

      & > .setting {
        margin-right: 150px;
      }
      & > .setting_screen {
        margin-right: 50px;
      }
      & > .begin {
        width: 80px;
      }

      .play {
        margin-right: 20px;
      }

      & > .changeShare,
      .exitShare,
      .share {
        width: 110px;
        margin-right: 20px;
      }
      .share-disabled{
        cursor: not-allowed;
        opacity: 0.5;
      }

      .exitShare {
        position: relative;
      }

      .tip_box {
        position: absolute;
        top: -132px;
        left: 14px;

        .tip_con {
          width: 121px;
          height: 116px;
          border-radius: 50%;
          background: @papaGreen;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;

          .con {
            position: relative;
            width: 100%;
            text-align: center;

            .con_close {
              text-align: center;
              line-height: 15px;
              color: #333;
              position: absolute;
              right: -5px;
              top: 0;
              color: #888888;
              opacity: 0.7;
              background: #fff;
              border-radius: 50%;
              width: 15px;
              height: 15px;
            }
          }
        }

        .tip_point {
          width: 14px;
          height: 12px;
          border-radius: 50%;
          background: @papaGreen;
        }
      }
    }
  }

  .audioBox {
    z-index: -1;
  }

  & > .exit {
    cursor: pointer;
    color: #29d087;
  }

  i {
    font-size: 14px;
  }

  .voice-bar {
    width: 105px;
    height: 12px;
    margin-left: 16px;
    position: relative;
    cursor: pointer;

    .line {
      width: 100%;
      height: 2px;
      margin: 5px 0;
      background: #fff;

      .with-color {
        height: 2px;
        width: 50px;
        background: #29d087;
      }
    }

    .ball {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #29d087;
      position: absolute;
      top: 1px;
      left: 50px;
    }
  }

  .disAbleBtn {
    cursor: not-allowed;
  }

  .tip_box {
    position: absolute;
    top: -132px;
    left: 14px;

    .tip_con {
      width: 121px;
      height: 116px;
      border-radius: 50%;
      background: @papaGreen;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;

      .con {
        position: relative;
        width: 100%;
        text-align: center;

        .con_close {
          position: absolute;
          right: -5px;
          top: 0;
          color: #888888;
          opacity: 0.7;
          background: #fff;
          border-radius: 50%;
        }
      }
    }

    .tip_point {
      width: 14px;
      height: 12px;
      border-radius: 50%;
      background: @papaGreen;
    }
  }
}
</style>
