import ColorList from "./colorList/colorList.vue";
import { mapActions, mapState } from "vuex";
import MaskCon from "@/components/maskCon/maskCon.vue";
import EXT_BOARD from "@/views/live/ext/board";
import Util from "@/utils/utils";
import AudioBar from "@/views/live/modules/board/audioBar/audioBar";
import { generateColumns, generateTips } from "@/utils/i18n";

export default {
  data() {
    return {
      currentToolType: 1,
      isShowTools: false,
      isShowPageBtn: false,
      brushColor: "#FF4040",
      textColor: "#FF4040",
      currentTime: "",
      localBeginTime: "",
      interValObj: null,
      tt: "",
      isShowClassOverTip: false,
      loading: false,
      isBenginClassDisabled:false
    };
  },
  mounted() {
    let date = new Date();
    this.localBeginTime = Math.ceil(date.getTime() / 1000);
    this.getCurrentTime();
  },
  props: {
    courseDesc: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    routeIsSpoken() {
      return !this.$route.path.includes("spoken");
    },
    flagClickBegin() {
      console.log(this.classStatus);
      if (this.classStatus !== 1) {
        return parseInt(sessionStorage.classStatus) !== 1 && this.canCLickBegin
          ? true
          : false;
      } else {
        return false;
      }
    },
    timeDiffer() {
      return this.localBeginTime - this.courseDesc.serverTime;
    },

    ...mapState({
      videoWrap: (state) => state.live.videoWrap,
      teduBoard: (state) => state.board.teduBoard,
      shareStatus: (state) => state.live.shareStatus,
      currentFile: (state) => state.board.currentFile,
      classStatus: (state) => state.live.classStatus,
      upQuality: (state) => state.live.uploadQuality,
      downQuality: (state) => state.live.downloadQuality,
      isShowAudioBar: (state) => state.board.isShowAudioBar,
      isShowPackUpVideo: (state) => state.board.isPackUpVideo,
      videoMode: (state) => state.footer.videoMode,
      isShowPackUp: (state) => state.live.isShowPackUp,
      packupvideo: (state) => state.board.isPackUpVideo,
    }),
  },

  methods: {
    generateColumns,
    generateTips,
    ...mapActions(["set_is_pack_up_video", "setIsPackUpVideo"]),
    handelPackUpVideo() {
      this.$store.commit("set_is_pack_up_video", false);
    },
    beginClass() {
      fundebug.notify("上课", {
        page: "board",
      });
      this.isBenginClassDisabled = true
      setTimeout(() => {
        this.isBenginClassDisabled = false
      }, 2000);
      this.$emit("beginClass");
    },
    getCurrentTime() {
      this.interValObj = setInterval(() => {
        let date = new Date();
        let now = date.getTime() / 1000 + this.timeDiffer;
        this.tt = Util.formatMoment(now, "HH:mm:ss");
      }, 1000);
    },
    onSetToolType(toolType, lineType) {
      if (
        this.currentToolType === lineType ||
        this.currentToolType === toolType
      ) {
        this.currentToolType = "";
        this.teduBoard.setToolType(0);
      } else {
        this.currentToolType = lineType || toolType;
        this.teduBoard.setToolType(toolType);
      }
    },

    //
    onSetLineToolType(lineStyle) {
      this.teduBoard.setLineStyle({
        endArrowType: lineStyle,
      });
      if (lineStyle === 1) {
        this.onSetToolType(4, 4);
      } else {
        this.onSetToolType(4, 10);
      }
    },
    zoomBoard(num) {
      EXT_BOARD.getBoardScale(this.teduBoard).then((res) => {
        EXT_BOARD.setBoardScale(this.teduBoard, parseInt(res) + num);
        this.onSetTextSize(parseInt(res));
      });
    },
    // 回退
    onUndo() {
      this.teduBoard.undo();
    },
    /**
     * 清空当前页涂鸦(保留背景色/图片)
     */
    onclearDraws() {
      this.teduBoard.clear();
    },

    /**
     * 设置涂鸦颜色
     * @param {*} color Hex 色值，如 #ff00ff
     */
    onSetBrushColor(color) {
      this.brushColor = this.rgbToHex(color);
      this.teduBoard.setBrushColor(this.brushColor);
    },

    onGetBrushColor(color) {
      this.brushColor = this.getBrushColor();
    },

    /**
     * 设置字体颜色
     * @param {*} color Hex 色值，如 #ff00ff
     */
    onSetTextColor(color) {
      this.textColor = this.rgbToHex(color);
      this.teduBoard.setTextColor(this.textColor);
    },
    onGetTextSize() {},
    onSetTextSize(scale) {
      this.teduBoard.setTextSize(400 / (scale / 100));
    },
    rgbToHex(color) {
      var arr = [],
        strHex;
      if (/^(rgb|RGB)/.test(color)) {
        arr = color.replace(/(?:\(|\)|rgb|RGB)*/g, "").split(",");
        strHex =
          "#" +
          ((1 << 24) + (arr[0] << 16) + (arr[1] << 8) + parseInt(arr[2]))
            .toString(16)
            .substr(1);
      } else {
        strHex = color;
      }
      return strHex;
    },
    moveTools(ev, ee) {},
    start(ev) {
      let obDom = ev.target;
      let disY = ev.clientY - obDom.offsetTop;
      document.onmousemove = (e) => {
        let target = document.querySelector(".pen-tools");
        let top = e.clientY - disY;
        this.positionX = top;
        target.style.bottom = -top + "px";
      };
      document.onmouseup = () => {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },
    closeToolBar() {
      this.isShowTools = false;
    },
    openToolBar() {
      this.isShowTools = true;
    },
    changeCourseware(type) {
      if (type === "-1") {
        if (this.currentFile.currentPageIndex > 0) {
          this.teduBoard.prevBoard();
        } else {
          this.$message.warning(this.generateTips("first_page"));
        }
      } else {
        let index = this.currentFile.currentPageIndex + 1;
        if (index > this.currentFile.pageCount - 1) {
          this.$message.warning(this.generateTips("last_page"));
        } else {
          this.teduBoard.nextBoard();
        }
      }
      let id = EXT_BOARD.getCurrentBoardId(this.teduBoard);
      this.setCurrentBoardId(id);
    },
    mouseOverBoard() {
      this.isShowPageBtn = false;
    },
    mouseLeaveBoard() {
      this.isShowPageBtn = true;
    },
    openPackupVideo() {
      this.setIsPackUpVideo(false);
    },
    ...mapActions(["setCurrentBoardId", "setCanBeginClass"]),
  },
  components: {
    ColorList,
    MaskCon,
    AudioBar,
  },
  beforeDestroy() {
    if (this.interValObj) {
    }
    clearInterval(this.interValObj);
  },
  watch: {
    classStatus(newVal, oldVal) {
      if (newVal === 2) {
        this.canCLickBegin = true;
      }
    },
    tt(newVal, odlVal) {
      if (newVal) {
        if (!this.isShowClassOverTip) {
          let date = new Date();
          let currentTime = date.getTime() / 1000;
          let endTime = parseInt(this.courseDesc.endTime) + 3000;
          if (currentTime > endTime) {
            this.isShowClassOverTip = true;
            this.$alert("教室将在10分钟后强制关闭", "提示", {
              confirmButtonText: "确定",
              showClose: false,
              callback: (action) => {
                // this.openPage(url)
              },
            });
          }
        }
      }
    },
  },
};
