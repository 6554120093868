<template>
  <div class="check">
    <div class="title">
      <slot name="title">{{ generateTips("can_see_tips") }}</slot>
    </div>
    <div class="btns">
      <button @click="checkTest(true)">
        <slot name='can_yes'>
        {{ generateColumns("can_see") }}
        </slot>
      </button>
      <button @click="checkTest(false)">
        <slot name='can_no'>
         {{ generateColumns("can_not_see") }}
        </slot>
      </button>
    </div>
  </div>
</template>

<script>
import { generateColumns, generateTips } from "@/utils/i18n";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      msg: "Welcome to Your Vue.js App",
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    generateColumns,
    generateTips,
    checkTest(isOk) {
      this.$emit("checkTest", isOk);
    },
  },
};
</script>

<style lang='less' scoped>
.title {
  text-align: center;
  color: rgba(0, 0, 0, 0.9);
  font-size: 14px;
}
.btns {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  button:nth-child(1) {
    width: 91px;
    height: 28px;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #29d087;
    border-radius: 5px;
    background: #fff;
    color: #29d087;
    font-size: 12px;
    cursor: pointer;
  }
  button:nth-child(2) {
    width: 91px;
    height: 28px;
    border: 1px solid #29d087;
    border-radius: 4px 4px 4px 4px;
    background: linear-gradient(136deg, #21dc7a 0%, #17d6a4 100%);
    color: rgba(255, 255, 255, 0.9);
    font-size: 12px;
    cursor: pointer;
  }
}
</style>