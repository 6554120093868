<template>
  <div>
    <div class="title">{{generateColumns("input_volume")}}</div>
    <div>
      <el-slider v-model="volume" @input="dragVolume('input_level')"></el-slider>
    </div>
    <div class="title">{{generateColumns("input_volume")}}</div>
    <div class="mic-bar-container">
      <div
        v-for="(item, index) in new Array(20).fill('')"
        :key="index"
        :class="[`mic-bar ${volumeNum > index && 'active'}`]"
      ></div>
    </div>
    <div id="audio-container"></div>
  </div>
</template>

<script>
import TRTC from "trtc-js-sdk";
import { generateColumns } from "@/utils/i18n";

export default {
  name: "",
  components: {},
  props: {
    activeMicDeviceId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      localStream: null,
      volumeNum: 0,
      timer: null,
      volume: 50,
    };
  },
  computed: {},
  watch: {
    activeMicDeviceId: {
      handler(newVal) {
        this.handleMicrophoneChange(newVal);
      },
      deep: true,
      immediate: true,
    },
  },
  created() {},
  mounted() {},
  methods: {
    generateColumns,
    // 初始化音频流
    async initStream(microphoneID) {
      console.log("microphoneID", microphoneID);
      this.localStream = TRTC.createStream({
        video: false,
        audio: true,
        microphoneId: microphoneID,
      });
      await this.localStream.initialize();
      this.localStream.play("audio-container");
      this.timer = setInterval(() => {
        const volume = this.localStream.getAudioLevel();
        this.volumeNum = Math.ceil(10 * volume);
      }, 100);
    },
    // 切换麦克风
    handleMicrophoneChange(deviceId) {
      if (this.localStream) {
        this.localStream.switchDevice("audio", deviceId);
      } else {
        this.initStream(deviceId);
      }
    },
    dragVolume() {
      console.log("this.volume", this.localStream);
      if (this.localStream) {
        this.localStream.setAudioCaptureVolume(this.volume);
      }
    },
    //调整麦克风音量
    // handleVolumeChange(volume) {
    //   this.localStream.setAudioVolume(volume);
    // },
  },
};
</script>

<style lang='less' scoped>
.title {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.9);
}
/deep/.el-slider__runway {
  height: 4px;
}
/deep/.el-slider__bar {
  height: 4px;
}
/deep/.el-slider__button {
  background-color: #29d087;
  width: 8px;
  height: 8px;
}
/deep/.el-slider__button-wrapper {
  top: -16px;
}
.mic-bar-container {
  -webkit-user-select: none;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  .mic-bar {
    width: 2px;
    height: 20px;
    background-color: #f5f5f5;
  }
  .mic-bar:not(:first-child) {
    margin-left: 8px;
  }
  .mic-bar.active {
    background: #29d087;
  }
}
</style>