import TIM from 'tim-js-sdk'
import { mapActions, mapState } from 'vuex'
import EXT_IM from '@/views/live/ext/im'
import * as CustomMsgType from '@/views/live/constants/customMsgType'
import { MessageBox } from 'element-ui'

export const imMixin = {
  data () {
    return {}
  },
  computed: {
    ...mapState({
      userId: ( state ) => state.live.userId,
      classStatus: ( state ) => state.live.classStatus,
      videoMode: ( state ) => state.footer.videoMode,
      downtime: ( state ) => state.live.setdownTime,
      isOnline: ( state ) => state.im.isOnline,
    }),
  },
  methods: {
    /************ IM 事件监听*********************/
    imEventListener ( tim ) {
      // 登录成功后会触发 SDK_READY 事件，该事件触发后，可正常使用 SDK 接口
      tim.on(TIM.EVENT.SDK_READY, this.imReadyHandler, this)
      // SDK NOT READT
      tim.on(TIM.EVENT.SDK_NOT_READY, this.imReadyHandler)
      // 被踢出
      tim.on(TIM.EVENT.KICKED_OUT, this.onKickOut)
      // SDK内部出错
      tim.on(TIM.EVENT.ERROR, this.onError)
      // 收到新消息
      tim.on(TIM.EVENT.MESSAGE_RECEIVED, this.onReceiveMessage)
      // 会话列表更新
      tim.on(
          TIM.EVENT.CONVERSATION_LIST_UPDATED,
          this.onUpdateConversationList
      )
      // 群组列表更新
      tim.on(TIM.EVENT.GROUP_LIST_UPDATED, this.onUpdateGroupList)
      // 网络监测
      tim.on(TIM.EVENT.NET_STATE_CHANGE, this.onNetStateChange)
      // 已读回执
      tim.on(TIM.EVENT.MESSAGE_READ_BY_PEER, this.onMessageReadByPeer)
    },

    onReadyStateUpdate ( { name } ) {
      const isSDKReady = name === TIM.EVENT.SDK_READY ? true : false
      return isSDKReady
    },
    onReceiveMessage ( { data: messageList } ) {
      messageList.forEach(( msg ) => {
        // 会话类型
        let conversationType = msg.conversationType
        switch (conversationType) {
          case TIM.TYPES.CONV_C2C:
            this.handleC2CMsg(msg)
            break
          case TIM.TYPES.CONV_GROUP:
            if (this.roomId !== msg.to) return
            this.handleGroupMsg(msg)
            break
          case TIM.TYPES.CONV_SYSTEM:
            if (this.roomId !== msg.to) return
            this.handleSystemMsg(msg)
        }
        let payload = msg.payload
        if (payload && payload.operationType == 1) {
          this.setUserJionTim(1)
        }
        if (payload && payload.operationType == 2) {
          this.setUserJionTim(0)
        }
      })
    },
    onKickOut ( event ) {
      console.log('被提出房间', event)
      window.close()
    },
    onUpdateGroupList () {
    },
    onNetStateChange () {
    },
    onUpdateConversationList () {
    },
    onMessageReadByPeer () {
    },
    onError () {
    },
    handleGroupMsg ( msg ) {
      let msgType = msg.type
      switch (msgType) {
        case TIM.TYPES.MSG_TEXT:
          EXT_IM.handleTextMsg(msg).then(( msgCon ) => {
            this.handleMsgToBox(msg, msgCon, 'chat')
          })
          break
        case TIM.TYPES.MSG_GRP_TIP:
          this.handleGroupTipMsg(msg)
          break
        case TIM.TYPES.MSG_CUSTOM:
          this.handleCustomMsg(msg)
      }
    },
    handleSystemMsg ( msg ) {
      let msgType = msg.payload.operationType
      switch (msgType) {
        case 1: // 用户申请加群
          console.log('用户加群')
          break
        case 4: // 用户被踢出群  处理为下课
          if (msg.payload.groupProfile.groupID === this.roomId) {
            this.handleClassOver()
          }
          break
        default:
          console.log('系统通知，类型：', msgType)
      }
    },

    handleC2CMsg ( msg ) {
      let msgType = msg.type
      switch (msgType) {
        case TIM.TYPES.MSG_TEXT:
          EXT_IM.handleTextMsg(msg).then(( msgCon ) => {
            this.handleMsgToBox(msg, msgCon)
          })
          break
        case TIM.TYPES.MSG_CUSTOM:
          this.handleCustomMsg(msg)
      }
    },
    // 解析群提示消息
    handleGroupTipMsg ( msg ) {
      let msgType = msg.payload.operationType
      switch (msgType) {
        case TIM.TYPES.GRP_TIP_MBR_JOIN:
          this.handleMemberJoinLeaveRoom(msg, 'join')
          break
        case TIM.TYPES.GRP_TIP_MBR_QUIT:
          this.handleMemberJoinLeaveRoom(msg, 'leave')
          break
        case TIM.TYPES.GRP_TIP_GRP_PROFILE_UPDATED:
          break
        case TIM.TYPES.GRP_TIP_MBR_PROFILE_UPDATED:
          break
        default:
          console.log('群提示消息：类型：', msgType)
          break
      }
    },

    handleMsgToBox ( msg, msgCon, type ) {
      this.addMsg(this.handleFormatMsg(msg, msgCon, type))
    },
    handleFormatMsg ( msg, msgCon, type ) {
      return {
        send: msg.nick,
        content: msgCon,
        type: type,
        avatar: msg.avatar,
        identity: '',
        status: status,
      }
    },
    handleMemberJoinLeaveRoom ( msg, type ) {
      // 是否自己  是否 录制账号  是否1v1
      let memberId = msg.payload.operatorID
      // console.log("handleMemberJoinLeaveRoom", memberId);
      if (memberId.includes('tiw_record_user')) return
      if (memberId.indexOf(this.userId) > -1) return
      if (msg.payload.operatorID.indexOf('tic_re') > -1) return
      let userAccount = msg.nick || memberId
      let msgCon = `${userAccount}${type === 'join' ? '进入' : '退出'}房间`
      this.setStudentOnlineStatus(type === 'join')
      if (type !== 'join') {
        this.setStudentPlatform('app')
      }
      this.handleMsgToBox(msg, msgCon, 'notify')
      this.$message.success(msgCon)
      EXT_IM.getGroupMemberList(this.roomId, 100, 0).then(( memberList ) => {
        console.log(memberList)
        this.setUserList(memberList)
      })
    },
    handleClassOver () {
      this.$alert('该课程已结束', '提示', {
        confirmButtonText: '确定',
        callback: () => {
          window.close()
        },
      })
    },
    handleCustomMsg ( msg ) {
      console.log(msg)
      let msgCon = JSON.parse(msg.payload.data)
      let customType = msgCon.type
      switch (customType) {
        case CustomMsgType.C2C_USER_HAND_UP:
          this.handleUserHandUp(msg.from)
          break
        case CustomMsgType.C2C_USER_HAND_DOWN:
          this.handleUserHandDown(msg.from)
          break
        case CustomMsgType.C2C_GET_USER_ENTER:
          this.handleGetUserEnter(msg)
          break
        case CustomMsgType.RECORD_OUT_LINE:
          break
        case CustomMsgType.C_G_ONLINE:
          // this.isOnlineMessage()
          this.setStudentPlatform('web')
          console.log(msg)
          this.setCustomOnlineMessageStamp(msg.time * 1000)
          break
        default:
          console.log('自定义消息', msg)
          break
      }
    },

    isOnlineMessage () {
      this.setIsOnline(true)
      this.setIsOnlineMessageNum()
    },
    handleUserHandUp ( user ) {
      this.setUserHandStatus({ userID: user, handUp: true })
      let msgHands = {
        type: 'group',
        data: CustomMsgType.MsgHandUpUserList(),
      }
      EXT_IM.sendCustomMsg(msgHands)
    },
    handleUserHandDown ( user ) {
      this.setUserHandStatus({ userID: user, handUp: false })
      this.setUserConnectStatus({ userID: user, isConnected: false })
      let msgHandDown = {
        type: 'group',
        data: CustomMsgType.MsgHandUpUserList(),
      }
      let msgConnect = {
        type: 'group',
        data: CustomMsgType.MsgConnectUser(),
      }
      EXT_IM.sendCustomMsg(msgConnect)
      EXT_IM.sendCustomMsg(msgHandDown)
    },
    handleGetUserEnter ( msg ) {
      let msgCon = {
        type: 'c2c',
        targetId: msg.from,
        data: CustomMsgType.MsgUserEnter(),
      }
      let msgDownTime = {
        data: CustomMsgType.MsgStartOrEndCountDown(this.downtime),
      }
      if (this.classStatus === 1) {
        EXT_IM.sendCustomMsg(msgCon)
        EXT_IM.sendCustomMsg(msgDownTime)
      }
      if (this.$route.path.includes('spoken/live')) {
        let modeMsg = {
          data: CustomMsgType.MsgLiveMode(
              this.videoMode == 1 ? 'board' : 'video'
          ),
        }
        EXT_IM.sendCustomMsg(modeMsg)
      }
    },
    ...mapActions([
      // 消息
      'addMsg',

      // 成员
      'setUserList',
      'setStudentPlatform',
      'setCustomOnlineMessageStamp',
      'setStudentOnlineStatus'
    ]),
  },
}
