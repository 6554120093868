import EXT_BOARD from "@/views/live/ext/board";
import * as Config from "@/config/live/live";
import { mapActions, mapState } from "vuex";
import * as Common from "@/config/common/common";
import * as Api from "@/api/live/live";
import Util from "@/utils/utils";
import { generateColumns, generateTips } from "@/utils/i18n";

export default {
  data() {
    return {
      chooseFile: "",
      isEdit: false,
      chooseFileId: [],
      uploadHeaders: {},
      loadingFile: {},
      isUploading: false,
      loadingFileList: [], // 上传中的课件列表
      transcodingList: [], // 转码中的课件ID列表
      finishedUploadList: [], // 转码完成的课件列表
      isConnectGetStatus: true,
      Common,
      UploadProgress: "",
      uploadData: {
        key: "",
        token: "",
      },
      checkInterval: null,
      lastUploadFile: "",
      uploadUrl: Config.QINIU_UPLOAD_ADDRESS[parseInt(Math.random() * 3)],
      uploadFileUrl:''
    };
  },
  props: {
    fileBoxTitle: {
      type: String,
      default: "选择",
    },
    boxType: {
      type: String,
      default: "file",
    },
  },
  computed: {
    uploadAccept() {
      if (this.boxType === "file") {
        return Common.FileType.document;
      } else {
        return Common.FileType.audio;
      }
    },
    chooseAll() {
      if (this.boxType === "file") {
        return (
          this.chooseFileId.length === this.fileList.length &&
          this.chooseFileId.length !== 0
        );
      } else {
        return (
          this.chooseFileId.length === this.audioList.length &&
          this.chooseFileId.length !== 0
        );
      }
    },
    getPagination() {
      if (this.boxType === "file") {
        return this.filePagination;
      } else {
        return this.audioPagination;
      }
    },
    ...mapState({
      localStream: (state) => state.rtc.localStream,
      uploadPercent: (state) => state.board.uploadPercent,
      teduBoard: (state) => state.board.teduBoard,
      fileList: (state) => state.board.fileList,
      audioList: (state) => state.board.audioList,
      filePagination: (state) => state.board.filePagination,
      audioPagination: (state) => state.board.audioPagination,
    }),
  },
  mounted() {},
  updated() {
    this.fileList.forEach((ele) => {
      if (ele.status !== 3) {
        if (this.transcodingList.indexOf(ele.id) < 0) {
          this.transcodingList.push(ele.id);
        }
      }
    });
  },
  methods: {
    generateColumns,
    generateTips,
    getFileList(page) {
      if (this.boxType === "file") {
        Api.teacherDocument("get", { page: page }, (res, pagination) => {
          this.setFileList(res);
          this.setFilePagination(pagination);
        });
      } else {
        Api.teacherAudio("get", { page: page }, (res) => {
          this.setAudioList(res);
        });
      }
    },

    handleChooseFile(file) {
      this.chooseFile = file;
    },
    confirmFile() {
      if (this.chooseFile !== "") {
        this.setCurrentFile(this.chooseFile);
        EXT_BOARD.addToBoard(this.teduBoard, this.chooseFile);
        this.setIsShowCourseWare(false);
        this.close();
      } else {
        this.$message.error(this.generateTips("select_courseware_tips"));
      }
    },
    confirmAudio() {
      if (!this.localStream)
        return this.$message.error(this.generateTips("play_audio_after_class"));
      this.setCurrentAudio(this.chooseFile);
      this.setAudioBarStatus(true);
      this.$emit("dialogClose");
    },
    close() {
      this.$emit("dialogClose");
    },
    edit(status) {
      this.isEdit = status;
      this.chooseFileId = [];
    },
    /*
     * 获取文件转码进度
     * @params ids    待查询的文件ID数组
     * */
    getStatus(ids) {
      if (this.boxType === "audio") {
        // 音频文件不用转码
      } else {
        if (ids.length > 0) {
          if (this.filePagination.current_page !== 1) return;
          Api.documentStatus(
            { ids: ids },
            (res) => {
              let _transcodingList = [];
              let _fileList = this.fileList;
              res.forEach((item) => {
                _fileList.forEach((ele) => {
                  if (ele.id === item.id) {
                    ele.status = item.status;
                    if (item.status === 3) {
                      ele.pages = item.pages || "";
                      ele.resolution = item.resolution || "";
                      ele.result_url = item.result_url || "";
                      ele.title = item.title || "";
                      ele.created_at = item.created_at || "";
                    }
                  }
                });
                if (item.status !== 3) {
                  _transcodingList.push(item.id);
                }
              });
              this.transcodingList = _transcodingList;
              this.setFileList(_fileList);
              this.$forceUpdate();
            },
            () => {}
          );
        }
      }
    },

    /**
     * 上传文件
     */
    uploadFile() {
      var file = document.getElementById("file_input—local").files[0];
      this.setUploadFile(file);
      EXT_BOARD.uploadFile(this.teduBoard, file, this.boxType);
    },

    handleFileItem(id) {
      if (this.chooseFileId.indexOf(id) > -1) {
        let index = this.chooseFileId.findIndex((item) => item === id);
        this.chooseFileId.splice(index, 1);
      } else {
        this.chooseFileId.push(id);
      }
    },

    beforeFileUpload(file) {
      if (this.lastUploadFile === "") {
        this.lastUploadFile = file.name;
      } else {
        if (file.name === this.lastUploadFile) {
          this.$alert(this.generateTips("file_uploading"));
          return false;
        }
      }
      let ext = Util.getFileType(file.name);
      let params = {};
      if (this.boxType === "file") {
        params = {
          module: "document",
          ext: ext,
        };
      } else {
        params = {
          module: "audio",
          ext: ext,
        };
      }

      let fileSize = file.size / 1048576; // 1024*1024
      if (
        (this.boxType === "file" && fileSize > Config.UploadDocLimit) ||
        (this.boxType === "audio" && fileSize > Config.UploadAudioLimit)
      ) {
        this.$alert(this.generateTips("file_too_large"));
        return false;
      } else {
        return new Promise((resolve, reject) => {
          Api.getTencentToken(
            this.boxType,
            params,
            (res) => {
              this.uploadUrl = res.pre_signed_url
              this.uploadFileUrl = res.url
              this.loadingFileList.push(file);
              resolve();
            },
            (error) => {
              reject();
            }
          );
        });
      }
    },
    httpRequest({ file }) {
      
      const xhr = new XMLHttpRequest()
      xhr.open('PUT', this.uploadUrl, true)
      xhr.onload = () => {
        this.handleUploadSuccess({
          key:this.uploadFileUrl
        },file )
      }
      xhr.onerror = () => {
        this.$message.error(this.generateTips(xhr.statusText));
      }
      xhr.send(file)
    },
    handleUploadChange(file, b) {
      if (this.boxType === "file") {
        if (file.status === 2) {
          this.loadingFileList.forEach((item, index) => {
            if (item.uid === file.uid) {
              this.loadingFileList.splice(index, 1);
            }
          });
        }
      } else {
        if (file.status === "success") {
          this.loadingFileList.forEach((item, index) => {
            if (item.uid === file.uid) {
              this.loadingFileList.splice(index, 1);
            }
          });
        }
      }
    },

    handleUploadProgress(event, file, fileList) {
      this.UploadProgress = `上传进度${event.percent.toFixed(2)}%`;
    },

    handleUploadSuccess(res, file, fileList) {
      if (this.boxType === "file") {
        if (!this.checkInterval) {
          this.getCheckInterval();
        }
        let params = {
          document_url: res.key,
          document_name: file.name,
        };
        Api.teacherDocument("post", params, (res) => {
          this.loadingFileList.forEach((item, index) => {
            if (item.uid === file.uid) {
              this.loadingFileList.splice(index, 1);
            }
          });
          file.id = res.id;
          file.status = 2;
          file.document_ext = file.name;
          file.document_name = file.name;
          let _fileList = this.fileList;
          _fileList.unshift(file);
          this.setFileList(_fileList);
        });
      } else {
        let params = {
          audio_url: res.key,
          audio_name: file.name,
        };
        Api.teacherAudio("post", params, (res) => {
          console.log(res);
          this.loadingFileList.forEach((item, index) => {
            if (item.uid === file.uid) {
              this.loadingFileList.splice(index, 1);
            }
          });
          this.getFileList();
        });
      }
    },

    handleUploadFail(err, file) {
      if (err) {
        let error = JSON.parse(JSON.stringify(err));
        if (error.status === 413) {
          this.$alert(this.generateTips("file_too_large"));
        } else if (error.status === 403) {
          this.$alert(this.generateTips("file_format_error"));
        }
        this.loadingFileList.forEach((item, index) => {
          if (item.uid === file.uid) {
            this.loadingFileList.splice(index, 1);
          }
        });
      } else {
        this.$alert(this.generateTips("file_upload_fail"));
      }
    },

    //
    chooseAllFile(type) {
      this.chooseFileId = [];
      if (this.boxType === "file") {
        if (type === "all") {
          this.fileList.forEach((ele) => {
            this.chooseFileId.push(ele.id);
          });
        } else {
          this.chooseFileId = [];
        }
      } else {
        if (type === "all") {
          this.audioList.forEach((ele) => {
            this.chooseFileId.push(ele.id);
          });
        } else {
          this.chooseFileId = [];
        }
      }
    },

    // 删除文件
    deleteFile(id) {
      this.$confirm("确认删除", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params;
        if (id === "all") {
          params = {
            ids: this.chooseFileId,
          };
        } else {
          params = {
            ids: [id],
          };
        }
        if (this.boxType === "file") {
          this.deleteDocument(params);
        } else {
          this.deleteAudio(params);
        }
      });
    },
    getCheckInterval() {
      this.checkInterval = setInterval(() => {
        this.getStatus(this.transcodingList);
      }, 3000);
    },
    deleteDocument(params) {
      Api.teacherDocument("delete", params, (res) => {
        this.chooseFileId = [];
        this.isEdit = false;
        Api.teacherDocument("get", "", (res, pagination) => {
          this.setFileList(res);
          this.setFilePagination(pagination);
        });
      });
    },
    deleteAudio(params) {
      Api.teacherAudio("delete", params, (res) => {
        this.chooseFileId = [];
        this.isEdit = false;
        Api.teacherAudio("get", "", (res) => {
          this.setAudioList(res);
        });
      });
    },
    ...mapActions([
      "setCurrentFile",
      "setFileBoxStatus",
      "setIsShowCourseWare",
      "setUploadPercent",
      "setUploadFile",
      "setAudioList",
      "setFileList",
      "setFilePagination",
      "setCurrentAudio",
      "setAudioBarStatus",
    ]),
  },
  watch: {
    transcodingList(newVal, odlVal) {
      if (newVal.length < 1) {
        if (this.checkInterval) {
          clearInterval(this.checkInterval);
          this.checkInterval = null;
        }
      }
    },
  },
};
