import * as Api from '@/api/live/live'
import * as Config from '@/config/live/live'
import * as Constant from '@/views/live/constants/constant'

import TIM from 'tim-js-sdk'
import TRTC from 'trtc-js-sdk'
import TIMUploadPlugin from 'tim-upload-plugin'
import $ from 'jquery'
import EXT_IM from '@/views/live/ext/im'
import EXT_BOARD from '@/views/live/ext/board'
import Util from '@/utils/utils'
import draggable from '@/utils/directives.js'
import { mapActions, mapState } from 'vuex'
import * as CustomMsgType from '@/views/live/constants/customMsgType'

import RTC from '@/views/live/modules/rtc/rtc.vue'
import File from '@/views/live/modules/file/file.vue'
import Board from '@/views/live/modules/board/board.vue'
import Footer from '@/views/live/modules/footer/footer.vue'
import ChatRoom from '@/views/live/modules/chatroom/chatroom.vue'
import FileBox from '@/views/live/modules/file/fileBox/fileBox.vue'
import Video from '@/views/live/modules/video/video.vue'
import Countdown from '@/views/live/modules/countdown/countdown.vue'
import ShowCountTime from '@/views/live/modules/countdown/showCountTime.vue'
import FeedBack from '@/views/live/modules/feedback/feedback.vue'
import Devicetest from '@/views/live/modules/devicetest/devicetest.vue'
import OpenCamera from '@/views/live/components/openCamera.vue'
import { imMixin } from '@/views/live/mixins/imMixin'
import { rtcMixin } from '@/views/live/mixins/rtcMixin'
import { boardMixin } from '@/views/live/mixins/boardMixin'
import * as Utils from '@/utils/utils'

import { generateColumns, generateTips } from '@/utils/i18n'

export default {
  mixins: [imMixin, rtcMixin, boardMixin],
  directives: {
    draggable,
  },
  data () {
    return {
      courseDesc: {},
      Constant,
      Config,
      countDownNum: Config.LIVE_RECORD_COUNTDOWN,
      flagRecordCountDown: false,
      courseId: '',
      roomId: '',
      status: 0,
      tic: null,
      tim: null,
      trtc: null,
      board: null,
      fileListVisible: false,
      audioListVisible: false,
      liveMode: Constant.LIVE_MODE.private, // 班课  1v1
      privateUser: {}, // 1v1课程 学生信息
      announcement: '',
      isShowBigPic: false,
      bigImgSrc: '',
      imReady: false,
      shareUser: {},
      isShowSetTimeOut: false,
      isShowCountTime: false,
      endTime: null,
      iSshowFeedBack: false,
      isShowdeviceTest: true,
      isTestDevice: true,
      isShowPackUp: true,
      isShowOpenCameraDialog: false,
      routeMode: '',
      timer: null,
      noClassSendMsgTimer: null,
    }
  },
  computed: {
    ...mapState({
      userId: ( state ) => state.live.userId,
      userToken: ( state ) => state.live.userToken,
      fileList: ( state ) => state.board.fileList,
      isVideoCall: ( state ) => state.rtc.isVideoCall,
      audioList: ( state ) => state.board.audioList,
      groupInfo: ( state ) => state.live.groupInfo,
      localStream: ( state ) => state.rtc.localStream,
      classStatus: ( state ) => state.live.classStatus,
      micStatus: ( state ) => state.live.micStatus,
      cameraStatus: ( state ) => state.live.cameraStatus,
      voiceStatus: ( state ) => state.live.voiceStatus,
      nextMsgSeq: ( state ) => state.im.nextMsgSeq,
      isShowNotificationBox: ( state ) => state.live.isShowNotificationBox,
      authorization: ( state ) => state.user.authorization,
      remoteVideoStream: ( state ) => state.rtc.remoteVideoStream,
      mainClient: ( state ) => state.rtc.mainClient,
      canBeginClass: ( state ) => state.live.canBeginClass,
      shareStatus: ( state ) => state.live.shareStatus,
      videoMode: ( state ) => state.footer.videoMode,
      deviceStatus: ( state ) => state.footer.testStatus,
      isShowPackUpVideo: ( state ) => state.board.isPackUpVideo,
      isOnlineMessageNum: ( state ) => state.im.isOnlineMessageNum,
      studentInClassStatus: ( state ) => state.im.studentInClassStatus,
      beforeShareVideoModeStatus: ( state ) =>
          state.footer.beforeShareVideoModeStatus,
      studentOnlineStatus: ( state ) => state.im.studentOnlineStatus,
    }),
    routeSpoken () {
      return this.videoMode == 1
    },
    //口语陪练页面
    routeSpokenMode () {
      return this.$route.path.includes('spoken/live')
    }
  },
  watch: {
    videoMode ( val ) {
      if (!this.canBeginClass) {
        let isOpenCamera = this.cameraStatus !== 0
        // this.startRTC(isOpenCamera, true);
      }
    },
    isOnlineMessageNum ( newVal ) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.setIsOnline(false)
        this.setIsOnlineMessageNum(0)
      }, 15000)
    },
    classStatus ( newVal ) {
      if (newVal === 1) {
        clearInterval(this.noClassSendMsgTimer)
      } else if (newVal === 2) {
        this.startFixTimeInterVal()
      }
    },
    winHeight ( newVal ) {
      console.log(newVal)
    }
  },

  mounted () {
    // 白板消息
    setInterval(() => {
      let msg = {
        data:
            {
              'seq': 55230757340963,
              'timestamp': Date.now(),
              'value': {
                'boardId': '#DEFAULT',
                'operator': this.userId,
                'actions': []
              }
            },
        extension: 'TXWhiteBoardExt',
        type: 'group'
      }
      EXT_IM.sendCustomMsg(msg)
    }, 60000) // 300000毫秒等于5分钟
    this.startFixTimeInterVal()
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    this.routeMode = this.isTestDevice =
        JSON.parse(sessionStorage.getItem('isTestDevice')) == null
            ? 'true'
            : JSON.parse(sessionStorage.getItem('isTestDevice'))
    if (this.$route.path.includes('private/live')) {
      this.setVideoMode(1)
    } else {
      this.setVideoMode(2)
    }
    fundebug.notify(`${this.$route.params.id}进房间`, '老师进入教室', {})
    window.TIM = TIM
    window.TRTC = TRTC

    // 是否打印 TRTC SDK log
    // TRTC.Logger.setLogLevel(TRTC.Logger.LogLevel.TRACE)
    TRTC.checkSystemRequirements().then(( result ) => {
      if (!result) {
        this.$alert(
            this.generateTips('not_support_live'),
            this.generateColumns('hint'),
            {
              confirmButtonText: '确定',
              callback: ( action ) => {
                window.location.href = 'https://www.google.cn/chrome/'
              },
            }
        )
      }
    })
    // 当前浏览器是否支持 TRTC
    TRTC.checkSystemRequirements().then(( result ) => {
      if (result) {
        // 当前浏览器是否支持 屏幕分享
        if (
            Util.getBrower().name === 'chrome' &&
            Util.getBrower().version > 72
        ) {
          this.setIsSupportShare(true)
        }
      } else {
        this.$alert(
            this.generateTips('not_support_live'),
            this.generateColumns('hint'),
            {
              confirmButtonText: this.generateColumns('affirm'),
              callback: ( action ) => {
                window.location.href = 'https://www.google.cn/chrome/'
              },
            }
        )
      }
    })

    // 聊天框图片放大
    let _this = this
    $('.live_page').on('click', '.chat_img', function () {
      let src = $(this).attr('bigimgsrc')
      _this.showOriginPic(src)
    })

    this.liveMode =
        this.$route.path.includes('private') ||
        this.$route.path.includes('spoken')
            ? Constant.LIVE_MODE.private
            : Constant.LIVE_MODE.class
    this.setLiveMode(this.liveMode)
    this.courseId = this.$route.params.id
    this.getLiveDetail(this.courseId)

    // 开始上课倒计时数秒
    this.handleBeginClassCountdown()
  },
  methods: {
    generateColumns,
    generateTips,
    handleResize () {
      const windowHeight = window.innerHeight
      Utils.default.devicentsetMedia(windowHeight, '.devicetest')
      // 在这里执行其他操作
    },
    startFixTimeInterVal () {
      // 5秒发送一次在线消息
      this.noClassSendMsgTimer = setInterval(() => {
        let msg = {
          data: CustomMsgType.MsgOnline(),
          type: 'group'
        }
        EXT_IM.sendCustomMsg(msg)
      }, 5000)
    },
    async getLiveDetail ( id ) {
      if (this.routeSpokenMode) {
        await Api.joinRoom(id, this.$route.path.includes('private') ? false : true, { type: 2 })
        await Api.getLiveDetail(
            id,
            { side_user: 1 },
            this.liveMode,
            this.routeSpokenMode
                ? Constant.ENTERANCE.speaking
                : Constant.ENTERANCE.course,
            ( res ) => {
              let month = new Date(res.started_at * 1000).getMonth() + 1
              let day = new Date(res.started_at * 1000).getDate()
              this.courseDesc = {
                title: res.category + res.type,
                time: `${month}月${day}日 ${Util.formatMoment(
                    res.started_at,
                    'HH:mm'
                )}-${Util.formatMoment(res.ended_at, 'HH:mm')}`,
              }
              this.setIsClassTime(res.ended_at)
              this.shareUser = res.live
              this.roomId = res.live.room_id
              this.privateUser = res.user
              sessionStorage.privateUser = JSON.stringify(this.privateUser)
              this.setIsVideoCall(true)
              this.setRoomID(this.roomId)
              this.initLive()
            }
        )
      } else {
        Api.getLiveDetail(
            id,
            { side_user: 1 },
            this.liveMode,
            this.routeSpokenMode
                ? Constant.ENTERANCE.speaking
                : Constant.ENTERANCE.course,
            ( { data, server_time } ) => {
              let serverTime = server_time
              if (this.liveMode === Constant.LIVE_MODE.private) {
                this.courseDesc = {
                  title: data.category + data.course_subject + '1v1',
                  time: `${Util.formatMoment(
                      data.started_at,
                      'MM/DD'
                  )} ${Util.formatMoment(
                      data.started_at,
                      'HH:mm'
                  )}-${Util.formatMoment(data.ended_at, 'HH:mm')}`,
                  serverTime: serverTime,
                  endTime: data.ended_at,
                }

                this.privateUser = data.user
                sessionStorage.privateUser = JSON.stringify(this.privateUser)
              } else {
                this.courseDesc = {
                  title: data.course.name,
                  time: `${Util.formatMoment(
                      data.started_at,
                      'MM/DD'
                  )} ${Util.formatMoment(
                      data.started_at,
                      'HH:mm'
                  )}-${Util.formatMoment(data.ended_at, 'HH:mm')}`,
                  serverTime: serverTime,
                  endTime: data.ended_at,
                }
              }
              this.setIsClassTime(data.ended_at)
              this.shareUser = data.live
              this.roomId = data.live.room_id
              this.setIsVideoCall(
                  this.routeSpokenMode ? true : data.is_video_call
              )
              this.setRoomID(this.roomId)
              this.initLive()
            }
        )
      }
    },

    /*
     * 1.初始化IM
     * 2.登录IM
     * 3.加入聊天室
     * 4.初始化白板
     * 5.设置老师身份
     * */
    async initLive () {
      this.initData()
      this.tim = window.tim = EXT_IM.getIMInstance()

      this.setTim(this.tim)
      this.tim.registerPlugin({ 'tim-upload-plugin': TIMUploadPlugin })
      this.imEventListener(this.tim)
      this.tim.setLogLevel(1)

      await this.joinClass()
      // await EXT_IM.getUserOnlineStatus(this.tim, '6eace81bba4e4c5eada3443ee7b2d253');
    },
    async afterLogin () {
      let memberList = await EXT_IM.getGroupMemberList(this.roomId, 100, 0)
      this.setUserList(memberList)
      let groupInfo = await EXT_IM.getGroupInfo(this.tim, this.roomId)
      groupInfo = groupInfo.data.group
      this.setGroupInfo(groupInfo)
      sessionStorage.getMessageCount = 1 // 循环获取聊天记录（腾讯的聊天记录有时候会拿不到）
      this.setNextMsgSeq(groupInfo.NextMsgSeq)
      await this.getHistory()
      // 班课通知学生连麦情况
      if (this.liveMode === Constant.LIVE_MODE.class) {
        let connectMsg = {
          type: 'group',
          data: CustomMsgType.MsgConnectUser(),
        }
        EXT_IM.sendCustomMsg(connectMsg)
        let handUpMsg = {
          type: 'group',
          data: CustomMsgType.MsgHandUpUserList(),
        }
        EXT_IM.sendCustomMsg(handUpMsg)

        // ios 用
        let userEnterMsg = {
          type: 'group',
          data: CustomMsgType.UsserTellEnter(this.userId),
        }
        EXT_IM.sendCustomMsg(userEnterMsg)
      }
      if (this.classStatus === 1) {
        //学生状态判断直接进入教师
        await this.beginClass()
      }
    },

    initData () {
      this.status = Constant.USER_STATUS.STATUS_UNLOGIN
    },
    async joinClass () {
      await EXT_IM.handleLoginIM(this.userId, this.userToken).catch(( e ) => {
        fundebug.notify(`${this.$route.params.id}`, JSON.stringify(e))
        location.reload()
      })
      try {
        await EXT_IM.handleJoinIMGroup(this.tim, this.roomId).catch(( e ) => {
          fundebug.notify(`${this.$route.params.id}`, JSON.stringify(e))
        })
      } catch (e) {
        if (e.code === 10013) {
          this.tim.quitGroup(this.roomId).then(() => {
            EXT_IM.handleJoinIMGroup(this.tim, this.roomID)
          })
        } else {
          fundebug.notify(`${this.$route.params.id}`, JSON.stringify(e))
        }
      }
      if (this.teduBoard) {
        this.teduBoard.destroy()
      }
      // if (this.$route.path.includes("private/live")) {
      // }
      await this.initBoard()
      let clientConfig = {
        // sdkAppId: Config.sdkAppId,
        sdkAppId: JSON.parse(localStorage.getItem('SDKAppId')),
        userId: this.userId,
        userSig: this.userToken,
      }
      let mainClient = await this.createMainClient(clientConfig)
      this.setMainClient(mainClient)
    },
    //实例化白板
    async initBoard () {
      let boardConfig = {
        id: 'paint_box',
        classId: this.roomId,
        // sdkAppId: Config.sdkAppId,
        sdkAppId: JSON.parse(localStorage.getItem('SDKAppId')),
        userId: this.userId,
        userSig: this.userToken,
        ratio: '4:3',
        boardContentFitMode: 0, // 白板内容自适应模式
        brushThin: 30,
        textSize: 400,
        textColor: '#ff4040',
      }
      let teduBoard = await EXT_BOARD.getBoardInstance(boardConfig).catch(
          ( e ) => {
            fundebug.notify(`${this.$route.params.id}`, JSON.stringify(e))
          }
      )
      window.teduBoard = teduBoard
      this.setTeduBoard(teduBoard)
      this.initBoardEvent()
      if (this.routeSpokenMode) {
        await this.getPermission(this.courseId).catch(( e ) => {
          fundebug.notify(`${this.$route.params.id}`, JSON.stringify(e))
        })
      } else {
        await this.getPermission(this.courseId).catch(( e ) => {
          fundebug.notify(`${this.$route.params.id}`, JSON.stringify(e))
        })
      }
    },


    beforeBeginClass () {
      const classType = this.$route.path.includes('private') ? 'private' : 'training'
      if (classType === 'private') {
        if (localStorage.isChoiceAlwaysOpen && JSON.parse(localStorage.isChoiceAlwaysOpen) === true) {
          this.setCameraStatus(1)
          this.beginClass()
        } else {
          this.isShowOpenCameraDialog = true
        }
      } else {
        this.beginClass()
      }
    },

    beginClassWithOpenCamera () {
      this.onCloseCameraTip()
      this.setCameraStatus(1)
      this.beginClass()
    },


    // 开始上课
    async beginClass () {
      let canBeginClassCountDown = parseInt(
          sessionStorage.canBeginClassCountDown
      )
      if (canBeginClassCountDown > 0) {
        this.$message.error(
            canBeginClassCountDown + this.generateTips('second_to_start')
        )
        this.setCanBeginClass(true)
        return
      }
      fundebug.notify(`${this.$route.params.id}`, {
        message: '调用开始上课',
      })
      Util.getLiveRoomStatus(
          this.$route.params.id,
          this.liveMode,
          this.routeSpokenMode
              ? Constant.ENTERANCE.speaking
              : Constant.ENTERANCE.course
      )
          .then(async () => {
            if (this.routeSpokenMode) {
              await Api.joinRoom(this.$route.params.id, this.$route.path.includes('private') ? false : true, { type: 3 })
              await this.mainClient.join({ roomId: parseInt(this.roomId) })

              return
            } else {
              await this.mainClient.join({ roomId: parseInt(this.roomId) })
              // await this.changeMode(2)
              return

            }
          })
          .then(() => {
            fundebug.notify(`${this.$route.params.id}`, {
              message: '调用开始录制前',
            })
            this.initRTCEvent()
            Api.startRecord(
                this.courseId,
                this.liveMode,
                this.routeSpokenMode
                    ? Constant.ENTERANCE.speaking
                    : Constant.ENTERANCE.course,
                () => {
                  // throw new Error("开始录制失败");
                  fundebug.notify(`${this.$route.params.id}`, {
                    message: '调用开始录制成功',
                  })
                  // 录制倒计时
                  sessionStorage.startRecordStatus = 3
                  let recordCountDown = setInterval(() => {
                    this.countDownNum--
                    this.flagRecordCountDown = false
                    if (this.countDownNum > 0) {
                      setTimeout(() => {
                        this.flagRecordCountDown = true
                      }, 100)
                    }
                    if (this.countDownNum <= 0) {
                      clearInterval(recordCountDown)
                      this.countDownNum = Config.LIVE_RECORD_COUNTDOWN
                    }
                  }, 1000)
                  this.$message.success(this.generateTips('start_record'))
                  // 通知开始上课
                  let msg = {
                    type: 'group',
                    data: CustomMsgType.MsgTeacherStartClass,
                  }
                  EXT_IM.sendCustomMsg(msg)

                  this.setClassStatus(Constant.CLASS_STATUS.STARTED)
                  sessionStorage.classStatus = Constant.CLASS_STATUS.STARTED
                  let isOpenCamera = this.cameraStatus !== 0
                  this.setMicStatus(1)
                  this.startRTC(isOpenCamera, true)
                  if (this.remoteVideoStream) {
                    // this.remoteVideoStream.play(document.getElementsByClassName('remote_video')[0])
                  }
                  // todo setCanBeginClass
                },
                ( err ) => {
                  if (status === 'fail') {
                    this.$message.error('开始上课失败~')
                  }
                  fundebug.notify(`${this.$route.params.id}`, JSON.stringify(err))
                }
            )
          })
          .catch(( err ) => {
            try {
              this.tim.logout().then(() => {
                location.reload()
              })
            } catch (e) {
              fundebug.notify(`${this.$route.params.id}`, JSON.stringify(err))
            }
          })
    },
    playRemote () {
      this.remoteVideoStream
          .play(document.getElementsByClassName('remote_video')[ 0 ])
          .then(( e ) => {
            this.remoteVideoStream.resume()
          })
          .catch(( err ) => console.log('remoteVideoStream:err:', err))
    },
    stopRemote () {
      this.remoteVideoStream.stop()
    },

    //设置群组资料
    modifyGroup ( obj ) {
      let options = {
        groupId: this.roomId,
      }
      Object.assign(options, obj)
      EXT_IM.modifyGroup(this.tim, options).then(( res ) => {
        this.closeNotificationBox()
        this.$message.success(this.generateTips('modify_group_info_success'))
        EXT_IM.getGroupInfo(this.tim, this.roomId).then(( groupInfo ) => {
          groupInfo = groupInfo.data.group
          this.setGroupInfo(groupInfo)
        })
      })
    },

    // 关闭编辑公告框
    closeNotificationBox () {
      this.setIsShowNotificationBox(false)
    },
    // 退出课堂
    /*
     * 1. 退出IM房间
     * 2. 清空store中的学生列表和聊天列表
     * 3. 跳转页面
     * */
    quitClassroom () {
      if (this.roomId) {
        this.tim
            .logout()
            .then(() => {
              window.close()
            })
            .catch(( e ) => {
              window.close()
            })
      } else {
        window.close()
      }
    },

    clearClassInfo () {
    },

    imReadyHandler ( { name } ) {
      // im 已经准备好了
      this.imReady = true
      if (name === 'sdkStateReady') {
        this.afterLogin()
      }
    },

    // 获取用户身份
    getUserProfileInUserList ( userAccount ) {
      let user = {}
      this.userList.forEach(( ele ) => {
        if (ele.userID === userAccount) {
          user = ele
        }
      })
      return user
    },

    showFileList ( type ) {
      if (type === 'file') {
        this.fileListVisible = true
        // this.setFileList(this.fileList)
      } else {
        this.audioListVisible = true
        // this.fileList = this.audioList
        // this.setAudioList(this.audioList)
      }
    },
    dialogClose () {
      this.fileListVisible = false
      this.audioListVisible = false
      this.isShowBigPic = false
    },
    getHistory () {
      let self = this
      let beginMsgSeq = this.nextMsgSeq
      let conversationID = 'GROUP' + this.roomId
      EXT_IM.getHistoryMsg(this.tim, conversationID, beginMsgSeq).then(
          ( list ) => {
            list = list.data
            if (list.length === 0) {
              this.setNextMsgSeq(1)
              return
            }
            this.setNextMsgSeq(list.nextReqMessageID)
            this.setIsCompleted(list.isCompleted)
            sessionStorage.getMessageCount =
                parseInt(sessionStorage.getMessageCount) + 1
            sessionStorage.msgIsCompleted = list.isCompleted
            EXT_IM.dealHistoryMsg(list.messageList).then(( msgList ) => {
              if (msgList.length > 0) {
                let list = []
                msgList.forEach(( ele ) => {
                  let user = ele.user
                  let text = ele.text
                  let type = ele.type
                  let account = user.nick || user.account
                  if (user.account === this.userId) {
                    account = 'personal'
                  }
                  let obj = {
                    send: account,
                    content: text,
                    type: type,
                    avatar: user.avatar,
                    identity: user.identity,
                    status: status,
                  }
                  list.push(obj)
                })
                this.addHistoryMsg(list)
              }
            })
          }
      )
    },
    /**
     * 获取老师身份
     * @params id  courseId  列表通过URL传递
     */
    getPermission ( id ) {
      return new Promise(( resolve, reject ) => {
        Api.getPermission(
            id,
            this.liveMode,
            this.routeSpokenMode
                ? Constant.ENTERANCE.speaking
                : Constant.ENTERANCE.course,
            () => {
              EXT_IM.getMemberList(this.roomId, 100, 0).then(( res ) => {
                let list = res.data.memberList
                this.setUserList(list)
                resolve()
              })
            },
            ( e ) => {
              reject(e)
            }
        )
      })
    },
    showOriginPic ( src ) {
      this.bigImgSrc = src
      this.isShowBigPic = true
    },
    handleBeginClassCountdown () {
      if (parseInt(sessionStorage.canBeginClassCountDown) > 0) {
        sessionStorage.canBeginClassInterval = setInterval(() => {
          sessionStorage.canBeginClassCountDown =
              parseInt(sessionStorage.canBeginClassCountDown) - 1
          if (sessionStorage.canBeginClassCountDown <= 0) {
            clearInterval(sessionStorage.canBeginClassInterval)
          }
        }, 1000)
      }
    },
    finishBus () {
      this.$refs.footerRefs.finishClass()
    },
    showSetTimeOut () {
      this.isShowCountTime = false
      this.isShowSetTimeOut = true
    },
    close () {
      this.isShowSetTimeOut = false
    },
    confirm ( time ) {
      this.isShowCountTime = true
      this.endTime = null
      this.endTime = time
    },
    countTime () {
      this.isShowCountTime = false
    },
    showFeedBack () {
      this.iSshowFeedBack = true
    },
    closeDialog () {
      this.iSshowFeedBack = false
    },
    closeDeviceTest () {
      this.isShowdeviceTest = false
    },
    packUp ( type ) {
      this.isShowPackUp = !this.isShowPackUp
      this.setIsShowPackUp(this.isShowPackUp)
    },
    onCloseCameraTip () {
      this.isShowOpenCameraDialog = false
    },

    ...mapActions([
      'setTim',
      'setWebRtc',
      'setRoomID',
      'setUserList',
      'addHistoryMsg',
      'setNextMsgSeq',
      'setGroupInfo',
      'setMicStatus',
      'setCanBeginClass',
      'setMainClient',
      'setVoiceStatus',
      'setLiveMode',
      'setIsShowNotificationBox',
      'setTeduBoard',
      'setClassStatus',
      'setTrtcClient',
      'setLocalStream',
      'clearTic',
      'setShareClient',
      'setRecordInstance',
      'setCurrentFile',
      'setVolumeValue',
      'setIsSupportShare',
      'setFileList',
      'setIsVideoCall',
      'setIsCompleted',
      'setUserHandStatus',
      'setCanTeachStatus',
      'setUserConnectStatus',
      'setIsShowCourseWare',
      'setUploadPercent',
      'setUploadFile',
      'setDownloadQuality',
      'setUploadQuality',
      'setRemoteVideoStream',
      'setVideoMode',
      'setIsShowPackUp',
      'setRemoteStreamVideo',
      'setUserJionTim',
      'setIsClassTime',
      'setIsOnline',
      'setIsOnlineMessageNum',
      'setCameraStatus'
    ]),
  },
  components: {
    RTC,
    File,
    Board,
    Video,
    Footer,
    FileBox,
    ChatRoom,
    FeedBack,
    Countdown,
    Devicetest,
    ShowCountTime,
    OpenCamera
  },
}
