<template>
  <div class="edit">
    <template
      v-if="!(category == '实用英语' || category == 'Practical English')"
    >
      <template v-if="class_type == '商务英语' || class_type == '实用英语'">
        <div class="chapter">
          <div class="title">{{ generateColumns("errorCorrection") }}</div>
          <el-input
            type="textarea"
            autosize
            v-model="feedback.error_correction"
          ></el-input>
        </div>
        <div class="chapter">
          <div class="title">{{ generateColumns("advice") }}</div>
          <el-input
            type="textarea"
            autosize
            v-model="feedback.advice"
          ></el-input>
        </div>
      </template>
      <template v-else>
        <div class="chapter bandScore">
          <div class="title">{{ generateColumns("band_score") }}</div>
          <el-input-number
            size="mini"
            :step="category === 'IELTS' || category === '雅思' ? 0.5 : 1"
            :min="0"
            v-model="feedback.score"
          ></el-input-number>
        </div>
        <div class="criteria_box chapter">
          <div class="title">{{ generateColumns("criteria") }}</div>
          <div
            class="criteria"
            v-for="(item, index) in criteria"
            :key="item.title"
          >
            <div class="criteria_title">{{ index + 1 }}.{{ item.title }}</div>
            <div class="scores">
              <div
                class="score_box"
                v-if="
                  category === 'IELTS' || (category === '雅思' && item.score)
                "
              >
                <el-input-number
                  size="mini"
                  :step="category === 'IELTS' || category === '雅思' ? 0.5 : 1"
                  :min="0"
                  v-model="item.score[0].value"
                  label="独立1"
                  controls-position="right"
                ></el-input-number>
              </div>
              <template v-else>
                <div class="score_box">
                  <span>{{ generateColumns("independent") }}</span>
                  <el-input-number
                    size="mini"
                    :step="
                      category === 'IELTS' || category === '雅思' ? 0.5 : 1
                    "
                    :min="0"
                    v-model="item.score[0].value"
                    label=""
                    controls-position="right"
                  ></el-input-number>
                </div>
                <div class="score_box">
                  <span>{{ generateColumns("multiple") }}</span>
                  <el-input-number
                    size="mini"
                    :step="
                      category === 'IELTS' || category === '雅思' ? 0.5 : 1
                    "
                    :min="0"
                    v-model="item.score[1].value"
                    label=""
                    controls-position="right"
                  ></el-input-number>
                </div>
              </template>
            </div>
            <div class="description">
              <span></span>
              <el-input
                placeholder="description"
                type="textarea"
                autosize
                v-model="item.content"
              ></el-input>
            </div>
          </div>
        </div>

        <div class="chapter">
          <div class="title">{{ generateColumns("errorCorrection") }}</div>
          <el-input
            type="textarea"
            autosize
            v-model="feedback.error_correction"
          ></el-input>
        </div>
        <div class="chapter">
          <div class="title">{{ generateColumns("advice") }}</div>
          <el-input
            type="textarea"
            autosize
            v-model="feedback.advice"
          ></el-input>
        </div>
      </template>
    </template>

    <template v-else>
      <div class="chapter">
        <div class="title">{{ generateColumns("errorCorrection") }}</div>
        <el-input
          type="textarea"
          autosize
          v-model="feedback.error_correction"
        ></el-input>
      </div>
      <div class="chapter">
        <div class="title">{{ generateColumns("advice") }}</div>
        <el-input
          type="textarea"
          autosize
          v-model="feedback.advice"
        ></el-input>
      </div>
    </template>

    <div class="operation" v-if="status === 1 || (status === 2 && subStatus === 20)">
      <el-button size="mini" @click="cancel">{{
        generateButton("cancel")
      }}</el-button>
      <el-button size="mini" type="primary" @click="saveForm">{{
        generateButton("saveAs")
      }}</el-button>
      <!-- 如果是spoken/live页面没有提交按钮 -->
      <span
        v-show="!$route.path.includes('spoken/live')"
        style="margin-left: 8px"
      >
        <el-button
          v-if="canSubmit || $route.path.includes('spoken/live')"
          size="mini"
          type="primary"
          @click="submitForm"
          >{{ generateButton("submit") }}
        </el-button>
      </span>
    </div>
  </div>
</template>

<script>
import * as Api from "@/api/traningSpeaking/appointment";

import { generateColumns, generateButton, generateTips } from "@/utils/i18n";

export default {
  name: "edit",
  components: {},
  props: {
    id: {
      type: Number,
      default: undefined,
    },
    category: {
      type: String,
      default: "IELTS",
    },
    status: {
      type: Number,
      default: 1,
    },
    subStatus: {
      type: Number,
      default: 0,
    },
    endTime: {
      type: Number,
    },
    class_type: {
      type: String,
    },
  },
  data() {
    return {
      serverTime: "",
      feedback: {
        score: undefined,
        error_correction: "",
        advice: "",
      },
      ieltsCriteria: [
        {
          title: "Fluency and coherence",
          score: [
            {
              key: "score",
              value: 0,
            },
          ],
          content: "",
        },
        {
          title: "Lexical Resource",
          score: [
            {
              key: "score",
              value: 0,
            },
          ],
          content: "",
        },
        {
          title: "Grammar range and accuracy",
          score: [
            {
              key: "score",
              value: 0,
            },
          ],
          content: "",
        },
        {
          title: "Pronunciation",
          score: [
            {
              key: "score",
              value: 0,
            },
          ],
          content: "",
        },
      ],
      tofelCriteria: [
        {
          title: "General Description",
          score: [
            {
              key: "Independence",
              value: 0,
            },
            {
              key: "Composite",
              value: 0,
            },
          ],
          content: "",
        },
        {
          title: "Delivery",
          score: [
            {
              key: "Independence",
              value: 0,
            },
            {
              key: "Composite",
              value: 0,
            },
          ],
          content: "",
        },
        {
          title: "Language Use",
          score: [
            {
              key: "Independence",
              value: 0,
            },
            {
              key: "Composite",
              value: 0,
            },
          ],
          content: "",
        },
        {
          title: "Topic Development",
          score: [
            {
              key: "Independence",
              value: 0,
            },
            {
              key: "Composite",
              value: 0,
            },
          ],
          content: "",
        },
      ],
      requiredStr: "",
    };
  },
  computed: {
    criteria: {
      get() {
        if (this.category === "雅思" || this.category === "IELTS") {
          return this.ieltsCriteria;
        } else {
          return this.tofelCriteria;
        }
      },
      set(value) {
        console.log(value);
        return value;
      },
    },
    canSubmit() {
      return this.serverTime > this.endTime;
    },
  },
  mounted() {
    this.getData();
    this.getServerTime();
  },
  methods: {
    getData() {
      let sopkenId = this.$route.params.id;
      Api.appointmentsFeedback(
        this.$route.path.includes("spoken/live") ? sopkenId : this.id,
        "",
        ({ advice, error_correction, score, criteria }) => {
          this.feedback = { advice, error_correction, score };
          if (criteria) {
            if (this.category === "雅思" || this.category === "IELTS") {
              this.ieltsCriteria = criteria;
            } else {
              this.tofelCriteria = criteria;
            }
          }
        }
      );
    },
    submitForm() {
      let sopkenId = this.$route.params.id;
      let params = {
        is_draft: 0,
        criteria: this.criteria,
        ...this.feedback,
      };
      console.log(params);
      let canTip = true;

      Object.keys(params).forEach((ele) => {
        if ((params[ele] === "" || params[ele] === undefined) && canTip) {
          canTip = false;
          return this.$message.error(ele + this.generateTips("required"));
        }
      });
      if(this.category != '实用英语' && this.category != 'Practical English'){
        this.criteria.forEach((ele) => {
          Object.keys(ele).forEach((item) => {
            if (ele[item] === "" && canTip) {
              canTip = false;
              if (item === "content") {
                return this.$message.error(
                  ele["title"] + " description" + this.generateTips("required")
                );
              } else {
                return this.$message.error(item + this.generateTips("required"));
              }
            } else if (item === "score" && canTip) {
              if (Array.isArray(ele[item])) {
                if (ele[item][0].value && ele[item][1] && ele[item][1].value) {
                } else {
                  if (
                    ele[item][0].value !== 0 &&
                    ele[item][1] &&
                    !ele[item][0].value
                  ) {
                    canTip = false;
                    return this.$message.error(
                      ele.title + this.generateTips("required")
                    );
                  }
                  if (
                    ele[item][1] &&
                    ele[item][1].value !== 0 &&
                    !ele[item][1].value
                  ) {
                    canTip = false;
                    return this.$message.error(
                      ele.title + this.generateTips("required")
                    );
                  }
                }
              } else {
                if (!ele[item].value && ele[item].value !== 0) {
                  canTip = false;
                  return this.$message.error(
                    ele.title + this.generateTips("required")
                  );
                }
              }
            }
          });
        });
      }
      if (canTip) {
        // this.$route.path.includes("spoken/live")?:''

        Api.submitAppointmentsFeedback(
          this.$route.path.includes("spoken/live") ? sopkenId : this.id,
          params,
          this.$route.path.includes("spoken/live") ? "spoken" : "",
          () => {
            this.cancel();
          }
        );
      }
    },
    saveForm() {
      let sopkenId = this.$route.params.id;
      let params = {
        is_draft: 1,
        criteria: this.criteria,
        ...this.feedback,
      };
      Api.submitAppointmentsFeedback(
        this.$route.path.includes("spoken/live") ? sopkenId : this.id,
        params,
        this.$route.path.includes("spoken/live") ? "spoken" : "",
        () => {
          this.cancel();
        }
      );
    },
    cancel() {
      this.$emit("closeDialog");
    },
    getServerTime() {
      Api.serverTime((res) => {
        this.serverTime = res.timestamp;
      });
    },
    generateColumns,
    generateButton,
    generateTips,
  },
};
</script>

<style lang="less" scoped>
.chapter {
  margin-bottom: 20px;

  .title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
  }
}

.criteria {
  margin-bottom: 5px;
}

.operation {
  text-align: right;
  margin-top: 20px;
}

.scores {
  display: flex;
}

.score_box {
  margin: 5px 10px 10px 0;

  span {
    margin-right: 8px;
  }
}
</style>
