import ChatList from './chatList/chatList.vue'
import HandList from './handList/handList.vue'
import OnlineList from './onlineList/onlineList.vue'
import { mapActions, mapState } from 'vuex'
import EXT_IM from '@/views/live/ext/im'
import * as CustomMsgType from '@/views/live/constants/customMsgType'
import * as Constant from '@/views/live/constants/constant'
import * as Api from '@/api/live/live'
import { generateColumns, generateTips } from '@/utils/i18n'

export default {
  data () {
    return {
      Constant,
      activeName: 'chatList',
      baseMsgNumber: 0,
      privateActiveName: 'chatListPrivate',
      onlineS: false,
      onlineStudentStatus: false,
      judgeOnLineType: Constant.JUDGE_ONLINE_TYPE.APP
    }
  },
  props: {
    privateUser: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  mounted () {
    this.getOnlineStatus()
  },
  computed: {
    st () {
      let status
      let privateUserAccount = this.privateUser.uuid
      let count = 0
      this.userList.forEach(( user ) => {
        if (user.userID === privateUserAccount) {
          status = true
          count++
        }
      })

      if (count === 0) {
        status = false
      }
      return status
    },
    ...mapState({

      tic: ( state ) => state.live.tic,
      tim: ( state ) => state.im.tim,
      userId: ( state ) => state.live.userId,
      liveMode: ( state ) => state.live.liveMode,
      onlineNumber: ( state ) => {
        let isHaveRecoder = state.live.userList.some(( element ) => {
        })
        if (isHaveRecoder) {
          return state.live.userList.length - 1
        } else {
          return state.live.userList.length
        }
      },
      handUpMember: ( state ) => state.live.handUpList.length,
      roomId: ( state ) => state.live.roomID,
      msgNumber: ( state ) => state.live.msgs.length,
      classStatus: ( state ) => state.live.classStatus,
      msgs: ( state ) => state.live.msgs,
      remoteVideoStream: ( state ) => state.rtc.remoteVideoStream,
      userList: ( state ) => state.live.userList,
      userJionTim: ( state ) => state.live.userJionTim,
      isOnlineMessage: ( state ) => state.im.isOnline, //没有上课状态下的在线消息
      isInClassOnline: ( state ) => state.im.isInClassOnline, //上课状态下的在线消息
      remoteMemberOnLineStatus ( state ) {
        let status = false
        if (this.classStatus === 1) {
          if (state.rtc.remoteVideoStream) {
            return true
          } else {
            setTimeout(() => {
              if (state.rtc.remoteVideoStream) {
                return true
              } else {
                return false
              }
            }, 3000)
            return true
          }
        } else {
          let privateUserAccount = this.privateUser.uuid
          let count = 0
          state.live.userList.forEach(( user ) => {
            if (user.userID === privateUserAccount) {
              status = true
              count++
            }
          })
          if (count === 0) {
            status = false
          }
        }
        return status
      },
      studentPlatform: (state)=> state.im.studentPlatform,
      customOnlineMessageStamp: (state)=> state.im.customOnlineMessageStamp,
      studentOnlineStatus: function (state) {
        if(state.im.studentOnlineStatus){
          this.$refs.enterInfoAudioRef.play()
        }
        return state.im.studentOnlineStatus
      },
    }),
    checkIsOnline () {
      this.getCheckIsOnline()
    },

    isOnline () {
      if (this.classStatus === 1) {
        return this.isInClassOnline
      } else {
        return this.isOnlineMessage
      }
    }
  },
  methods: {
    generateColumns,
    generateTips,
    checkStatus () {
    },
    changeTab () {
    },
    sendMsg ( text ) {
      if (!this.roomId) {
        this.$message.error(this.generateTips('room_id_empty'))
        return
      }
      let nick = ''
      let account = this.userId
      let avatar = ''
      let identity = 'teacher'
      EXT_IM.handleGroupTextMsg(text).then(( textCon ) => {
        this.showMessageInBox(
            { nick, account, avatar, identity },
            textCon,
            'chat',
            1
        ).then(() => {
          if (account === 'personal') {
            return
          }
          EXT_IM.sendTextMessage(text, this.roomId)
              .then(( res ) => {
                console.log(res)
              })
              .catch(( err ) => {
                console.log(err)
              })
        })
      })
    },
    /**
     * 增加IM消息监听回调
     */
    addTICMessageListener () {
      let self = this
      let listeneer = {
        /**
         * 收到C2C自定义消息
         * @param fromUserId    发送此消息的用户id
         * @param data        收到消息的内容
         * @param dataLen      收到消息的长度
         */
        onTICRecvCustomMessage: ( fromUserId, data ) => {
          let content = JSON.parse(data.data)
          this.handleCustomMsg({ account: fromUserId, content: content })
        },
        /**
         * 收到群自定义消息
         * @param fromUserId    发送此消息的用户id
         * @param data        收到消息的内容
         * @param dataLen      收到消息的长度
         */
        onTICRecvGroupCustomMessage: ( fromUserId, data ) => {
          this.handleCustomGroupMsg(data)
        },

        /**
         * 所有消息
         * @param msg  IM 消息体
         * @note 所有收到的消息都会在此回调进行通知，包括前面已经封装的文本和自定义消息（白板信令消息除外）
         */
        onTICRecvMessage ( msg ) {
          self.switchMsgType(msg)
        },
      }
      // this.tic.addTICMessageListener(listeneer)
    },
    // 解析消息
    switchMsgType ( msg ) {
      const nick = msg.nick || msg.from
      const avatar = msg.avatar
      // let identity = msg.extraInfo && msg.extraInfo.Role
      let identity = ''
      const account = msg.from
      let elem = msg.payload
      let msgType = msg.type
      switch (msgType) {
          // 群文本消息（包括表情）
        case 'TIMTextElem':
          if (msg.flow === 'out') {
            break
          }
          EXT_IM.handleGroupTextMsg(elem.text).then(( text ) => {
            this.showMessageInBox(
                { nick, account, avatar, identity },
                text,
                'chat'
            )
          })
          break
          // 图片消息
        case 'TIMImageElem':
          EXT_IM.handleImageMsgToHtml(elem).then(( imgHtml ) => {
            this.showMessageInBox(
                { nick, account, avatar, identity },
                imgHtml,
                'chat'
            )
          })
          break
          // 系统消息
        case 'TIMGroupTipElem':
          this.systemMsg(elem)
          break
        case 'TIMCustomElem':
          this.handleCustomGroupMsg(elem, msg.fromAccount)
      }
    },
    // 解析c2c自定义消息
    handleCustomMsg ( msg ) {
      switch (parseInt(msg.content.type)) {
        case CustomMsgType.C2C_GET_USER_ENTER:
          if (this.classStatus === Constant.CLASS_STATUS.STARTED) {
            let shareMsg = {
              type: 'c2c',
              targetId: msg.content.uids[ 0 ],
              data: CustomMsgType.MsgUserEnter(),
            }
            EXT_IM.sendCustomMsg(shareMsg)
          }
          break
        case parseInt(CustomMsgType.C2C_USER_HAND_UP):
          this.setUserHandStatus({ userID: msg.account, handUp: true })
          let msgHands = {
            type: 'group',
            data: CustomMsgType.MsgHandUpUserList(),
          }
          EXT_IM.sendCustomMsg(msgHands)
          break
        case parseInt(CustomMsgType.C2C_USER_HAND_DOWN):
          this.setUserHandStatus({ userID: msg.account, handUp: false })
          this.setUserConnectStatus({
            userID: msg.account,
            isConnected: false,
          })
          let msgHandDown = {
            type: 'group',
            data: CustomMsgType.MsgHandUpUserList(),
          }
          let msgConnect = {
            type: 'group',
            data: CustomMsgType.MsgConnectUser(),
          }
          EXT_IM.sendCustomMsg(msgConnect)
          EXT_IM.sendCustomMsg(msgHandDown)
          break
      }
    },
    //解析群组自定义消息
    handleCustomGroupMsg ( msg, fromAccount ) {
      if (!msg.data) {
        return
      }
      msg = JSON.parse(msg.data)
      switch (msg.type) {
        case CustomMsgType.GROUP_CLASS_OVER:
          console.log('下课')
          this.setCanTeachStatus(true)
          break
        case CustomMsgType.GROUP_CLASS_BEGIN:
          console.log('上课')
          // 上课中的老师忽略消息  其他待上课的老师进行相应的操作
          if (fromAccount === this.userId) {
          } else {
            this.setCanTeachStatus(false)
          }
          break
        case CustomMsgType.GROUP_MUTE:
          this.systemMsg(msg)
          if (msg.status === 'On') {
            this.showMessageInBox({ account: '' }, '已开启全体禁言', 'notify')
          } else {
            this.showMessageInBox({ account: '' }, '全体禁言已关闭', 'notify')
          }
          break
        case CustomMsgType.C2C_GET_USER_ENTER:
          if (this.classStatus === Constant.CLASS_STATUS.STARTED) {
            let shareMsg = {
              type: 'c2c',
              targetId: msg.uids[ 0 ] || msg.content.uids[ 0 ],
              data: CustomMsgType.MsgUserEnter(),
            }
            EXT_IM.sendCustomMsg(shareMsg)
          }
          break
        case CustomMsgType.RECORD_OUT_LINE:
          let self = this
          if (this.classStatus !== 1) {
            break
          }
          let id = this.$route.params.id
          this.$confirm(
              '由于长时间未操作，当前已断开连接，是否重新连接？',
              '',
              {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
              }
          )
              .then(() => {
                Api.startRecord(id, self.liveMode, () => {
                })
              })
              .catch(() => {
                self.$emit('finishbus')
              })
          break
        default:
          break
      }
    },
    //群提示消息类型
    systemMsg ( msg ) {
      let self = this
      switch (msg.opType) {
        case Constant.WEB_IM_GROUP_TIP_TYPE.MODIFY_MEMBER_INFO:
          msg.memberInfoList.forEach(( ele ) => {
            if (ele.userId === this.currentUser) {
              if (ele.shutupTime > 0) {
                this.setUserMute(true)
              } else {
                this.setUserMute(false)
              }
            }
          })
          break
        case Constant.WEB_IM_GROUP_TIP_TYPE.MODIFY_GROUP_INFO:
          msg.groupInfoList.forEach(( ele ) => {
            switch (ele.type) {
              case Constant.WEB_IM_GROUP_TIP_MODIFY_GROUP_INFO_TYPE
                  .NOTIFICATION:
                this.showMessageInBox(
                    { account: msg.fromAccount },
                    '群公告已更新，请查看最新公告',
                    'notify'
                )
                EXT_IM.getGroupInfo().then(( groupInfo ) => {
                  groupInfo = groupInfo.data.group
                  this.setGroupInfo(groupInfo)
                })
                break
            }
          })
          break
        case Constant.WEB_IM_GROUP_TIP_TYPE.SET_ADMIN:
          EXT_IM.getMemberList(self.roomId, ( list ) => {
            self.setUserList(list)
          })
          break
        case Constant.WEB_IM_GROUP_TIP_TYPE.CANCEL_ADMIN:
          EXT_IM.getMemberList(self.roomId, ( list ) => {
            self.setUserList(list)
          })
          break
      }
    },
    //在聊天框中显示聊天消息
    showMessageInBox ( user, text, type = 'chat', status ) {
      return new Promise(( resolve, reject ) => {
        let account = user.nick || user.account
        if (user.account === this.userId) {
          account = 'personal'
        }
        this.addMsg({
          send: account,
          content: text,
          type: type,
          avatar: user.avatar,
          identity: user.identity,
          status: status,
        })
        this.$nextTick(() => {
          var msgbox = document.querySelector('#msg_box')
          msgbox.scrollTop = msgbox.scrollHeight
          resolve()
        })
      })
    },
    loadMoreMsg () {
      this.$emit('loadMoreMsg')
    },

    /**
     * app: 上课状态  是否在群   是否有流
     * web: 发消息 5s一次  15s没有消息 离线
     */


    checkIsInGroup (  ) {
      let userList = this.userList
      let isStudentInGroup = false
      for (let i = 0; i < userList.length; i++) {
        let user = userList[ i ]
        if (user.userID !== this.userId) {
          if (user.userID.indexOf('tiw_record_user') < 0 && user.userID.indexOf('desktop_share_record_user') < 0) {
            isStudentInGroup = true
            break
          }
        }
      }
      return isStudentInGroup
    },
    checkHasStream () {
      if(this.remoteVideoStream){
        return true
      }else{
        return false
      }
    },
    getCheckIsOnline () {
      let currentTimeStamp = new Date().getTime()
      if(this.classStatus === Constant.CLASS_STATUS.STARTED){
        if(this.checkHasStream()){
          return this.setStudentOnlineStatus(true)
        }
      }else{
        if(this.studentPlatform === 'app'){
          if(this.checkIsInGroup()){
            return this.setStudentOnlineStatus (true)
          }
        }else{
          if(currentTimeStamp - this.customOnlineMessageStamp < 20000){
            return this.setStudentOnlineStatus(true)
          }
        }
      }
      // this.setStudentPlatform('app')
      return  this.setStudentOnlineStatus (false)
    },
    getOnlineStatus(){
      let _this = this
      setInterval(()=>{
        _this.getCheckIsOnline()
      },5000)
    },

    ...mapActions([
      'addMsg',
      'setUserMute',
      'setUserList',
      'setGroupInfo',
      'setUserHandStatus',
      'setCanTeachStatus',
      'setUserConnectStatus',
        'setStudentOnlineStatus',
        'setStudentPlatform'
    ]),
  },
  watch: {
    activeName ( oldVal, newVal ) {
      if (oldVal === 'chatList' && newVal !== 'chatList') {
        this.baseMsgNumber = this.msgNumber
      }
      if (newVal === 'chatList') {
        this.baseMsgNumber = this.msgNumber
      }
    },
    st ( newVal, oldVal ) {
      if (this.classStatus === 1) {
        this.onlineS = newVal
      } else {
        this.onlineS = newVal
      }
    },
    userList: {
      deep: true,
      immediate: true,
      handler ( newVal, oldVal ) {
        let str = JSON.stringify(newVal)
        // 判断str是否包含roomId
        if (str.indexOf(this.roomId) > -1) {
          this.onlineStudentStatus = true
        } else {
          this.onlineStudentStatus = false
        }
      },
    },
  },
  components: {
    ChatList,
    HandList,
    OnlineList,
  },
}


